<template>
    <bxs-form
    ref="form"
    @submit="submit">
        <bxs-row>
            <bxs-col
            cols="12"
            ds="6">
                <bxs-text-field
                v-model="model.nome"
                name="nome"
                label="Nome"
                required />
            </bxs-col>

            <bxs-col
            cols="12"
            ds="6">
                <bxs-text-field
                v-model="model.cognome"
                name="cognome"
                required
                label="Cognome" />
            </bxs-col>

            <bxs-col
            cols="12"
            ds="6">
                <bxs-text-field
                v-model="model.email"
                type="email"
                label="Email"
                name="email"
                :rules="['email']"
                required />
            </bxs-col>

            <bxs-col
            cols="12"
            ds="6">
                <bxs-text-field
                v-model="model.telefono"
                label="Telefono"
                name="telefono"
                required />
            </bxs-col>
        </bxs-row>

        <div class="my-ui">
            <p class="mb-2"><em class="el-required">*</em> In che modo puoi aiutarci?</p>

            <div>
                <bxs-radio-field
                v-model="model.in_che_modo_puoi_aiutarci"
                name="in_che_modo_puoi_aiutarci"
                required
                value="Biblioteca degli oggetti (desk, segreteria)">Biblioteca degli oggetti (desk, segreteria)</bxs-radio-field>

                <bxs-radio-field
                v-model="model.in_che_modo_puoi_aiutarci"
                name="in_che_modo_puoi_aiutarci"
                required
                value="Comunicazione (social, scrittura articoli, organizzazione campagne)">Comunicazione (social, scrittura articoli, organizzazione campagne)</bxs-radio-field>

                <bxs-radio-field
                v-model="model.in_che_modo_puoi_aiutarci"
                name="in_che_modo_puoi_aiutarci"
                required
                value="Laboratorio (organizzazione, assistenza durante i laboratori)">Laboratorio (organizzazione, assistenza durante i laboratori)</bxs-radio-field>

                <bxs-radio-field
                v-model="model.in_che_modo_puoi_aiutarci"
                name="in_che_modo_puoi_aiutarci"
                required
                value="Ricerca fondi (scrittura bandi, ricerca sponsor)">Ricerca fondi (scrittura bandi, ricerca sponsor)</bxs-radio-field>

                <bxs-radio-field
                v-model="model.in_che_modo_puoi_aiutarci"
                name="in_che_modo_puoi_aiutarci"
                required
                value="Varie (servizio oggetti a domicilio, aiuto progetti vari)">Varie (servizio oggetti a domicilio, aiuto progetti vari)</bxs-radio-field>
            </div>
        </div>

        <bxs-textarea-field
        v-model="model.messaggio"
        required
        label="Lascia un commento o un messaggio"
        placeholder="Scrivi qui il tuo commmento o messaggio" />

        <!-- --------------------------------------------------------------------------------------------------------
        -------------------------------------------------------------------------------------------------------- -->
        <bxs-text-field
        v-model="model.antispam"
        label="Quiz AntiSpam, quanto fa 7+4?"
        name="antispam"
        required />

        <!--  -->
        <div class="mb-ui">
            <bxs-btn
            block
            :loading="loading"
            :disabled="!is_submitable"
            @click="$refs.form.submit()">Invia</bxs-btn>
        </div>

        <!-- foot -->
        <div>
            <bxs-checkbox
            v-model="model.privacy"
            id="privacy"
            name="privacy"><small>* Confermo di aver letto e accettato la <router-link to="/policies/privacy">privacy policy</router-link></small></bxs-checkbox>
        </div>

        <!-- error message -->
        <div
        v-if="error"
        class="flex nowrap align-start mt-ui text-error mt-ui">
            <div>
                <small class="mb-0">Oops, qualcosa è andato storto.</small> <br>
                <small>Al momento non è possible inviare questo modulo, contattaci per ulteriore supporto.</small>
                <!-- {{ error.message }} -->
            </div>
        </div>

        <!-- response message -->
        <bxs-alert
        v-if="res"
        type="success"
        class="mt-ui">Grazie per il tuo invio. Ti ricontatteremo appena possibile.</bxs-alert>
    </bxs-form>
</template>

<script>
export default {
    name: 'form_volontariato',
    data () {
        return {
            key: 1,
            loading: false,
            error: null,
            res: null,
            model: {
                subject: 'Volontariato',
                nome: null,
                cognome: null,
                email: null,
                antispam: null,
                privacy: false,
                in_che_modo_puoi_aiutarci: null,
                messaggio: null
            }
        }
    },
    computed: {
        is_submitable () {
            const v = !this.loading && this.model.antispam === '11' && !!this.model.privacy
            return v
        }
    },
    methods: {
        async submit () {
            this.loading = true
            this.error = null

            try {
                this.res = await this.$api.post('v1/forms/volontariato', this.model)

                this.model = {
                    subject: 'Volontariato',
                    nome: null,
                    cognome: null,
                    email: null,
                    antispam: null,
                    privacy: false,
                    in_che_modo_puoi_aiutarci: null,
                    messaggio: null
                }

                this.key += 1
            } catch (err) {
                this.error = err
            } finally {
                this.loading = false
            }
        }
    }
}
</script>