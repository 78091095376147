<template>
    <bxs-form
    ref="form"
    @submit="submit">
        <bxs-checkbox
        v-model="model.laboratorio_workshop_corso"
        required
        name="laboratorio_workshop_corso"
        id="laboratorio_workshop_corso"
        label="Il tuo progetto è un laboratorio / workshop / corso?"
        class="mb-ui" />

        <bxs-textarea-field
        v-model="model.descrizione"
        name="descrizione"
        required
        label="Breve descrizione del progetto. Cerca di rispondere alle domande: Cosa? Come? A chi è rivolto? Perché? Dove?"
        placeholder="Max 300 parole" />

        <bxs-textarea-field
        v-model="model.per_quanto_tempo"
        label="Indica per quanto tempo e con che cadenza hai bisogno dello spazio"
        name="per_quanto_tempo"
        required
        placeholder="Max 300 parole" />

        <bxs-textarea-field
        v-model="model.biografia_responsabile_progetto"
        name="biografia_responsabile_progetto"
        required
        label="Breve biografia del responsabile del progetto"
        placeholder="Max 150 parole" />

        <bxs-text-field
        v-model="model.sitoweb_responsabile"
        required
        label="Indirizzo (URL) del sito/portfolio responsabile del progetto"
        name="sitoweb_responsabile" />

        <bxs-textarea-field
        v-model="model.budget"
        label="Budget con voci di costo"
        name="budget"
        placeholder="Max 150 parole" />

        <!-- --------------------------------------------------------------------------------------------------------
        -------------------------------------------------------------------------------------------------------- -->
        <bxs-text-field
        v-model="model.antispam"
        label="Quiz AntiSpam, quanto fa 7+4?"
        name="antispam"
        required />

        <!--  -->
        <div class="mb-ui">
            <bxs-btn
            :disabled="!is_submitable"
            :loading="loading"
            block
            @click="$refs.form.submit()">Invia</bxs-btn>
        </div>

        <!-- foot -->
        <bxs-checkbox
        v-model="model.privacy"
        id="privacy"
        name="privacy">
            <small>* Confermo di aver letto e accettato la <router-link to="/policies/privacy">privacy policy</router-link></small>
        </bxs-checkbox>

        <!-- error message -->
        <div
        v-if="error"
        class="flex nowrap align-start mt-ui text-error mt-ui">
            <div>
                <small class="mb-0">Oops, qualcosa è andato storto.</small> <br>
                <small>Al momento non è possible inviare questo modulo, contattaci per ulteriore supporto.</small>
                <!-- {{ error.message }} -->
            </div>
        </div>

        <!-- response message -->
        <bxs-alert
        v-if="res"
        type="success"
        class="mt-ui">Grazie per il tuo invio. Ti ricontatteremo appena possibile.</bxs-alert>
    </bxs-form>
</template>

<script>
export default {
    name: 'form_proponi_progetto',
    data () {
        return {
            key: 1,
            loading: false,
            error: null,
            res: null,
            model: {
                laboratorio_workshop_corso: false,
                descrizione: null,
                per_quanto_tempo: null,
                biografia_responsabile_progetto: null,
                sitoweb_responsabile: null,
                budget: null,
                privacy: false,
                antispam: null
            }
        }
    },
    computed: {
        is_submitable () {
            const v = !this.loading && this.model.antispam === '11' && !!this.model.privacy
            return v
        }
    },
    methods: {
        async submit () {
            if (!this.$refs.form.valid) return

            this.loading = true
            this.error = null

            try {
                this.res = await this.$api.post('v1/forms/proponi-il-tuo-progetto', this.model)

                this.model = {
                    name: null,
                    email: null,
                    message: null
                }

                this.key += 1
            } catch (err) {
                this.error = err
            } finally {
                this.loading = false
            }
        }
    }
}
</script>