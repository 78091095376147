<template>
    <!--
        clone: Se clonare il contenuto se non vuoi spazi vuoti nell'animazione
        loop: il numero di istanze in cui deve essere eseguita l'animazione del riquadro di selezione (0 è infinito)
    -->
    <Vue3Marquee
    :duration="10"
    :loop="0"
    clone
    :direction="direction">
        <span class="marquee-text">
            <slot />
        </span>
    </Vue3Marquee>
</template>

<script>
import { Vue3Marquee } from 'vue3-marquee'

export default {
    name: 'bxs_marquee',
    components: {
        Vue3Marquee
    },
    props: {
        text: {
            type: String,
            required: false,
            default: ''
        },
        direction: {
            type: String,
            required: false,
            default: 'normal'
        }
    }
}
</script>

<style lang="scss" scoped>
.vue3-marquee {
    overflow: hidden;
}

.marquee-text {
    font-size: 20vh;
    line-height: 100%;
    font-weight: var(--typo-weight-display);
    text-transform: uppercase;
}
</style>