<template>
    <div class="regolamento-view">
        <bxs-hero title="Regolamento"></bxs-hero>

        <section>
            <bxs-layout>
                <div>
                    <h3>Regole, tempi e modi di Leila</h3>
                    <p>Il tesseramento comporta l’accettazione incondizionata dello statuto e del regolamento in ogni loro parte. Il tesseramento è possibile anche per associazioni, in questo caso il costo varia a seconda delle caratteristiche della realtà e al numero di persone che usufruiscono della tessera. Per poter accedere al servizio di condivisione è necessario condividere almeno un proprio oggetto per un anno civile, al termine del quale si potrà scegliere se rinnovare il servizio oppure riprendere l’oggetto condiviso. La qualità degli oggetti che desideri trovare è direttamente proporzionale alla qualità degli oggetti che ogni persona decide di mettere in condivisione, quindi, punta in alto! La tessera associativa non è trasferibile ad altri soggetti. Per prendere in prestito un oggetto è necessaria la prenotazione. Essa può avvenire unicamente online attraverso il proprio profilo personale della biblioteca degli oggetti. L’oggetto deve essere utilizzato esclusivamente dal socio e non potrà essere ceduto, prestato o noleggiato. L’oggetto deve essere utilizzato esclusivamente dalla persona che l’ha preso in prestito e non potrà essere ceduto, prestato o noleggiato. Si possono prenotare e prendere in prestito contemporaneamente un numero di oggetti pari a quelli messi in condivisione. In altre parole: se hai messo un oggetto in condivisione nella biblioteca, puoi chiedere un oggetto in prestito alla volta; se ne hai messi due, puoi prenderne fino a due alla volta; se ne hai messi tre, puoi prenderne fino a tre alla volta, ecc.</p>
                </div>

                <div>
                    <h4>Gli oggetti</h4>
                    <p>Lo staff di Leila controlla che gli oggetti siano in buono stato di funzionamento e in buone condizioni generali, al momento del deposito, al momento del prestito e al rientro.</p>
                    <p>Non si possono mettere in condivisione oggetti:</p>
                    <ul>
                        <li>– di uso quotidiano (es: posate, spazzolino, vestiti)</li>
                        <li>– di consumo (es: guanti da lavoro, vernici, cibo, detersivi)</li>
                        <li>– armi</li>
                        <li>– oggetti ingombranti e difficili da trasportare</li>
                    </ul>

                    <div>
                        <p>È previsto un servizio di consegna e ritiro a domicilio relativo all’area urbana di Bologna:</p>
                        <ul>
                            <li>– 3€ a tratta in centro dentro mura</li>
                            <li>– 5€ a tratta fuori dalle mura</li>
                        </ul>
                    </div>

                    <div>
                        <h4>Tempi del prestito</h4>
                        <p>Il tempo massimo del prestito è di un mese, rinnovabile per un ulteriore mese. Gli oggetti per neonati possono essere presi in prestito fino a 6 mesi, non rinnovabili. La riconsegna deve avvenire entro il tempo stabilito, oltre il quale si impegna a donare 2€ per ogni giorno di ritardo. Se un  socio prenota il ritiro di uno o più oggetti, ma non passa a ritirarli nel giorno stabilito, la prenotazione viene considerata nulla e gli oggetti tornano ad essere disponibili.</p>
                    </div>

                    <div>
                        <h4>Responsabilità</h4>
                        <p>Leila Bologna non si assume la responsabilità degli oggetti dati e presi in condivisione, né risponde di danni provocati dall’utilizzo degli oggetti. La persona che ha preso in prestito l’oggetto ne è responsabile e quindi, in caso di danni, si impegna a sostituire a proprie spese l’oggetto con uno nuovo. Prima di procedere con la sostituzione, mettersi in contatto con lo staff di Leila. La stessa modalità deve essere adottata in caso di smarrimento o furto. In caso di danno parziale, il socio si impegna nella riparazione o nella sostituzione del pezzo danneggiato a proprie spese, in accordo con lo staff.</p>
                    </div>
                </div>
            </bxs-layout>
        </section>
    </div>
</template>

<script>
export default {
    name: 'regolamento'
}
</script>