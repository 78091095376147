<template>
    <section
    id="section-fare-condivisione"
    class="mute">
        <bxs-layout class="mb-ui">
            <div>
                <p class="label">Fare condivisione</p>
                <h2>Oltre gli oggetti</h2>
                <p>Da Leila si condividono esperienze, saperi, spazi e tanto altro!</p>
            </div>
        </bxs-layout>

        <bxs-layout fluid>
            <bxs-row>
                <bxs-col
                cols="12"
                ml="6"
                ds="3">
                    <bxs-link
                    to="/fare-condivisione/leila-social-club"
                    class="mb-ui">
                        <template #activator>
                            <h6 class="mb-0">Leila social club</h6>
                        </template>

                        <bxs-figure
                        src="https://leila-bologna.it/wp-content/uploads/2023/02/leila-social-club.jpg"
                        ratio="3-2"
                        class="mt-ui" />
                    </bxs-link>

                    <p>La massima espressione della condivisione dei saperi!</p>
                </bxs-col>

                <bxs-col
                cols="12"
                ml="6"
                ds="3">
                    <bxs-link
                    to="/fare-condivisione/laboratorio"
                    class="mb-ui">
                        <template #activator>
                            <h6 class="mb-0">Laboratorio</h6>
                        </template>

                        <bxs-figure
                        src="https://leila-bologna.it/wp-content/uploads/2022/12/leila-bologna-laboratorio.jpg"
                        ratio="3-2"
                        class="mt-ui" />
                    </bxs-link>

                    <p>Lo spazio in condivisione che fa per te!</p>
                </bxs-col>

                <bxs-col
                cols="12"
                ml="6"
                ds="3">
                    <bxs-link
                    to="/fare-condivisione/cargo-bike"
                    class="mb-ui">
                        <template #activator>
                            <h6 class="mb-0">Cargo bike</h6>
                        </template>

                        <bxs-figure
                        src="https://leila-bologna.it/wp-content/uploads/2022/12/leila-bologna-cargobike.jpg"
                        ratio="3-2"
                        class="mt-ui" />
                    </bxs-link>

                    <p>I Giochi Di Pippo e il CiapLab per bambinə</p>
                </bxs-col>

                <bxs-col
                cols="12"
                ml="6"
                ds="3">
                    <bxs-link
                    to="/fare-condivisione/coworking"
                    class="mb-ui">
                        <template #activator>
                            <h6 class="mb-0">Coworking</h6>
                        </template>

                        <bxs-figure
                        src="https://leila-bologna.it/wp-content/uploads/2022/12/leila-bologna-coworking.jpg"
                        ratio="3-2"
                        class="mt-ui" />
                    </bxs-link>

                    <p>Scopri le scrivanie in condivisione!</p>
                </bxs-col>
            </bxs-row>
        </bxs-layout>
    </section>
</template>

<script>
export default {
    name: 'section_fare_condivisione'
}
</script>