<template>
    <div class="news-view">
        <section
        v-if="!is_preloaded"
        class="text-center">
            <bxs-layout>
                <bxs-loader />
            </bxs-layout>
        </section>

        <div v-else>
            <bxs-hero
            src="/img/news.jpg"
            title="News"></bxs-hero>

            <section>
                <bxs-layout>
                    <bxs-row>
                        <bxs-col
                        v-for="item in data_page.docs"
                        :key="item.id"
                        cols="12"
                        ds="3"
                        class="mb-6">
                            <lel-single-post-card :item="item" />
                        </bxs-col>
                    </bxs-row>
                </bxs-layout>
            </section>
        </div>
    </div>
</template>

<script>
import SinglePostCard from '@/components/project/SinglePostCard.vue'

export default {
    name: 'news',
    components: {
        'lel-single-post-card': SinglePostCard
    },
    data () {
        return {
            is_preloaded: false,
            is_busy: false,
            data_page: {
                category: null,
                docs: [],
                pagination: {
                    page: 1
                }
            }
        }
    },
    computed: {
        latest_post () {
            return this.data_page.docs[0]
        }
    },
    mounted () {
        this.$nextTick(this.start)
    },
    methods: {
        async start () {
            await this.getDocs()
            this.is_preloaded = true
            window.addEventListener('scroll', this.handleScroll)
        },
        stop () {
            window.removeEventListener('scroll', this.handleScroll)
        },
        handleScroll () {
            if (
                (window.innerHeight + window.scrollY) >= (document.body.scrollHeight - (window.innerHeight / 2))
            ) {
                if (this.data_page.pagination.has_next_page && !this.is_busy) {
                    this.getDocs(this.data_page.pagination.page += 1)
                }
            }
        },
        async getDocs (page = 1, slug) {
            this.is_busy = true

            try {
                const {
                    category,
                    docs,
                    pagination
                } = await this.$store.dispatch('getNews', {
                    limit: 10,
                    page: page,
                    ...this.$route.query
                })

                this.data_page.category = category

                if (page === 1) this.data_page.docs = docs
                else this.data_page.docs.push(...docs)

                this.data_page.pagination = pagination
            } catch (err) {
                alert(err)
            } finally {
                this.is_busy = false
                this.is_preloaded = true
            }
        }
    },
    beforeRouteUpdate (to, from) {
        this.getDocs(1, to.params.slug)
    },
    beforeUnmount () {
        this.stop()
    }
}
</script>