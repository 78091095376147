<template>
    <section
    id="section-tessera"
    style="padding: 12.5vh 0;">
        <div class="section-tessera-bg"></div>

        <bxs-layout>
            <div v-if="!hideLabel">
                <p class="label">Servizio di condivisione</p>
            </div>

            <bxs-row>
                <bxs-col
                cols="12"
                ds="8">
                    <div class="mb-ui">
                        <div v-if="!$slots.default">
                            <h1 class="text-gradient">Entra a far parte <br class="show-ds"> della comunità</h1>
                            <p class="text-400">Con la tessera di Leila attivi il servizio di condivisione della Biblioteca degli oggetti con cui condividere e prendere in prestito tutti gli oggetti che vuoi. Ottieni la tessera di Leila ed entra a far parte della comunità della condivisione!</p>
                        </div>

                        <slot  />

                        <bxs-btn
                        v-if="!hideCta"
                        to="/come-funziona">Attiva il servizio</bxs-btn>
                    </div>

                    <div v-if="!hideCta">
                        <!-- <h6>Se hai già la tessera e vuoi rinnovarla segui il link</h6> -->
                        <bxs-link
                        to="/tessera"
                        label="Scopri come rinnovare la tua tessera"
                        class="text-400">
                            <template #activator>
                                <small>Hai già la tessera? Scopri come rinnovarla</small>
                            </template>
                        </bxs-link>
                    </div>
                </bxs-col>

                <bxs-col
                cols="12"
                ds="4">
                    <bxs-figure src="https://leila-bologna.it/wp-content/uploads/2022/12/filosofia-di-leila-1.jpg"></bxs-figure>
                </bxs-col>
            </bxs-row>
        </bxs-layout>
    </section>
</template>

<script>
export default {
    name: 'section_tessera',
    props: {
        'hide-cta': Boolean,
        'hide-label': Boolean
    }
}
</script>