<template>
    <div class="scrolldown">
        <div class="scrolldown--label">scroll to explore</div>

        <div class="scrolldown--line">
            <div ref="line"></div>
        </div>
    </div>
</template>

<script>
import { gsap } from 'gsap'

export default {
    name: 'scrolldown',
    mounted () {
        const tl = gsap.timeline({
            repeat: -1
        })

        tl
        .set(this.$refs.line, {
            scaleY: 0
        })
        .to(this.$refs.line, {
            scaleY: 1,
            transformOrigin: 'left top',
            duration: 1,
            ease: 'expo.inOut'
        })
        .to(this.$refs.line, {
            scaleY: 0,
            transformOrigin: 'left bottom',
            duration: 1,
            ease: 'expo.out'
        })
    }
}
</script>

<style lang="scss">
$line-height: 48px;
$line-width: 3px;

.scrolldown {
    position: relative;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    cursor: pointer;
    text-align: center;

    &--line {
        display: inline-block;
        position: relative;
        height: $line-height;
        width: $line-width;
        overflow: hidden;
        background: var(--color-mute);

        > div {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            transform-origin: left bottom;
            background-color: var(--color-primary);
        }
    }

    &--label {
        font-size: 0.7rem;
        font-weight: 400;
        text-transform: uppercase;
        color: var(--color-white);
        margin-bottom: 1rem;
    }
}
</style>