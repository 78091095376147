<template>
    <section
    id="section-storie-di-leila"
    class="fourth light">
        <bxs-layout>
            <div class="mb-ui">
                <p class="label">Storie di Leila</p>
                <h3>Le storie di <br> Leila a fumetti</h3>
            </div>

            <ul class="my-ui">
                <li>
                    <div class="flex nowrap align-center justify-between mb-3">
                        <div class="flex-1">
                            <small class="text-disabled text-400 mb-1">{{ item.title }}</small>
                        </div>

                        <div class="flex-1 text-right">
                            <a
                            href="https://www.instagram.com/archiviodiferro/"
                            target="_blank"
                            class="text-400">
                                <div
                                class="lel-avatar mr-2"
                                style="background-image: url('/img/federica-ferraro.jpg')"></div>
                                <small class="text-disabled">@archiviodiferro</small>
                            </a>
                        </div>
                    </div>

                    <bxs-row no-gutters>
                        <bxs-col
                        v-for="(img, i2) in item.imgs"
                        :key="i2"
                        cols="6"
                        ml="3">
                            <bxs-figure :src="img" />
                        </bxs-col>
                    </bxs-row>
                </li>
            </ul>

            <div class="mt-ui text-right">
                <bxs-link
                label="Scopri tutte le storie"
                to="/storie-di-leila" />
            </div>
        </bxs-layout>
    </section>
</template>

<script>
export default {
    name: 'section_storie_di_leila',
    data () {
        return {
            item: {
                title: 'Federica Ferraro - 21/06/2023',
                imgs: [
                    '/img/storie-di-leila/federica-ferraro-21-06-2023/1.png',
                    '/img/storie-di-leila/federica-ferraro-21-06-2023/2.png',
                    '/img/storie-di-leila/federica-ferraro-21-06-2023/3.png',
                    '/img/storie-di-leila/federica-ferraro-21-06-2023/4.png',
                    '/img/storie-di-leila/federica-ferraro-21-06-2023/5.png',
                    '/img/storie-di-leila/federica-ferraro-21-06-2023/6.png',
                    '/img/storie-di-leila/federica-ferraro-21-06-2023/7.png'
                ]
            }
        }
    }
}
</script>