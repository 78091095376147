import { gsap } from 'gsap'

const transitions = new Map()

// ------------------------------------------------------------------------------------------------------
const fallback = (el, direction, next) => {
    const isIn = direction === 'in'

    const tl = gsap.timeline({
        onComplete: next
    })

    tl
        .addLabel('start')
        .fromTo(el, {
            autoAlpha: isIn ? 0 : 1
            // scaleX: isIn ? 1.1 : 1
        }, {
            autoAlpha: isIn ? 1 : 0,
            // scaleX: isIn ? 1 : 1.1,
            duration: 0.5,
            clearProps: 'scaleX',
            ease: 'expo.out'
        })
}

const bigMenu = (el, direction, next) => {
    const isIn = direction === 'in'
    const items = [...el.querySelectorAll('[data-big-menu-item]')]

    const tl = gsap.timeline({
        onComplete: next
    })

    tl
        .addLabel('start')

    if (isIn) {
        tl
            .fromTo(el, {
                autoAlpha: 0
            }, {
                autoAlpha: 1,
                duration: 0.5,
                ease: 'expo.out'
            })
            .fromTo(items, {
                y: '200%'
            }, {
                y: 0,
                stagger: 0.05,
                ease: 'expo.out'
            })
    } else {
        tl
            .fromTo(el, {
                autoAlpha: 1
            }, {
                autoAlpha: 0,
                duration: 0.5,
                ease: 'expo.out'
            })
    }
}

// ------------------------------------------------------------------------------------------------------

[{
    key: 'fallback',
    timeline: fallback
}, {
    key: 'big_menu',
    timeline: bigMenu
}].forEach((item) => {
    transitions.set(
        item.key,
        (ctx, direction) => new Promise(resolve => item.timeline(ctx, direction, resolve))
    )
})

export default transitions