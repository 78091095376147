<template>
    <header class="navframe bxs-navframe header-2">
        <bxs-layout fluid>
            <div>
                <logo />
            </div>

            <!-- ------------------------------------------------------------------------------------------------------------------
            ------------------------------------------------------------------------------------------------------------------ -->
            <nav class="nav-main">
                <ul class="bxs-navframe--menu">
                    <li>
                        <router-link to="/storia-e-filosofia-di-leila">Chi siamo</router-link>
                    </li>

                    <li>
                        <router-link to="/come-funziona">Come funziona</router-link>
                    </li>

                    <li>
                        <a
                        href="https://webapp.leila-bologna.it"
                        target="_blank">Oggetti</a>
                    </li>

                    <li>
                        <router-link to="/voi-e-leila/crea-una-leila-anche-tu">Crea una leila anche tu</router-link>
                    </li>

                    <li>
                        <router-link to="/collaborazioni">Collaborazioni</router-link>
                    </li>

                    <li>
                        <router-link to="/news">News</router-link>
                    </li>

                    <li>
                        <router-link to="/contatti">Contatti</router-link>
                    </li>
                </ul>
            </nav>

            <!-- ------------------------------------------------------------------------------------------------------------------
            ------------------------------------------------------------------------------------------------------------------ -->
            <nav>
                <ul>
                    <li>
                        <a
                        href="https://www.instagram.com/leilabologna/"
                        target="_blank">
                            <bxs-icon
                            name="ig"
                            width="1.3rem"
                            height="1.3rem"></bxs-icon>
                        </a>
                    </li>

                    <li>
                        <a
                        href="https://www.facebook.com/leila.bologna.la.biblioteca.degli.oggetti"
                        target="_blank">
                            <bxs-icon
                            name="fb"
                            width="1.3rem"
                            height="1.3rem"></bxs-icon>
                        </a>
                    </li>

                    <li>
                        <a
                        href="https://www.youtube.com/channel/UCGA-JRWPzLn1YNZGCMqjRhA"
                        target="_blank">
                            <bxs-icon
                            name="yt"
                            width="1.3rem"
                            height="1.3rem"></bxs-icon>
                        </a>
                    </li>

                    <!-- ----------------------------------------------------------------------------------------------------------------------------------------------------------------
                    ---------------------------------------------------------------------------------------------------------------------------------------------------------------- -->
                    <li>
                        <bxs-drawer
                        max-width="576">
                            <template #header-logo>
                                <logo />
                            </template>

                            <template #activator="{ on }">
                                <bxs-icon
                                v-on="on"
                                name="hamburger"
                                width="1rem"
                                class="ml-2"></bxs-icon>
                            </template>

                            <ul>
                                <li>
                                    <bxs-link
                                    label="Chi siamo"
                                    to="/storia-e-filosofia-di-leila"
                                    block
                                    class="pb-2" />
                                </li>

                                <li>
                                    <bxs-link
                                    label="Come funziona"
                                    to="/come-funziona"
                                    block
                                    class="py-1" />
                                </li>

                                <li>
                                    <bxs-link
                                    label="Oggetti"
                                    href="https://webapp.leila-bologna.it"
                                    target="_blank"
                                    block
                                    class="py-1" />
                                </li>

                                <li>
                                    <bxs-link
                                    label="Oggetti Mancanti"
                                    to="/oggetti-mancanti"
                                    block
                                    class="py-1" />
                                </li>

                                <!-- <li>
                                    <bxs-link
                                    label="Crea una Leila anche tu"
                                    to="/voi-e-leila/crea-una-leila-anche-tu"
                                    block
                                    class="py-1" />
                                </li> -->

                                <li>
                                    <bxs-link
                                    label="Collaborazioni"
                                    to="/collaborazioni"
                                    block
                                    class="py-1" />
                                </li>

                                <li>
                                    <bxs-link
                                    label="La rete"
                                    to="/la-rete"
                                    block
                                    class="py-1" />
                                </li>

                                <li>
                                    <bxs-link
                                    label="News"
                                    to="/news"
                                    block
                                    class="py-1" />
                                </li>

                                <li>
                                    <bxs-link
                                    label="Contatti"
                                    to="/contatti"
                                    block
                                    class="py-1" />
                                </li>
                            </ul>

                            <div class="my-12">
                                <div class="mb-ui">
                                    <h6 class="text-disabled mb-2">Voi e Leila</h6>

                                    <ul>
                                        <li>
                                            <bxs-link
                                            label="Crea una Leila anche tu"
                                            to="/voi-e-leila/crea-una-leila-anche-tu"
                                            block
                                            class="py-1" />
                                        </li>

                                        <li>
                                            <bxs-link
                                            label="Volontariato"
                                            to="/voi-e-leila/volontariato"
                                            block
                                            class="py-1" />
                                        </li>

                                        <li>
                                            <bxs-link
                                            label="Proponi il tuo progetto"
                                            to="/voi-e-leila/proponi-il-tuo-progetto"
                                            block
                                            class="py-1" />
                                        </li>

                                        <li>
                                            <bxs-link
                                            label="Tirocinio"
                                            to="/voi-e-leila/tirocinio"
                                            block
                                            class="py-1" />
                                        </li>
                                    </ul>
                                </div>

                                <div>
                                    <h6 class="text-disabled mb-2">Fare condivisione</h6>

                                    <ul>
                                        <li>
                                            <bxs-link
                                            label="Leila social club"
                                            to="/fare-condivisione/leila-social-club"
                                            block
                                            class="py-1" />
                                        </li>

                                        <li>
                                            <bxs-link
                                            label="Laboratorio"
                                            to="/fare-condivisione/laboratorio"
                                            block
                                            class="py-1" />
                                        </li>

                                        <li>
                                            <bxs-link
                                            label="Cargo bike"
                                            to="/fare-condivisione/cargo-bike"
                                            block
                                            class="py-1" />
                                        </li>

                                        <li>
                                            <bxs-link
                                            label="Coworking"
                                            to="/fare-condivisione/coworking"
                                            block
                                            class="py-1" />
                                        </li>
                                    </ul>
                                </div>
                            </div>

                            <ul class="text-disabled small">
                                <li>
                                    <bxs-link
                                    label="Rinnovo tessera"
                                    to="/rinnovo-tessera"
                                    block
                                    class="py-1" />
                                </li>

                                <li>
                                    <bxs-link
                                    label="Oggetti suggeriti"
                                    to="/oggetti-suggeriti"
                                    block
                                    class="py-1" />
                                </li>

                                <li>
                                    <bxs-link
                                    label="Dicono di noi"
                                    to="/dicono-di-noi"
                                    block
                                    class="py-1" />
                                </li>

                                <li>
                                    <bxs-link
                                    label="Storie di Leila"
                                    to="/storie-di-leila"
                                    block
                                    class="py-1" />
                                </li>

                                <li>
                                    <bxs-link
                                    label="Regolamento"
                                    to="/regolamento"
                                    block
                                    class="py-1" />
                                </li>

                                <li>
                                    <bxs-link
                                    label="Statuto"
                                    to="/statuto"
                                    block
                                    class="py-1" />
                                </li>

                                <li>
                                    <bxs-link
                                    label="Privacy policy"
                                    to="/policies/privacy"
                                    block
                                    class="py-1" />
                                </li>

                                <li>
                                    <bxs-link
                                    label="Cookie policy"
                                    to="/policies/cookie"
                                    block
                                    class="py-1" />
                                </li>
                            </ul>

                            <bxs-divider class="my-ui"></bxs-divider>

                            <!-- ------------------------------------------------------------------------------------------------------------------
                            ------------------------------------------------------------------------------------------------------------------ -->
                            <div class="text-center">
                                <div class="mb-2">
                                    <small>© {{ new Date().getFullYear() }} Leila Bologna - La Biblioteca degli oggetti APS</small>
                                </div>

                                <div class="flex nowrap align-center justify-center">
                                    <a
                                    href="https://www.instagram.com/leilabologna/"
                                    target="_blank"
                                    class="mr-4">
                                        <bxs-icon
                                        name="ig"
                                        width="1.3rem"
                                        height="1.3rem"></bxs-icon>
                                    </a>

                                    <a
                                    href="https://www.facebook.com/leila.bologna.la.biblioteca.degli.oggetti"
                                    target="_blank"
                                    class="mr-4">
                                        <bxs-icon
                                        name="fb"
                                        width="1.3rem"
                                        height="1.3rem"></bxs-icon>
                                    </a>

                                    <a
                                    href="https://www.youtube.com/channel/UCGA-JRWPzLn1YNZGCMqjRhA"
                                    target="_blank">
                                        <bxs-icon
                                        name="yt"
                                        width="1.3rem"
                                        height="1.3rem"></bxs-icon>
                                    </a>
                                </div>
                            </div>
                        </bxs-drawer>
                    </li>
                </ul>
            </nav>
        </bxs-layout>
    </header>
</template>

<script>
export default {
    name: 'bxs_navbar'
    // mounted () {
    //     this.$nextTick(() => {
    //         this.$sticky('.navframe')
    //     })
    // }
}
</script>

<style lang="scss" scoped>
@import '@/assets/styles/mq.scss';

.bxs-navframe {
    position: relative;
    width: 100%;

    min-height: var(--navbar-height);
    background-color: var(--navbar-background-color);
    display: flex;
    align-items: center;
    margin-bottom: var(--navbar-margin-bottom);

    z-index: var(--layer-navbar);

    @include mq($mq-static-breakpoint) {
        min-height: var(--navbar-height-desktop);
    }

    .separator {
        margin: 0 1rem;
        display: none;
        align-items: center;

        @include mq($mq-static-breakpoint) {
            display: flex;
        }

        > span {
            display: inline-block;
            width: 8px;
            height: 8px;
            background-color: var(--color-primary);
            vertical-align: middle;
            border-radius: 100%;
        }
    }

    .logo {
        margin-top: -5px;
    }

    .bxs-navframe--menu {
    }

    > .bxs-layout {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;

        nav {
            flex: 1;
            display: flex;
            flex-flow: row nowrap;
            align-items: center;
            justify-content: flex-end;

            ul {
                display: flex;
                flex-flow: row nowrap;
                align-items: center;
                justify-content: center;

                &.bxs-navframe--menu {
                    display: none;

                    @include mq($mq-static-breakpoint) {
                        display: flex;
                    }
                }

                > li {
                    font-size: 0.7rem;
                    font-weight: 500;
                    text-transform: uppercase;
                    margin-right: 1rem;

                    &:last-child {
                        margin-right: 0;
                    }

                    // &.hamburger {
                    //     display: unset;

                    //     @include mq($mq-static-breakpoint) {
                    //         display: none;
                    //     }
                    // }

                    > a,
                    .bxs-drawer--activator > a  {
                        font-size: 0.7rem;
                        font-weight: 500;
                        text-transform: uppercase;
                    }
                }
            }
        }
    }

    &.header-2 {
        > .bxs-layout {
            .nav-main {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                display: none;
                flex-flow: row nowrap;
                align-items: center;
                justify-content: center;
                z-index: 1;
                pointer-events: none;

                @include mq($mq-static-breakpoint) {
                    display: flex;
                }

                ul {
                    pointer-events: auto;
                    // display: flex;
                    // flex-flow: row nowrap;
                    // align-items: center;
                    // justify-content: center;
                }
            }
        }
    }
}
</style>