<template>
    <div
    :class="classes"
    :style="measurable_styles">
        <slot />
    </div>
</template>

<script>
import { measurable } from '@/mixins'

export default {
    name: 'bxs-layout',
    mixins: [measurable],
    props: {
        fluid: {
            type: Boolean,
            required: false,
            default: false
        },
        'no-gutters': {
            type: Boolean,
            required: false,
            default: false
        }
    },
    computed: {
        classes () {
            return ['bxs-layout', {
                'bxs-layout-fluid': this.fluid,
                'bxs-layout-no-gutters': this.noGutters
            }]
        }
    }
}
</script>

<style lang="scss">
.bxs-layout {
    position: relative;
    width: 100%;
    max-width: var(--boxed-ui);

    margin-left: auto;
    margin-right: auto;

    padding-left: var(--size-layout);
    padding-right: var(--size-layout);

    &.bxs-layout-fluid {
        max-width: 100%;
    }

    &.bxs-layout-no-gutters {
        padding-left: 0;
        padding-right: 0;
    }
}
</style>
