<template>
    <section id="section-come-funziona-full">
        <bxs-layout>
            <div class="mb-ui">
                <p class="label">Come funziona</p>
                <h3>Come funziona il servizio di condivisione</h3>
                <p class="h5 text-ui mb-0">Contribuisci a rendere il mondo un luogo sempre più equo, etico ed ecologico. <br> Attiva il servizio di condivisione, ti aspettiamo nelle <bxs-link href="#section-sedi" hide-arrow label="nostre sedi" /> o visita la pagina <bxs-link to="/contatti" hide-arrow label="contatti" /> per ulteriori informazioni.</p>
            </div>

            <bxs-row align="center">
                <bxs-col
                cols="12"
                ds="6"
                class="text-center">
                    <h1 class="text-big text-primary">.1</h1>
                    <h5 class="text-primary">Fai la tessera</h5>
                </bxs-col>

                <bxs-col
                cols="12"
                ds="6">
                    <p>Il servizio ha una durata di un anno e si attiva dopo aver condiviso almeno un tuo oggetto. Per attivare la tessera e poter accedere al servizio di condivisione <bxs-link href="#section-sedi" hide-arrow label="vieni in uno dei Punti Leila" actived /> che preferisci nei giorni e orari indicati.</p>
                    <p>Se possiedi la tessera delle biblioteche comunali <b>quella di Leila è in regalo!</b> <br> In caso contrario, ha un costo di <b>20,00 € (15,00 € per gli studenti)</b>.</p>
                </bxs-col>
            </bxs-row>

            <bxs-divider></bxs-divider>

            <bxs-row align="center">
                <bxs-col
                cols="12"
                ds="6"
                class="text-center">
                    <h1 class="text-big text-primary">.2</h1>
                    <h5 class="text-primary">Porta almeno un oggetto    in condivisione</h5>
                </bxs-col>

                <bxs-col
                cols="12"
                ds="6">
                    <p class="mb-1">Puoi mettere in condivisione tutti gli oggetti che vuoi con qualche attenzione, Leila non accetta:</p>
                    <ul class="mb-ui">
                        <li>- oggetti di uso quotidiano come posate, vestiti non tecnici, bigiotteria, oggetti personali;</li>
                        <li>- oggetti di consumo come guanti da lavoro, vernici, cibo, detersivi;</li>
                        <li>- armi;</li>
                        <li>- oggetti troppo ingombranti e difficili da trasportare.</li>
                    </ul>

                    <p>La qualità degli oggetti che desideri trovare dipende dalla qualità degli oggetti che ciascuno condivide! Perciò, mira in alto e <b>condividi solo il meglio!</b> <br> Per qualsiasi dubbio o se hai bisogno di consigli <bxs-link to="/contatti" label="contattaci" hide-arrow actived /> o scrivi alla mail <a href="mailto:info@leila-bologna.it">info@leila-bologna.it</a></p>

                    <p>Visita la pagina degli <bxs-link label="oggetti suggeriti" hide-arrow actived to="/oggetti-suggeriti"></bxs-link> per la condivisione!</p>
                </bxs-col>
            </bxs-row>

            <bxs-divider></bxs-divider>

            <bxs-row align="center">
                <bxs-col
                cols="12"
                ds="6"
                class="text-center">
                    <h1 class="text-big text-primary">.3</h1>
                    <h5 class="text-primary">Prendi in prestito quello che ti serve</h5>
                </bxs-col>

                <bxs-col
                cols="12"
                ds="6">
                    <p>Tramite <bxs-link href="https://webapp.leila-bologna.it" target="_blank" label="la nostra webapp" hide-arrow actived></bxs-link>, seleziona gli oggetti che desideri e prenotali seguendo le indicazioni! <br> Puoi prendere in prestito tanti oggetti quanti ne hai condivisi. Per ritirare e condividere gli oggetti passa in uno dei nostri <bxs-link href="#section-sedi" label="Punti Leila" hide-arrow actived></bxs-link>.</p>
                </bxs-col>
            </bxs-row>

            <bxs-divider></bxs-divider>

            <bxs-row align="center">
                <bxs-col
                cols="12"
                ds="6"
                class="text-center">
                    <h1 class="text-big text-primary">.4</h1>
                    <h5 class="text-primary">Rinnova il servizio di condivisione</h5>
                </bxs-col>

                <bxs-col
                cols="12"
                ds="6">
                    <!-- <p class="mb-2">Per rinnovare il servizio di condivisione puoi:</p> -->

                    <ul>
                        <li>
                            <leila-list-item
                            title="Rinnova la tessera nella sezione dedicata"
                            href="#section-rinnovo-tessera"
                            icon="arrow-down" />
                            <!-- 1. Farlo nella sezione dedicata <bxs-link to="/tessera" label="cliccando qui" /> -->
                        </li>
                        <li>
                            <leila-list-item
                            title="Vieni nei Punti Leila negli orari indicati"
                            href="#section-sedi"
                            icon="arrow-down" />
                            <!-- 2. Venire nelle <bxs-link href="#section-sedi" label="nostre sedi" hide-arrow></bxs-link> -->
                        </li>
                    </ul>
                </bxs-col>
            </bxs-row>

            <bxs-divider></bxs-divider>
        </bxs-layout>
    </section>
</template>

<script>
export default {
    name: 'section_come_funziona'
}
</script>