<template>
  <div class="bxs-checkbox-field">
    <label :for="name || id">
      <input
      v-model="local_value"
      type="checkbox"
      :name="name || id"
      :id="id">

      <div class="checkbox"></div>

      <span v-if="label">{{ label }}</span>
      <slot></slot>
    </label>
  </div>
</template>

<script>
import {
  onMounted,
  onBeforeUnmount,
  inject,
  getCurrentInstance
} from 'vue'

export default {
  name: 'bxs_checkbox_field',
  props: {
    modelValue: {
      type: Boolean,
      required: false,
      default: null
    },
    label: {
      type: String,
      required: false,
      default: null
    },
    name: {
      type: String,
      required: false,
      default: null
    },
    id: {
      type: String,
      required: true,
      default: null
    }
  },
  emits: [
    'update:modelValue'
  ],
  data () {
    return {
      local_value: this.modelValue,
      cacheErrors: this.errors
    }
  },
  setup (props, ctx) {
    // console.log('ctx', ctx)
    const instance = getCurrentInstance()
    // console.log('instance', instance)

    if (!props.solo) {
      const form = inject('bxs-form')
      // console.log(form)

      const uid = instance.uid

      onMounted(() => {
        if (form) {
          form.bind({
            validate: instance.ctx.validate,
            getValue: instance.ctx.getValue,
            reset: instance.ctx.reset,
            resetValidation: instance.ctx.resetValidation,
            name: props.name,
            uid
          })
        }
      })

      onBeforeUnmount(() => {
        if (form) form.unbind(uid)
      })
    }

    return {}
  },
  watch: {
    modelValue (newVal) {
      this.local_value = newVal
    },
    local_value (newVal) {
      this.$emit('update:modelValue', newVal)
    }
  },
  methods: {
    reset () {
      this.local_value = null
      this.resetValidation()
    },
    resetValidation () {
      this.cacheErrors = []
      this.valid = true
    },
    getValue () {
      return this.cacheValue
    },
    validate () {
      let v = true
      this.cacheErrors = []
      this.valid = v

      if (this.required && !this.local_value) {
        this.cacheErrors.push('Campo richiesto')
        v = false
      }

      return {
        value: v,
        errors: this.cacheErrors
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.bxs-checkbox-field {
  margin-bottom: var(--input-margin-bottom);
  font-size: var(--input-font-size);
  cursor: pointer;
  .checkbox {
    width: var(--input-font-size);
    height: var(--input-font-size);
    display: inline-block;
    border: 1px solid var(--input-color);
    transform: translate(0, 1px);
    transition: background-color .1s ease-in-out;
    margin-right: 1rem;
  }

  input[type="checkbox"]:checked + .checkbox{
    background-color: var(--color-primary);
  }

  input{
    visibility: hidden;
    width: 0;
    height: 0;
    overflow: hidden;
  }

  label {
    font-size: inherit;
    display: flex;
    flex-flow: row nowrap;
    cursor: pointer;

    span {
      flex: 1;
    }
  }
}
</style>
