<template>
    <div class="storie-di-leila-view">
        <bxs-hero
        title="Storie di Leila"
        height="50vh"
        src="/img/storie-di-leila/federica-ferraro-21-06-2023/4.png"></bxs-hero>

        <section>
            <bxs-layout>
                <div class="mb-24 text-center">
                    <h4>Le strisce a fumetti sulla Biblioteca Degli Oggetti</h4>
                </div>

                <ul>
                    <li
                    v-for="(item, i) in items"
                    :key="i"
                    class="mb-24 mb-0-last">
                        <div class="mb-ui">
                            <p class="text-disabled text-400">{{ item.title }}</p>
                        </div>

                        <bxs-row no-gutters>
                            <bxs-col
                            v-for="(img, i2) in item.imgs"
                            :key="i2"
                            cols="3">
                                <bxs-figure
                                :src="img" />
                            </bxs-col>
                        </bxs-row>
                    </li>
                </ul>
            </bxs-layout>
        </section>

        <!-- ------------------------------------------------------------------------------------------------------------------
            chi siamo
        ------------------------------------------------------------------------------------------------------------------ -->
        <section-about />

        <!-- ------------------------------------------------------------------------------------------------------------------
            come funziona
        ------------------------------------------------------------------------------------------------------------------ -->
        <section-come-funziona />

        <!-- ------------------------------------------------------------------------------------------------------------------
            tessera
        ------------------------------------------------------------------------------------------------------------------ -->
        <section-tessera />
    </div>
</template>

<script>
export default {
    name: 'storie_di_leila',
    data () {
        return {
            items: [
                {
                    title: 'Federica Ferraro - 21/06/2023',
                    imgs: [
                        '/img/storie-di-leila/federica-ferraro-21-06-2023/1.png',
                        '/img/storie-di-leila/federica-ferraro-21-06-2023/2.png',
                        '/img/storie-di-leila/federica-ferraro-21-06-2023/3.png',
                        '/img/storie-di-leila/federica-ferraro-21-06-2023/4.png',
                        '/img/storie-di-leila/federica-ferraro-21-06-2023/5.png',
                        '/img/storie-di-leila/federica-ferraro-21-06-2023/6.png',
                        '/img/storie-di-leila/federica-ferraro-21-06-2023/7.png'
                    ]
                },
                {
                    title: 'Federica Ferraro – 12/05/2023',
                    imgs: [
                        'https://leila-bologna.it/wp-content/uploads/2023/09/photo_2023-05-31_10-35-17.jpg',
                        'https://leila-bologna.it/wp-content/uploads/2023/09/photo_2023-05-31_10-35-19.jpg',
                        'https://leila-bologna.it/wp-content/uploads/2023/09/photo_2023-05-31_10-35-23.jpg',
                        'https://leila-bologna.it/wp-content/uploads/2023/09/photo_2023-05-31_10-35-25.jpg',
                        'https://leila-bologna.it/wp-content/uploads/2023/09/photo_2023-05-31_10-35-29.jpg'
                    ]
                },
                {
                    title: 'Federica Ferraro – 14/04/2023',
                    imgs: [
                        'https://leila-bologna.it/wp-content/uploads/2023/09/1.jpg',
                        'https://leila-bologna.it/wp-content/uploads/2023/09/2.jpg',
                        'https://leila-bologna.it/wp-content/uploads/2023/09/3.jpg',
                        'https://leila-bologna.it/wp-content/uploads/2023/09/4.jpg',
                        'https://leila-bologna.it/wp-content/uploads/2023/09/5.jpg',
                        'https://leila-bologna.it/wp-content/uploads/2023/09/6.jpg'
                    ]
                },
                {
                    title: 'Federica Ferraro – 16/03/2023',
                    imgs: [
                        'https://leila-bologna.it/wp-content/uploads/2023/04/Federica-Ferraro-1.jpg',
                        'https://leila-bologna.it/wp-content/uploads/2023/04/Federica-Ferraro-2.jpg',
                        'https://leila-bologna.it/wp-content/uploads/2023/04/Federica-Ferraro-3.jpg',
                        'https://leila-bologna.it/wp-content/uploads/2023/04/Federica-Ferraro-4.jpg',
                        'https://leila-bologna.it/wp-content/uploads/2023/04/Federica-Ferraro-5.jpg',
                        'https://leila-bologna.it/wp-content/uploads/2023/04/Federica-Ferraro-6.jpg'
                    ]
                },
                {
                    title: 'Marta Battocchio – 12/07/2022',
                    imgs: [
                        'https://leila-bologna.it/wp-content/uploads/2022/12/Storie-di-Leila-6-1.jpg',
                        'https://leila-bologna.it/wp-content/uploads/2022/12/Storie-di-Leila-6-2.jpg',
                        'https://leila-bologna.it/wp-content/uploads/2022/12/Storie-di-Leila-6-3.jpg',
                        'https://leila-bologna.it/wp-content/uploads/2022/12/Storie-di-Leila-6-4.jpg',
                        'https://leila-bologna.it/wp-content/uploads/2022/12/Storie-di-Leila-6-5.jpg',
                        'https://leila-bologna.it/wp-content/uploads/2022/12/Storie-di-Leila-6-6.jpg'
                    ]
                },
                {
                    title: 'Marta Battocchio – 10/03/2022',
                    imgs: [
                        'https://leila-bologna.it/wp-content/uploads/2022/12/Storie-di-Leila-5-1.jpg',
                        'https://leila-bologna.it/wp-content/uploads/2022/12/Storie-di-Leila-5-2.jpg',
                        'https://leila-bologna.it/wp-content/uploads/2022/12/Storie-di-Leila-5-3.jpg',
                        'https://leila-bologna.it/wp-content/uploads/2022/12/Storie-di-Leila-5-4.jpg',
                        'https://leila-bologna.it/wp-content/uploads/2022/12/Storie-di-Leila-5-5.jpg'
                    ]
                },
                {
                    title: 'Marta Battocchio – 09/02/2022',
                    imgs: [
                        'https://leila-bologna.it/wp-content/uploads/2022/12/Storie-di-Leila-4-1.jpg',
                        'https://leila-bologna.it/wp-content/uploads/2022/12/Storie-di-Leila-4-2.jpg',
                        'https://leila-bologna.it/wp-content/uploads/2022/12/Storie-di-Leila-4-3.jpg',
                        'https://leila-bologna.it/wp-content/uploads/2022/12/Storie-di-Leila-4-4.jpg',
                        'https://leila-bologna.it/wp-content/uploads/2022/12/Storie-di-Leila-4-5.jpg',
                        'https://leila-bologna.it/wp-content/uploads/2022/12/Storie-di-Leila-4-6.jpg',
                        'https://leila-bologna.it/wp-content/uploads/2022/12/Storie-di-Leila-4-7.jpg'
                    ]
                },
                {
                    title: 'Marta Battocchio – 03/09/2021',
                    imgs: [
                        'https://leila-bologna.it/wp-content/uploads/2022/12/Storie-di-Leila-3-1.jpg',
                        'https://leila-bologna.it/wp-content/uploads/2022/12/Storie-di-Leila-3-2.jpg',
                        'https://leila-bologna.it/wp-content/uploads/2022/12/Storie-di-Leila-3-3.jpg',
                        'https://leila-bologna.it/wp-content/uploads/2022/12/Storie-di-Leila-3-4.jpg',
                        'https://leila-bologna.it/wp-content/uploads/2022/12/Storie-di-Leila-3-5.jpg',
                        'https://leila-bologna.it/wp-content/uploads/2022/12/Storie-di-Leila-3-6.jpg',
                        'https://leila-bologna.it/wp-content/uploads/2022/12/Storie-di-Leila-3-7.png'
                    ]
                },
                {
                    title: 'Marta Battocchio – 27/07/2021',
                    imgs: [
                        'https://leila-bologna.it/wp-content/uploads/2022/12/Storie-di-Leila-2-1.jpg',
                        'https://leila-bologna.it/wp-content/uploads/2022/12/Storie-di-Leila-2-2.jpg',
                        'https://leila-bologna.it/wp-content/uploads/2022/12/Storie-di-Leila-2-3.jpg',
                        'https://leila-bologna.it/wp-content/uploads/2022/12/Storie-di-Leila-2-4.jpg',
                        'https://leila-bologna.it/wp-content/uploads/2022/12/Storie-di-Leila-2-5.jpg',
                        'https://leila-bologna.it/wp-content/uploads/2022/12/Storie-di-Leila-2-6.jpg',
                        'https://leila-bologna.it/wp-content/uploads/2022/12/Storie-di-Leila-2-7.jpg',
                        'https://leila-bologna.it/wp-content/uploads/2022/12/Storie-di-Leila-2-8.jpg'
                    ]
                },
                {
                    title: 'Marta Battocchio – 20/07/2021',
                    imgs: [
                        'https://leila-bologna.it/wp-content/uploads/2022/12/Storie-di-Leila-1-1.jpg',
                        'https://leila-bologna.it/wp-content/uploads/2022/12/Storie-di-Leila-1-2.jpg',
                        'https://leila-bologna.it/wp-content/uploads/2022/12/Storie-di-Leila-1-3.jpg',
                        'https://leila-bologna.it/wp-content/uploads/2022/12/Storie-di-Leila-1-4.jpg',
                        'https://leila-bologna.it/wp-content/uploads/2022/12/Storie-di-Leila-1-5.jpg',
                        'https://leila-bologna.it/wp-content/uploads/2022/12/Storie-di-Leila-1-6.jpg',
                        'https://leila-bologna.it/wp-content/uploads/2022/12/Storie-di-Leila-1-7.jpg'
                    ]
                }
            ]
        }
    }
}
</script>