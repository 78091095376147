const url = new URL(window.location.href)

const d = {
    toStaticFile(v) {
        if (!v) return v
        // const env = process.env.NODE_ENV
        // if (v.startsWith('http')) v = v.replace('http://localhost:8080/', '')
        return `${process.env.NODE_ENV === 'development' ? process.env.VUE_APP_API : url.origin}/${v}`
    },
    toStaticFileMaster (v) {
        if (!v) return v
        // const env = process.env.NODE_ENV
        // if (v.startsWith('http')) v = v.replace('http://localhost:8080/', '')
        return `${process.env.VUE_APP_WEBAPP_API}/${v}`
    }
}

export default d