<template>
    <div class="collaborazioni-blocco-comune">
        <bxs-hero
        src="/img/backbo-logo-orizzontale.png"
        light
        overlay>
            <div class="flex column align-center justify-center text-center pa-ui fill-height">
                <div>
                    <h1 class="text-500">Collaborazione - BackBo</h1>
                </div>
            </div>
        </bxs-hero>

        <!-- ------------------------------------------------------------------------------------------------------------------
        ------------------------------------------------------------------------------------------------------------------ -->
        <section>
            <bxs-layout>
                <bxs-row>
                    <bxs-col
                    cols="12"
                    ds="6">
                        <div class="flex nowrap">
                            <bxs-figure
                            src="/img/backbo-logo-orizzontale.png"
                            max-width="120"
                            class="mr-ui"></bxs-figure>

                            <div>
                                <p class="mb-2 text-400">BACKBO APS</p>
                                <small>DumBO Space - Via Camillo Casarini, 19 - 40131 Bologna (BO)</small> <br>
                                <small>info@arcowood.it</small> <br>
                                <a href="https://backbo.it" target="_blank"><small>Sito web</small></a> <br>
                                <a href="https://www.instagram.com/backbo.hub" target="_blank"><small>Instagram</small></a> <br>
                                <a href="https://www.facebook.com/BackBO.hub" target="_blank"><small>Facebook</small></a>
                            </div>
                        </div>
                    </bxs-col>

                    <bxs-col
                    cols="12"
                    ds="6">
                        <p class="mb-2 text-400 text-disabled">Contatti</p>
                        <a href="">327 838 8717</a>
                    </bxs-col>
                </bxs-row>
            </bxs-layout>
        </section>

        <!-- ------------------------------------------------------------------------------------------------------------------
        ------------------------------------------------------------------------------------------------------------------ -->
        <section>
            <bxs-carousel
            :items="[
                '/img/collaborazioni/backbo/collaborazioni-backbo-3.jpg',
                '/img/collaborazioni/backbo/collaborazioni-backbo-1.jpg',
                '/img/collaborazioni/backbo/collaborazioni-backbo-2.jpg',
                'https://backbo.it/wp-content/uploads/2021/05/BackBO-23-scaled.jpg',
                'https://backbo.it/wp-content/uploads/2021/05/BackBO-74-scaled.jpg',
                'https://backbo.it/wp-content/uploads/2019/12/BackBo_Economia_Circolare_Stampa_Plastica_Riciclata_Gadget.jpg'
            ]"
            items-per-view="4"
            navigation
            pagination>
                <template #item="{ item }">
                    <bxs-figure
                    :src="item"
                    ratio="3-2" />
                </template>
            </bxs-carousel>
        </section>

        <!-- ------------------------------------------------------------------------------------------------------------------
        ------------------------------------------------------------------------------------------------------------------ -->
        <section>
            <bxs-layout>
                <p>BackBO è una hub di economia circolare, il cui obiettivo è ridurre gli sprechi e sensibilizzare la cittadinanza sull'inquinamento legato all'usa-e-getta. Obiettivo che può essere raggiunto dal basso, nel nostro piccolo, attraverso le azioni quotidiane, anche attraverso l'organizzazione di workshop sul riciclo creativo della plastica e fornendo un servizio di bicchieri riutilizzabili in plastica rigida, in alternativa al monouso.</p>
                <p>BackBO fornisce a Leila bicchieri riutilizzabili in plastica rigida per contribuire a dare vita ad un servizio di stoviglioteca per feste ed eventi. Nel recente passato abbiamo condiviso l'esperienza dei VezLab, un ciclo di workshop grazie ai quali i "vez" di Bologna hanno contribuito a far riscoprire ai più giovani sane, vecchie abitudini di uso-e-riuso.</p>
            </bxs-layout>
        </section>
    </div>
</template>

<script>
export default {
    name: 'collaborazioni_backbo'
}
</script>