import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import createComps from './components'
import filters from './filters'

// libs
import Http from './assets/libs/Http'
import Eventer from './assets/libs/Eventer'
import VueGtag from 'vue-gtag'

if (process.env.NODE_ENV === 'production') {
    console.log = function () {}
}

const app = createApp(App)

app.use(VueGtag, {
    config: { id: 'G-2NYHH8BDNJ' },
    router,
    enabled: true
})

// Will be used later
app.provide('gtag', app.config.globalProperties.$gtag)

app.config.globalProperties.$filters = filters
app.config.globalProperties.$wp = new Http(process.env.VUE_APP_WP_API)
app.config.globalProperties.$api = new Http(process.env.VUE_APP_WEBAPP_API)
app.config.globalProperties.$eventHub = new Eventer()

// comps
createComps(app)

// app.config.errorHandler = (err, vm, info) => {
//     console.log('error handler', err, vm, info)

//     if (err.code && err.code === 'rest_no_route') {
//         if (err.data && err.data.status && err.data.status === 404) {
//             vm.$router.push(404)
//         }
//     }
// }

app.use(store).use(router).mount('#app')