// comps
import Hero from './Hero.vue'
import Layout from './layout/Layout.vue'
import Row from './layout/Row/Row.vue'
import Col from './layout/Col/Col.vue'

import Carousel from './Carousel.vue'
import Alert from './Alert.vue'
import Btn from './Btn.vue'
import Chip from './Chip.vue'
import ChipGroup from './ChipGroup.vue'
import Icon from './Icon.vue'
import Menu from './Menu.vue'
import List from './List.vue'
import ListItem from './ListItem.vue'
import CollapseItem from './CollapseItem.vue'
import Spacer from './Spacer.vue'
import Toolbar from './Toolbar.vue'
import Card from './Card.vue'
import Figure from './media/FigureComp.vue'
import Loader from './Loader.vue'
import Drawer from './Drawer.vue'
import BottomNavigation from './BottomNavigation.vue'
import TopNavigation from './TopNavigation.vue'
import Stepper from './Stepper.vue'
import Title from './Title.vue'
import Divider from './Divider.vue'
import Modal from './Modal.vue'
import Marquee from './Marquee.vue'

import Form from './form/Form'
import BxsSwitchField from './form/BxsSwitchField.vue'
import BxsTextField from './form/TextField.vue'
import BxsTextareaField from './form/BxsTextareaField.vue'
import Checkbox from './form/Checkbox.vue'
import BxsSelectField from './form/SelectField.vue'
import BxsRadioField from './form/RadioField.vue'
import RadioGroupField from './form/RadioGroupField.vue'

import DataLazy from './DataLazy.vue'

// project
import Logo from './Logo.vue'
import ProductsUx from './project/ProductsUx.vue'
import BrushUx from './project/Brush.vue'
import SharePage from './project/SharePage.vue'
import LeilaListitem from './project/LeilaListItem.vue'
import Scrolldown from './project/Scrolldown.vue'
import Link from './project/Link.vue'
import SectionSedi from './project/SectionSedi.vue'
import SectionDiconoDiNoi from './project/SectionDiconoDiNoi.vue'
import SectionComeFunziona from './project/SectionComeFunziona.vue'
import SectionComeFunzionaFull from './project/SectionComeFunzionaFull.vue'
import SectionTessera from './project/SectionTessera.vue'
import SectionAbout from './project/SectionAbout.vue'
import SectionLatestNews from './project/SectionLatestNews.vue'
import SectionSocial from './project/SectionSocial.vue'
import SectionVoiELeila from './project/SectionVoiELeila.vue'
import SectionFareCondivisione from './project/SectionFareCondivisione.vue'
import SectionLaRete from './project/SectionLaRete.vue'
import SectionStorieDiLeila from './project/SectionStorieDiLeila.vue'
import SectionOggetti from './project/SectionOggetti.vue'
import FormVolontariato from './project/FormVolontariato.vue'
import FormProponiProgetto from './project/FormProponiProgetto.vue'
import FormCreaLeila from './project/FormCreaLeila.vue'
import FormCargoBike from './project/FormCargoBike.vue'
import FormRinnovoTessera from './project/FormRinnovoTessera.vue'
import FormContact from './project/FormContact.vue'
import SectionCollaborazioni from './project/SectionCollaborazioni.vue'
import SectionCreaUnaLeilaAncheTu from './project/SectionCreaUnaLeilaAncheTu.vue'
import SectionINumeri from './project/SectionINumeri.vue'

// libs
import Toast, { useToast } from 'vue-toastification'
import stickybits from 'stickybits'

import 'vue-toastification/dist/index.css'

export default function createComp(app) {
    app.component('logo', Logo)
    app.component('products-ux', ProductsUx)
    app.component('brush-ux', BrushUx)
    app.component('leila-list-item', LeilaListitem)
    app.component('lel-share-page', SharePage)
    app.component('scrolldown', Scrolldown)
    app.component('section-sedi', SectionSedi)
    app.component('section-come-funziona', SectionComeFunziona)
    app.component('section-come-funziona-full', SectionComeFunzionaFull)
    app.component('section-about', SectionAbout)
    app.component('section-latest-news', SectionLatestNews)
    app.component('section-dicono-di-noi', SectionDiconoDiNoi)
    app.component('section-i-numeri', SectionINumeri)
    app.component('section-tessera', SectionTessera)
    app.component('section-social', SectionSocial)
    app.component('section-voi-e-leila', SectionVoiELeila)
    app.component('section-fare-condivisione', SectionFareCondivisione)
    app.component('section-la-rete', SectionLaRete)
    app.component('section-storie-di-leila', SectionStorieDiLeila)
    app.component('section-oggetti', SectionOggetti)
    app.component('form-volontariato', FormVolontariato)
    app.component('form-proponi-progetto', FormProponiProgetto)
    app.component('form-crea-leila', FormCreaLeila)
    app.component('form-cargo-bike', FormCargoBike)
    app.component('form-rinnovo-tessera', FormRinnovoTessera)
    app.component('form-contatto', FormContact)
    app.component('section-collaborazioni', SectionCollaborazioni)
    app.component('section-crea-una-leila-anche-tu', SectionCreaUnaLeilaAncheTu)

    // --

    app.component('bxs-hero', Hero)
    app.component('bxs-layout', Layout)
    app.component('bxs-row', Row)
    app.component('bxs-col', Col)

    app.component('bxs-carousel', Carousel)
    app.component('bxs-marquee', Marquee)
    app.component('bxs-toolbar', Toolbar)
    app.component('bxs-bottom-nav', BottomNavigation)
    app.component('bxs-top-nav', TopNavigation)
    app.component('bxs-stepper', Stepper)
    app.component('bxs-divider', Divider)
    app.component('bxs-modal', Modal)
    app.component('bxs-link', Link)

    app.component('bxs-card', Card)
    app.component('bxs-data-lazy', DataLazy)
    app.component('bxs-figure', Figure)

    app.component('bxs-btn', Btn)
    app.component('bxs-menu', Menu)
    app.component('bxs-drawer', Drawer)
    app.component('bxs-list', List)
    app.component('bxs-list-item', ListItem)
    app.component('bxs-collapse-item', CollapseItem)

    app.component('bxs-icon', Icon)
    app.component('bxs-chip', Chip)
    app.component('bxs-chip-group', ChipGroup)
    app.component('bxs-loader', Loader)

    app.component('bxs-form', Form)
    app.component('bxs-switch-field', BxsSwitchField)
    app.component('bxs-text-field', BxsTextField)
    app.component('bxs-textarea-field', BxsTextareaField)
    app.component('bxs-checkbox', Checkbox)
    app.component('bxs-select-field', BxsSelectField)
    app.component('bxs-radio-group-field', RadioGroupField)
    app.component('bxs-radio-field', BxsRadioField)

    app.component('bxs-spacer', Spacer)
    app.component('bxs-alert', Alert)
    app.component('bxs-title', Title)

    app.use(Toast, {
        position: 'top-center',
        timeout: 5000,
        closeOnClick: true,
        pauseOnFocusLoss: false,
        pauseOnHover: false,
        newestOnTop: false,
        transition: 'Vue-Toastification__fade',
        bodyClassName: ['bxs-notify'],
        maxToasts: 1,
        showCloseButtonOnHover: false,
        hideProgressBar: true,
        draggable: false
    })

    app.config.globalProperties.$notify = useToast()
    app.config.globalProperties.$sticky = stickybits
}
