<template>
    <div class="collaborazioni-blocco-comune">
        <bxs-hero
        src="/img/la-rete/atpico/logo-atpico.png"
        light
        overlay>
            <div class="flex column align-center justify-center text-center pa-ui fill-height">
                <div>
                    <h1 class="text-500">Collaborazione - ATPiCO</h1>
                </div>
            </div>
        </bxs-hero>

        <!-- ------------------------------------------------------------------------------------------------------------------
        ------------------------------------------------------------------------------------------------------------------ -->
        <section>
            <bxs-layout>
                <bxs-row>
                    <bxs-col
                    cols="12"
                    ds="6">
                        <div class="flex nowrap">
                            <bxs-figure
                            src="/img/la-rete/atpico/logo-atpicoa.png"
                            max-width="120"
                            class="mr-ui"></bxs-figure>

                            <div>
                                <p class="mb-2 text-400">ATPiCO A.P.S.</p>
                                <small>Via Olivera, 18 - 31020 San Vendemiano (TV)</small> <br>
                                <small>info@atpico.it</small> <br>
                                <a href="https://atpico.it" target="_blank"><small>Sito web</small></a> <br>
                                <a href="https://www.instagram.com/atpico_aps" target="_blank"><small>Instagram</small></a>
                                <a href="https://www.facebook.com/ATPiCOaps" target="_blank"><small>Facebook</small></a>
                            </div>
                        </div>
                    </bxs-col>

                    <bxs-col
                    cols="12"
                    ds="6">
                        <p class="mb-2 text-400">Referente</p>
                        <small>Lorem ipsum</small> <br>
                        <a href="tel:+393534383069" target="_blank"><small>353 43 83 069</small></a> <br>
                    </bxs-col>
                </bxs-row>
            </bxs-layout>
        </section>

        <!-- ------------------------------------------------------------------------------------------------------------------
        ------------------------------------------------------------------------------------------------------------------ -->
        <section>
            <bxs-carousel
            :items="[
                '/img/la-rete/atpico/foto-insieme.jpg',
                '/img/la-rete/atpico/post.jpeg',
            ]"
            items-per-view="4">
                <template #item="{ item }">
                    <bxs-figure
                    :src="item"
                    ratio="3-2" />
                </template>
            </bxs-carousel>
        </section>

        <!-- ------------------------------------------------------------------------------------------------------------------
        ------------------------------------------------------------------------------------------------------------------ -->
        <section>
            <bxs-layout>
                <p>ATPiCO è una neonata associazione con sede a San Vendemiano in provincia di Treviso, fondata da Silvio Stragapede, Luca Foltran e Saverio Stragapede, con l'intento di portare nella loro terra natale le spinte culturali che hanno conosciuto ed apprezzato in giro per il mondo. Alla faccia del "Qui da noi non succede mai niente"! </p>

                <div>
                    <p>L'attività dell'associazione è composta da tre pilastri. </p>
                    <p>Il primo è La Biblioteca Degli Oggetti, in rete con Leila di Bologna, che ha conquistato ATPiCO per la sua semplicità ed efficacia. </p>
                </div>

                <div>
                    <p>Il secondo, che per ora è ancora in fase progettuale, è il Laboratorio condiviso: una stanza con diversi banchi attrezzati con il necessario per la lavorazione del legno, del ferro, per lavori di cucito, di riparazione biciclette ed ogni altra attività che la creatività farà emergere.  Un luogo che darà l'opportunità a tutti gli appassionati di incontrarsi e avere uno spazio dove divertirsi, arrangiarsi, ingegnarsi nel perfezionare le proprie competenze manuali. </p>
                    <p>Il terzo è il Repair Café. Si tratta del movimento europeo che si occupa della periodica organizzazione di eventi che fanno incontrare i proprietari di oggetti rotti con volontari e appassionati in grado di ripararli. </p>
                </div>

                <div>
                    <p>ATPiCO è dunque un nome scelto intenzionalmente per omaggiare la vitalità nella sua forma chimica (l'ATP appunto), elemento che siamo certi caratterizzerà tutti coloro che saranno attratti da questa realtà. </p>
                </div>
            </bxs-layout>
        </section>

        <!-- ------------------------------------------------------------------------------------------------------------------
        ------------------------------------------------------------------------------------------------------------------ -->
        <section>
            <bxs-layout>
                <p>--- descrizione di quello che abbiamo fatto insieme, che stiamo facendo, che faremo e del perché questa collaborazione è per voi significativa ---</p>
                <p>Abbiamo conosciuto Leila e la Biblioteca degli Oggetti mentre cercavamo ispirazione dai laboratori condivisi esistenti in Italia. E ce ne siamo innamorati! </p>
                <p>La semplicità dell'idea, la sua inclusività, la sua portata, ci hanno subito dato una certezza: ATPiCO avrebbe avuto una Biblioteca degli Oggetti! </p>
                <p>Abbiamo quindi conosciuto Antonio che ci ha accompagnato nella scoperta di questo progetto, ci ha dato gli strumenti per realizzarlo, e ci ha parlato del progetto di Leila Italia. In quel momento abbiamo capito che stavamo entrando a far parte di qualcosa di grande, di un progetto che sarebbe cresciuto oltre i confini della nostra piccola cittadina. </p>
                <p>Certo, quello che facciamo nel nostro piccolo può essere un esempio, ma se questo esempio è conosciuto e seguito, il suo valore si amplifica. E' proprio questo il progetto di Leila Italia: far conoscere il progetto, diffonderlo e, chissà, magari un giorno potremo avere una Biblioteca degli Oggetti in ogni città. Ve lo immaginate? Noi si :)</p>
            </bxs-layout>
        </section>
    </div>
</template>

<script>
export default {
    name: 'la_rete_atpico'
}
</script>