<template>
    <div class="collaborazioni-blocco-comune">
        <bxs-hero
        src="https://leila-bologna.it/wp-content/uploads/2022/11/Quattrocchi_Logo-1024x755.png"
        light
        overlay>
            <div class="flex column align-center justify-center text-center pa-ui fill-height">
                <div>
                    <h1 class="text-500">Collaborazione - Il Biricoccolo</h1>
                </div>
            </div>
        </bxs-hero>

        <!-- ------------------------------------------------------------------------------------------------------------------
        ------------------------------------------------------------------------------------------------------------------ -->
        <section>
            <bxs-layout>
                <bxs-row>
                    <bxs-col
                    cols="12"
                    ds="6">
                        <div class="flex nowrap">
                            <bxs-figure
                            src="https://leila-bologna.it/wp-content/uploads/2022/12/logo.jpg"
                            max-width="120"
                            class="mr-ui"></bxs-figure>

                            <div>
                                <p class="mb-2 text-400">Il Biricoccolo Fattoria Sociale e CSA</p>
                                <small>Via Tombetto, 13 – Località Crespellano 40053 Valsamoggia (BO)</small> <br>
                                <a href="https://orecchiedasino57.wixsite.com/o-d-a" target="_blank"><small>Sito web</small></a> <br>
                                <a href="https://www.instagram.com/orecchie_dasino/" target="_blank"><small>Instagram</small></a> <br>
                            </div>
                        </div>
                    </bxs-col>

                    <bxs-col
                    cols="12"
                    ds="6">
                        <p class="mb-2 text-400">Contatti</p>
                        <a href="mailto:orecchiedasino57@gmail.com" target="_blank"><small>orecchiedasino57@gmail.com</small></a> <br>
                    </bxs-col>
                </bxs-row>
            </bxs-layout>
        </section>

        <!-- ------------------------------------------------------------------------------------------------------------------
        ------------------------------------------------------------------------------------------------------------------ -->
        <section>
            <bxs-carousel
            :items="[
                'https://leila-bologna.it/wp-content/uploads/2022/11/Quattrocchi_1.jpg',
                'https://leila-bologna.it/wp-content/uploads/2022/11/Quattrocchi_2.jpg'
            ]"
            items-per-view="4">
                <template #item="{ item }">
                    <bxs-figure
                    :src="item"
                    ratio="3-2" />
                </template>
            </bxs-carousel>
        </section>

        <!-- ------------------------------------------------------------------------------------------------------------------
        ------------------------------------------------------------------------------------------------------------------ -->
        <section>
            <bxs-layout>
                <div>
                    <p>QUATTROCCHI è un progetto realizzato dal duo artistico Orecchie D’Asino, formato da Ornella De Carlo e Federica Porro. Creando una finta corporate, le artiste hanno dato forma ad un immaginario che consentisse l’azione diretta con i cittadini e quindi funzionasse da interruttore per generare un cortocircuito</p>
                    <p>Orecchie D’Asino lavorano nel campo artistico dal 2018, la loro pratica si muove da esercizi di improvvisazione teatrale per espandersi poi nella ricerca del quotidiano e dell’attività onirica, attraverso diversi linguaggi e metodologie artistiche. Negli anni prendono parte a diverse mostre personali e collettive oltre che a far parte di premi nazionali e residenze artistiche.</p>
                </div>

                <div>
                    <p>QUATTROCCHI è un progetto ideato da Orecchie D’Asino e realizzato nell’inverno del 2021 in collaborazione con Leila – La biblioteca degli oggetti grazie al supporto della Biblioteca Salaborsa e del Comune di Bologna.</p>
                    <p>QUATTROCCHI è un esperimento collettivo che riutilizza gli oggetti per produrre un gioco di scambio, ripensare le dinamiche e le abitudini nascoste sotto la superficie del quotidiano, rinnovare il senso dell’incontro e del fare rete in modalità offline e mettere in discussione concetti chiave del mondo contemporaneo come scambio e fiducia. Come avvengono le relazioni nella nostra società? Che valore hanno? Come immaginiamo le relazioni del futuro? In tempi di Facebook, Tinder e Instagram, sappiamo ancora incontrarci a quattrocchi?</p>
                    <p>Basandosi sull’impatto delle tecnologie e la fragilità dei legami che ne deriva, QUATTROCCHI si dirama in modo trasversale e intergenerazionale tra i luoghi e gli abitanti della città come un gioco di società in cui lo scambio, la fiducia e l’incontro sono i traguardi e le tappe da raggiungere. Orecchie D’Asino cerca di invertire il processo consumistico e capitalistico del nostro presente che aumenta sempre di più la fragilità della vita sociale e la friabilità delle relazioni: creando una finta corporate che distribuisce scatole in cui l’oggetto e la sua immagine vengono nascoste, si riscopre così il piacere della sorpresa e del caso. L’oggetto non acquista importanza grazie alle sue caratteristiche esibite ma si trasforma in un link verso una nuova sfera esperienziale. Simili intenzioni e riflessioni sono coincise con gli obiettivi di Leila Bologna, non solo la biblioteca degli oggetti, ma anche un luogo vitale di esperienze e socialità. È proprio grazie alla sintonia e alla collaborazione con lo spazio e le persone di Leila che QUATTROCCHI ha ritrovato la voglia e l’energia per essere realizzato anche sul suolo di Bologna.</p>
                    <p>Il progetto si è strutturato quindi in una prima fase (20 novembre – 5 dicembre 2021) di raccolta dei partecipanti e dei loro oggetti attraverso un desk presso la piazza coperta della Biblioteca Salaborsa ed in una seconda fase (9 – 20 dicembre 2022) di distribuzione in cui Orecchie D’Asino distribuiva le scatole e il loro contenuto nascosto all’ingresso della Biblioteca Salaborsa. Le persone che decidevano di prendere una delle scatole aderivano al progetto e quindi avrebbero incontrato il proprietario dell’oggetto. Nella terza fase (gennaio 2022) sono avvenuti gli incontri tra i partecipanti ai progetti lungo le fermate degli autobus di Bologna. Ad essi è stato richiesto di inviare un feedback delle esperienze e delle conoscenze nate grazie all’incontro che QUATTROCCHI aveva pianificato, oltre che consigliato dei nuovi e anticonformisti modi di approccio.</p>
                    <p>L’intenzione ora è quella di riuscire a pubblicare un libro che possa contenere i vari feedback ricevuti oltre che le chiavi della ricerca che hanno supportato QUATTROCCHI e che hanno mosso Orecchie D’Asino nella realizzazione del progetto. Inoltre non si esclude la possibilità di poter organizzare una giornata di incontro e presentazione della pubblicazione con tutti i partecipanti di QUATTROCCHI.</p>
                </div>
            </bxs-layout>
        </section>
    </div>
</template>

<script>
export default {
    name: 'collaborazioni_quattrocchi'
}
</script>