<template>
    <section :class="['bxs-hero', {
        ...ratio_classes,
        'bxs-hero-fullscreen': fullscreen,
        light: light
    }]"
    :style="{ height, ...ratio_styles }">
        <div>
            <div
            v-if="src"
            class="bxs-hero--src"
            :style="{ backgroundImage: `url(${src})` }"></div>

            <div
            v-if="overlay"
            class="bxs-hero--overlay"></div>

            <bxs-layout
            :max-width="maxWidth"
            :fluid="fluid"
            :no-gutters="noGutters"
            class="bxs-hero--layout">
                <slot>
                    <div class="text-center pa-ui">
                        <h1 class="h2 mb-0 text-white">{{ title }}</h1>
                    </div>
                </slot>
            </bxs-layout>

            <div
            v-if="fullscreen || scrolldown"
            class="bxs-hero--footer px-layout">
                <scrolldown @click="scrollToo" />

                <!-- <ul class="flex nowrap align-center">
                    <li class="mr-ui">
                        <bxs-icon name="ig"></bxs-icon>
                    </li>

                    <li class="mr-ui">
                        <bxs-icon name="fb"></bxs-icon>
                    </li>

                    <li>
                        <bxs-icon name="yt"></bxs-icon>
                    </li>
                </ul> -->
            </div>
        </div>
    </section>
</template>

<script>
import { ratio } from '@/mixins'

export default {
    name: 'hero',
    mixins: [ratio],
    props: {
        src: {
            type: String,
            required: false,
            default: '/img/banner.jpg'
        },
        overlay: {
            type: Boolean,
            required: false,
            default: true
        },
        title: String,
        light: Boolean,
        fluid: Boolean,
        'no-gutters': Boolean,
        fullscreen: Boolean,
        scrolldown: Boolean,
        height: String,
        'max-width': String,
        'scroll-to': String
    },
    methods: {
        scrollToo () {
            if (!this.scrollTo) {
                window.scrollTo({
                    top: window.innerHeight,
                    left: 0,
                    behavior: 'smooth'
                })
            } else {
                const element = document.querySelector(this.scrollTo)
                element.scrollIntoView({
                    behavior: 'smooth',
                    block: 'end',
                    inline: 'nearest'
                })
            }
        }
    }
}
</script>

<style lang="scss" scoped>
@import '@/assets/styles/mq.scss';
.bxs-hero {
    position: relative;
    padding: 7.5vh 0;
    margin: 0;

    &.bxs-hero-fullscreen {
        height: calc(
            100vh - var(--navbar-height)
        );

        // height: 100vh;
        // height: calc(var(--vh, 1vh) * 100);

        @include mq($mq-static-breakpoint) {
            height: calc(
                100vh - var(--navbar-height-desktop)
            );
        }

        > div {
            height: 100%;
        }
    }

    &--src {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        pointer-events: none;
        z-index: 1;
    }

    &--overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        pointer-events: none;
        z-index: 2;

        &::before,
        &::after {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-image: linear-gradient(
                to bottom,
                rgba(#000, 0.05),
                rgba(#000, 0.45)
            );
            background-position: center center;
            background-repeat: no-repeat;
            background-size: cover;
        }
    }

    &--layout {
        position: relative;
        height: 100%;
        z-index: 3;
    }

    &--footer {
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        display: flex;
        flex-flow: row nowrap;
        justify-content: center;
        z-index: 4;
    }
}
</style>