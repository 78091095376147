import { createRouter, createWebHistory } from 'vue-router'
import store from '@/store'

import Error404 from '../views/Error404.vue'
import Index from '../views/Index.vue'
import Page from '../views/Page.vue'

import About from '../views/About.vue'
import ComeFunziona from '../views/ComeFunziona.vue'
import DiconoDiNoi from '../views/DiconoDiNoi.vue'
import OggettiSuggeriti from '../views/OggettiSuggeriti.vue'
import StorieDiLeila from '../views/StorieDiLeila.vue'
import News from '../views/News.vue'
import SinglePost from '../views/SinglePost.vue'
import Statuto from '../views/Statuto.vue'
import Regolamento from '../views/Regolamento.vue'
import Contacts from '../views/Contacts.vue'
import RinnovoTessera from '../views/RinnovoTessera.vue'
import Startup from '../views/Startup.vue'
import OggettiMancanti from '../views/OggettiMancanti.vue'

import CookiePolicy from '../views/CookiePolicy.vue'
import PrivacyPolicy from '../views/PrivacyPolicy.vue'

import LaRete from '../views/LaRete.vue'
import LaReteAtpico from '../views/children/laRete/LaReteAtpico.vue'

import Collaborazioni from '../views/Collaborazioni.vue'
import CollaborazioniLeilaNelleBiblioteche from '../views/children/collaborazioni/CollaborazioniLeilaNelleBiblioteche.vue'
import CollaborazioniBackBo from '../views/children/collaborazioni/CollaborazioniBackBo.vue'
import CollaborazioniBloccoComune from '../views/children/collaborazioni/CollaborazioniBloccoComune.vue'
import CollaborazioniIlBiricoccolo from '../views/children/collaborazioni/CollaborazioniIlBiricoccolo.vue'
import CollaborazioniLeGazze from '../views/children/collaborazioni/CollaborazioniLeGazze.vue'
import CollaborazioniOperaMarella from '../views/children/collaborazioni/CollaborazioniOperaMarella.vue'
import CollaborazioniQuattrocchi from '../views/children/collaborazioni/CollaborazioniQuattrocchi.vue'
import CollaborazioniBarca59 from '../views/children/collaborazioni/CollaborazioniBarca59.vue'

import VoiELeilaCreaUnaLeilaAncheTu from '../views/VoiELeilaCreaUnaLeilaAncheTu.vue'
import VoiELeilaVolontariato from '../views/VoiELeilaVolontariato.vue'
import VoiELeilaProponiIlTuoProgetto from '../views/VoiELeilaProponiIlTuoProgetto.vue'
import VoiELeilaTirocinio from '../views/VoiELeilaTirocinio.vue'

import FareCondivisioneLeilaSocialClub from '../views/FareCondivisioneLeilaSocialClub.vue'
import FareCondivisioneLaboratorio from '../views/FareCondivisioneLaboratorio.vue'
import FareCondivisioneCargoBike from '../views/FareCondivisioneCargoBike.vue'
import FareCondivisioneCoworking from '../views/FareCondivisioneCoworking.vue'

import FormTestPage from '../views/FormTestPage.vue'

const routes = [
  {
    path: '/:pathMatch(.*)*',
    name: 'error_404',
    component: Error404
  },
  // ------------------------------------------------------------
  {
    path: '/',
    name: 'home',
    component: Index
  },
  {
    path: '/',
    name: 'page',
    component: Page
  },
  {
    path: '/storia-e-filosofia-di-leila',
    name: 'about',
    component: About
  },
  {
    path: '/storie-di-leila',
    name: 'storie_di_leila',
    component: StorieDiLeila
  },
  {
    path: '/dicono-di-noi',
    name: 'dicono_di_noi',
    component: DiconoDiNoi
  },
  {
    path: '/rinnovo-tessera',
    name: 'rinnovo_tessera',
    component: RinnovoTessera,
    meta: {
      is_funnell: true
    }
  },
  {
    path: '/startup',
    name: 'startup',
    component: Startup
  },
  {
    path: '/policies/privacy',
    name: 'policies_privacy',
    component: PrivacyPolicy
  },
  {
    path: '/policies/cookie',
    name: 'policies_cookie',
    component: CookiePolicy
  },
  {
    path: '/oggetti-mancanti',
    name: 'oggetti_mancanti',
    component: OggettiMancanti
  },
  // ------------------------------------------------------------
  {
    path: '/fare-condivisione/leila-social-club',
    name: 'fare_condivisione_leila_social_club',
    component: FareCondivisioneLeilaSocialClub
  },
  {
    path: '/fare-condivisione/laboratorio',
    name: 'fare_condivisione_volontariato',
    component: FareCondivisioneLaboratorio
  },
  {
    path: '/fare-condivisione/cargo-bike',
    name: 'fare_condivisione_cargo_bike',
    component: FareCondivisioneCargoBike
  },
  {
    path: '/fare-condivisione/coworking',
    name: 'fare_condivisione_coworking',
    component: FareCondivisioneCoworking
  },
  // ------------------------------------------------------------
  {
    path: '/voi-e-leila/crea-una-leila-anche-tu',
    name: 'voi_e_leila_crea_una_leila_anche_tu',
    component: VoiELeilaCreaUnaLeilaAncheTu
  },
  {
    path: '/voi-e-leila/volontariato',
    name: 'voi_e_leila_volontariato',
    component: VoiELeilaVolontariato
  },
  {
    path: '/voi-e-leila/proponi-il-tuo-progetto',
    name: 'voi_e_leila_proponi_il_tuo_progetto',
    component: VoiELeilaProponiIlTuoProgetto
  },
  {
    path: '/voi-e-leila/tirocinio',
    name: 'voi_e_leila_tirocinio',
    component: VoiELeilaTirocinio
  },
  // ------------------------------------------------------------
  {
    path: '/collaborazioni',
    name: 'collaborazioni',
    component: Collaborazioni,
    children: [
      {
        path: 'leila-nelle-biblioteche',
        name: 'collaborazioni_leile_nelle_biblioteche',
        component: CollaborazioniLeilaNelleBiblioteche
      },
      {
        path: 'backbo',
        name: 'collaborazioni_backbo',
        component: CollaborazioniBackBo
      },
      {
        path: 'blocco-comune',
        name: 'collaborazioni_blocco_comune',
        component: CollaborazioniBloccoComune
      },
      {
        path: 'il-biricoccolo',
        name: 'collaborazioni_il_biricoccolo',
        component: CollaborazioniIlBiricoccolo
      },
      {
        path: 'le-gazze',
        name: 'collaborazioni_le_gazze',
        component: CollaborazioniLeGazze
      },
      {
        path: 'opera-marella',
        name: 'collaborazioni_opera_marella',
        component: CollaborazioniOperaMarella
      },
      {
        path: 'barca59',
        name: 'collaborazioni_barca58',
        component: CollaborazioniBarca59
      },
      {
        path: 'quattrocchi',
        name: 'quattrocchi',
        component: CollaborazioniQuattrocchi
      }
    ]
  },
  // ------------------------------------------------------------
  {
    path: '/la-rete',
    name: 'la_rete',
    component: LaRete,
    children: [
      {
        path: 'atpico',
        name: 'la_rete_atpico',
        component: LaReteAtpico
      }
    ]
  },
  // ------------------------------------------------------------
  {
    path: '/come-funziona',
    name: 'come_funziona',
    component: ComeFunziona
  },
  // ------------------------------------------------------------
  {
    path: '/oggetti-suggeriti',
    name: 'oggetti_suggeriti',
    component: OggettiSuggeriti
  },
  // ------------------------------------------------------------
  {
    path: '/statuto',
    name: 'statuto',
    component: Statuto
  },
  // ------------------------------------------------------------
  {
    path: '/regolamento',
    name: 'regolamento',
    component: Regolamento
  },
  // ------------------------------------------------------------
  {
    path: '/news',
    name: 'news',
    component: News
  },
  {
    path: '/news/:slug',
    name: 'post_blog',
    component: SinglePost
  },
  // ------------------------------------------------------------
  {
    path: '/contatti',
    name: 'contacts',
    component: Contacts
  },
  // ------------------------------------------------------------
  {
    // TODO TEMP PAGE TO BE REMOVED
    path: '/form-test-page',
    name: 'form_test_page',
    component: FormTestPage
  }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
    scrollBehavior(to, from, savedPosition) {
      return { top: 0 }
    }
})

router.beforeEach((to, from, next) => {
    store.commit('setAppIsInTransition', true)
    store.commit('setAppPageEnter', to.name)
    store.commit('setAppPageLeave', from.name)

    next()
})

export default router
