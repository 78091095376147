<template>
    <div
    class="leila-list-item"
    @click="handleClick">
        <div class="leila-list-item--container flex nowrap align-center justify-between">
            <div class="flex nowrap">
                <div
                v-if="src"
                class="mr-4">
                    <bxs-figure
                    :src="src"
                    ratio="1-1"
                    width="80" />
                </div>

                <div class="flex-1">
                    <h6
                    v-if="title"
                    v-html="title"
                    class="text-500 mb-0"></h6>

                    <p
                    v-if="subtitle"
                    v-html="subtitle"
                    class="mb-0 mt-1"></p>
                    <slot />
                </div>
            </div>

            <div>
                <bxs-icon :name="icon"></bxs-icon>
            </div>
        </div>

        <bxs-divider />
    </div>
</template>

<script>
export default {
    name: 'list_item',
    props: {
        title: String,
        subtitle: String,
        href: String,
        src: String,
        icon: {
            type: String,
            required: false,
            default: 'arrow-down-right'
        },
        to: [String, Object]
    },
    methods: {
        handleClick () {
            if (this.href) {
                if (this.href.startsWith('#')) {
                    const el = document.querySelector(this.href)
                    el.scrollIntoView({
                        behavior: 'smooth',
                        block: 'start',
                        inline: 'nearest'
                    })
                } else {
                    window.open(this.href, '_blank')
                }
            }

            if (this.to) {
                this.$router.push(this.to)
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.leila-list-item {
    position: relative;
    cursor: pointer;

    &::before {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: var(--color-secondary);
        transform-origin: left bottom;
        transform: scaleY(0);
        transition: transform 0.2s ease-out;
        z-index: -1;
    }

    @media (hover: hover) and (pointer: fine) {
        &:hover {
            .leila-list-item--container {
                padding-left: 0.5rem;
                padding-right: 0.5rem;
            }

            &::before {
                transform: scaleY(1);
                // transform-origin: left bottom;
            }
        }
    }

    &--container {
        padding-top: 1rem;
        padding-bottom: 1rem;
        transition: padding 0.2s ease-out;
    }
}
</style>