<template>
    <div>
        <bxs-form
        ref="form"
        @submit="submit">
            <bxs-row class="mb-ui">
                <bxs-col
                cols="12"
                ds="6">
                    <div
                    class="form-card bxs-pointer"
                    @click="model.card_type = 'tessera'">
                        <div class="form-card-title">
                            <span>Rinnovo tessera</span>

                            <div class="form-card-price">15,00 €</div>

                            <bxs-icon
                            v-if="model.card_type === 'tessera'"
                            name="check-cirlce-fill"
                            class="form-card-icon"></bxs-icon>
                        </div>

                        <div class="form-card-text">
                            Con la tessera attivi il servizio di condivisione della biblioteca degli oggetti con cui condividere e prendere in prestito tutti gli oggetti che vuoi. Rinnovi anche l'assicurazione, che ti permette di partecipare a qualsiasi attività di Leila come laboratori, workshop, Leila Social Club, ecc.
                        </div>
                    </div>
                </bxs-col>

                <bxs-col
                cols="12"
                ds="6">
                    <div
                    class="form-card bxs-pointer"
                    @click="model.card_type = 'mezza_tessera'">
                        <div class="form-card-title">
                            <span>Rinnovo mezza tessera</span>

                            <div class="form-card-price">10,00 €</div>

                            <bxs-icon
                            v-if="model.card_type === 'mezza_tessera'"
                            name="check-cirlce-fill"
                            class="form-card-icon"></bxs-icon>
                        </div>

                        <div class="form-card-text">
                            Con la mezza tessera potrai beneficiare dell'assicurazione, senza però poter partecipare al servizio di condivisione della biblioteca degli oggetti.
                        </div>
                    </div>
                </bxs-col>
            </bxs-row>

            <bxs-row>
                <bxs-col
                cols="12"
                ds="6">
                    <bxs-text-field
                    v-model="model.email"
                    type="email"
                    label="Email"
                    name="email"
                    required />
                </bxs-col>

                <bxs-col
                cols="12"
                ds="6">
                    <bxs-text-field
                    v-model="model.card_number"
                    label="Numero tessera"
                    name="card_number"
                    required />
                </bxs-col>
            </bxs-row>

            <bxs-text-field
            v-model="model.antispam"
            label="Quiz AntiSpam, quanto fa 7+4?"
            name="antispam"
            required />

            <div class="mb-ui">
                <bxs-btn
                block
                :loading="loading"
                :disabled="!is_submitable"
                @click="$refs.form.submit()">Vai al pagamento con paypal</bxs-btn>
            </div>

            <!-- foot -->
            <div>
                <bxs-checkbox
                v-model="model.privacy"
                id="privacy"
                name="privacy"><small>* Confermo di aver letto e accettato la <router-link to="/policies/privacy">privacy policy</router-link></small></bxs-checkbox>
            </div>

            <!-- error message -->
            <div
            v-if="error"
            class="flex nowrap align-start mt-ui text-error mt-ui">
                <div>
                    <small class="mb-0">Oops, qualcosa è andato storto.</small> <br>
                    <small>{{ error.message }}</small> <br>
                    <small>Al momento non è possible rinnovare la tessera, contattaci per ulteriore supporto.</small>
                </div>
            </div>

            <!-- response message -->
            <bxs-alert
            v-if="success"
            type="success"
            class="mt-ui">{{ success }}</bxs-alert>
        </bxs-form>

        <!--  -->
        <form
        ref="paypal_form"
        action="https://www.paypal.com/cgi-bin/webscr"
        method="post"
        target="_top">
            <input type="hidden" name="image_url" value="https://webapp.leila-bologna.it/img/logo.png" />
            <input type="hidden" name="business" value="donazione@leila-bologna.it" />
            <input type="hidden" name="bn" value="smnfsc_x_leila" />
            <input type="hidden" name="cmd" value="_cart" />
            <input type="hidden" name="lc" value="it_IT" />
            <input type="hidden" name="cbt" value="Leila Bologna" />
            <input type="hidden" name="currency_code" value="EUR" />
            <input type="hidden" name="no_shipping" value="1" />

            <input type="hidden" name="cancel_return" value="https://leila-bologna.it/rinnovo-tessera/?transaction_status=canceled" />
            <input type="hidden" name="return" value="https://leila-bologna.it/rinnovo-tessera/?transaction_status=completed" />
            <input type="hidden" name="rm" value="1">
            <input type="hidden" name="notify_url" value="https://forms.leila-bologna.it/v1/users_renews/ipn" />

            <input type="hidden" name="upload" value="1">

            <input type="hidden" name="item_name_1" :value="model.card_type">
            <input type="hidden" name="amount_1" :value="model.card_type === 'tessera' ? '15.00' : '10.00'" />
            <input type="hidden" name="tax" value="0" />

            <input type="hidden" name="custom" :value="paypal.custom_value" />
        </form>
    </div>
</template>

<script>
// Personal
// sb-0pua81639729@personal.example.com
// u?99Pg0,
export default {
    name: 'form_rinnovo_tessera',
    data () {
        return {
            step: 'recovery_auth',
            loading: false,
            error: null,
            success: null,
            paypal: {
                custom_value: null
            },
            model: {
                // firstname: null,
                // lastname: null,
                email: null,
                card_number: null,
                card_type: 'tessera',
                auth_code: null,
                privacy: false,
                antismap: null
            },
            rules: {
                required: (value) => {
                    if (value) return true
                    return 'Campo richiesto'
                },
                only_numbers: v => /^\d+$/.test(v) || 'questo campo deve contere solo un numero intero',
                email: v => /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i.test(v) || 'inserisci un\'email corretta'
            }
        }
    },
    computed: {
        is_submitable () {
            const v = !this.loading && this.model.antispam === '11' && !!this.model.privacy
            return v
        }
    },
    mounted () {
        this.$nextTick(this.start)
    },
    methods: {
        start () {
            // const top_query_params = new URLSearchParams(window.location.search)
            // const top_transaction_status = top_query_params.get('transaction_status')
            // const top_payer_id = top_query_params.get('PayerID')

            if (this.$route.query.transaction_status === 'completed' && !!this.$route.query.PayerID) {
                this.success = 'Rinnovo completato, riceverai a breve la conferma via email, buona condivisione!'
                this.$notify.success('Rinnovo completato con successo!')
            }
        },
        async submit (body) {
            console.log('form rinnovo tessera submit()', body)
            console.log(this.$refs.form, this.$refs.paypal_form)

            const form = this.$refs.form
            if (!form.valid) return

            this.success = null
            this.error = null
            this.loading = true

            try {
                this.paypal.custom_value = await this.$api.post('/v1/users_renews', {
                    card: {
                        name: this.model.card_type,
                        type: this.model.card_type,
                        price: this.model.card_type === 'tessera' ? 15 : 10
                    },
                    payment: {
                        method: 'paypal'
                    },
                    ...this.model
                })

                await this.$nextTick()

                this.$refs.paypal_form.submit()
            } catch (err) {
                if (err === 'user.not_found') {
                    this.error = 'Ops, nessun membro trovato, verifica l\'email e il numero della tessera'
                } else {
                    this.error = err
                }

                this.loading = false
            }
        }
    }
}
</script>
