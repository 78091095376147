<template>
    <div class="news-view">
        <section
        v-if="!is_preloaded"
        class="text-center">
            <bxs-layout>
                <bxs-loader />
            </bxs-layout>
        </section>

        <div v-else>
            <!--
                height="50vh"
                scrolldown
            -->
            <bxs-hero
            :title="item.title"
            :src="item.img_url"></bxs-hero>

            <section>
                <bxs-layout max-width="1280">
                    <!-- ------------------------------------------------------------------------------------------------------------------------------------------------
                    ------------------------------------------------------------------------------------------------------------------------------------------------ -->
                    <bxs-row class="mb-ui">
                        <bxs-col cols="4">
                            <small class="text-disabled">{{ item.created_at }}</small>
                            <p class="text-500 small mb-0">{{ item.author.name }} <span class="text-disabled text-400">Team Leila</span></p>
                        </bxs-col>

                        <bxs-col cols="4">
                            <small class="text-disabled">Tag</small> <br>
                            <small>
                                <span
                                v-for="(c, i) in item.categories"
                                :key="i">{{ c.name }}<span v-if="i < item.categories.length - 1">, </span></span>
                            </small>
                        </bxs-col>

                        <bxs-col cols="4">
                            <lel-share-page :item="item" />
                        </bxs-col>
                    </bxs-row>

                    <!-- ------------------------------------------------------------------------------------------------------------------------------------------------
                    ------------------------------------------------------------------------------------------------------------------------------------------------ -->
                    <div>
                        <bxs-figure
                        v-if="item.img_url"
                        :src="item.img_url"
                        class="mb-ui"></bxs-figure>

                        <div
                        v-if="item.content"
                        v-html="item.content"
                        class="wysiwyg"></div>

                        <div
                        v-else
                        class="text-center py-ui">
                            <small>No content available</small>
                        </div>
                    </div>
                </bxs-layout>
            </section>

            <bxs-divider></bxs-divider>

            <section-latest-news />
        </div>
    </div>
</template>

<script>
export default {
    name: 'post_blog',
    data () {
        return {
            is_preloaded: false,
            is_loading: true,
            item: null
        }
    },
    mounted () {
        this.$nextTick(this.start)
    },
    methods: {
        async start (slug) {
            await this.getDoc(slug)
            this.is_preloaded = true
        },
        async getDoc (slug) {
            this.is_loading = true

            try {
                this.item = await this.$store.dispatch('getArticle', slug || this.$route.params.slug)
            } catch (err) {
                alert(err)
            } finally {
                this.is_loading = false
            }
        }
    },
    beforeRouteUpdate (to) {
        this.getDoc(to.params.slug)
    },
    beforeUnmount () {
    }
}
</script>