<template>
    <div
    :class="classes"
    :style="styles">
        <div
        class="text-field--content">
            <div
            v-if="$slots.prepend"
            class="text-field--content--prepend-content">
                <slot name="prepend" />
            </div>

            <div
            class="text-field--content--input-content"
            @click="handleClick">
                <label
                v-if="label"
                ref="label"
                :for="name"><em v-if="required">* </em>{{label}}</label>

                <input
                v-if="!currency && !number"
                ref="input"
                v-model="cacheValue"
                :type="internalType"
                :name="name"
                :id="id"
                :aria-label="ariaLabel"
                :placeholder="placeholder"
                :readonly="readonly"
                :disabled="disabled"
                :autofocus="autofocus"
                :autocomplete="autocomplete"
                :minlength="minLength"
                :maxlength="maxLength"
                :required="required"
                @focus="handleFocus(true)"
                @blur="handleFocus(false)"
                @input="handleModelInput"
                @change="$emit('change', cacheValue)">

                <input
                v-else
                ref="inputRef"
                type="text"
                :name="name"
                :id="id"
                :aria-label="ariaLabel"
                :placeholder="placeholder"
                :readonly="readonly"
                :disabled="disabled"
                :autofocus="autofocus"
                :autocomplete="autocomplete"
                :required="required"
                @focus="handleFocus(true)"
                @blur="handleFocus(false)"
                @change="$emit('change', cacheValue)">

                <!-- <div
                v-if="number"
                class="flex column nowrap">
                    <bxs-btn
                    icon
                    small
                    @click="increment">
                        <bxs-icon name="chevron-top"></bxs-icon>
                    </bxs-btn>
                    <bxs-btn
                    icon
                    small
                    @click="decrement">
                        <bxs-icon name="chevron-down"></bxs-icon>
                    </bxs-btn>
                </div> -->
            </div>

            <div
            v-if="$slots.append || clearable"
            class="text-field--content--append-content">
                <slot
                v-if="$slots.append"
                name="append"
                :value="showPass"
                :on="{
                    click: () => showPass = !showPass
                }"
                :attrs="{
                    role: 'button',
                    'aria-haspopup': true,
                    'aria-expanded': false
                }" />

                <bxs-icon
                v-if="clearable && !!cacheValue"
                name="close"
                width="15"
                height="15"
                class="text-mute"
                @click="reset"></bxs-icon>
            </div>
        </div>

        <div
        v-if="!hideDetails && (hint || minLength || maxLength)"
        class="text-field--hint">
            <div v-if="hint">
                <span>{{ hint }}</span>
            </div>

            <div v-if="(minLength || maxLength) && !hideCounter">
                <span v-if="minLength">{{ minLength }}</span>

                <span v-if="minLength && maxLength" class="mx-1">-</span>

                <span v-if="maxLength">{{ charsCount }}</span>
                <span v-if="maxLength" style="margin: 0 1px;">/</span>
                <span v-if="maxLength">{{ maxLength }}</span>
            </div>
        </div>

        <div
        v-if="!valid && cacheErrors.length > 0 && !hideDetails"
        class="text-field--error">
            <ul>
                <li
                v-for="(error, i) in cacheErrors"
                :key="i">{{ error }}</li>
            </ul>
        </div>
    </div>
</template>

<script>
import {
    onMounted,
    onBeforeUnmount,
    inject,
    getCurrentInstance
} from 'vue'

import validator from 'validator'
import { useCurrencyInput } from 'vue-currency-input'

export default {
    name: 'bxs-text-field',
    props: {
        modelValue: {
            type: [String, Number],
            required: false,
            default: null
        },
        id: {
            type: String,
            required: false,
            default: 'id-' + Date.now()
        },
        currency: {
            type: [Object, Boolean],
            required: false,
            default: null
        },
        number: {
            type: [Boolean, Object],
            required: false,
            default: false
        },
        autocomplete: {
            type: [String, Boolean],
            required: false,
            default: 'off'
        },
        clearable: {
            type: Boolean,
            required: false,
            default: false
        },
        rules: {
            type: Array,
            required: false,
            default: () => []
        },
        required: {
            type: Boolean,
            required: false,
            default: false
        },
        debug: {
            type: Boolean,
            required: false,
            default: false
        },
        placeholder: {
            type: String,
            required: false,
            default: null
        },
        type: {
            type: String,
            required: false,
            default: 'text'
        },
        autofocus: {
            type: Boolean,
            required: false,
            default: false
        },
        readonly: {
            type: Boolean,
            required: false,
            default: false
        },
        disabled: {
            type: Boolean,
            required: false,
            default: false
        },
        'aria-label': {
            type: String,
            required: false,
            default: null
        },
        name: {
            type: String,
            required: false,
            default: null
        },
        hint: {
            type: String,
            required: false,
            default: null
        },
        'min-length': {
            type: [String, Number],
            required: false,
            default: null
        },
        'max-length': {
            type: [String, Number],
            required: false,
            default: null
        },
        errors: {
            type: Array,
            required: false,
            default: () => []
        },
        label: {
            type: String,
            required: false,
            default: null
        },
        'max-counter': {
            type: Number,
            required: false,
            default: null
        },
        'hide-details': {
            type: Boolean,
            required: false,
            default: false
        },
        'hide-counter': {
            type: Boolean,
            required: false,
            default: false
        },
        solo: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    emits: [
        'update:modelValue',
        'change'
    ],
    // inject: {
    //     // for default inject
    //     'bxs-form': {
    //         default: null
    //     }
    // },
    data () {
        return {
            cacheValue: this.modelValue,
            charsCount: 0,
            focus: false,
            valid: true,
            cacheErrors: this.errors,
            showPass: false,
            internalType: this.type,
            selected_rules: [],
            local_rules: {
                alphanumeric: (v) => /^[a-z\d]*$/.test(v) || 'codice in maiuscolo, alfanumerico, senza spazio',
                capitalize: (v) => /^[A-Z]$/.test(v) || 'campo con lettere maiuscole',
                capitalizeFirstletter: (v) => /([^A-Z])([A-Z])(?=[A-Z]{2})|^([A-Z])/.test(v) || 'Questo campo deve avere la prima lettera maiuscola',
                dimension: (v) => /^\d+(?:[.]\d{1,3}|$)$/.test(v) || 'campo con 3 decimali',
                email: (v) => /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i.test(v) || 'Email non valida',
                fiscalcode: (v) => /^[A-Za-z]{6}[0-9]{2}[A-Za-z]{1}[0-9]{2}[A-Za-z]{1}[0-9]{3}[A-Za-z]{1}$/.test(v) || 'inserisci un codice fiscale corretto',
                from0To100: (v) => /^0*(?:[1-9][0-9]?|100)$/g.test(v) || 'questo campo deve essere compreso tra 0 e 100',
                least1Number: (v) => /\d+/.test(v) || 'questo campo deve contenere almeno un numero',
                max1000char: (v) => (v && v.length > 1000) || 'questo campo non può superare i 1000 caratteri',
                max165char: (v) => (v && v.length < 165) || 'questo campo non può superare i 165 caratteri',
                max20char: (v) => (v && v.length > 20) || 'questo campo non può superare i 20 caratteri',
                min100char: (v) => (v && v.length < 100) || 'questo campo deve contenere almeno 100 caratteri',
                min165char: (v) => (v && v.length > 165) || 'questo campo deve contenere almeno 165 caratteri',
                only5Numbers: (v) => /^[0-9]{5}$/.test(v) || 'questo campo deve contere solo 5 numeri',
                onlyNumbers: (v) => /^\d+$/.test(v) || 'questo campo deve contere solo da numeri',
                phone: (v) => /^\d{3,}$/.test(v) || 'il campo richiede solo numeri',
                required: (v) => !!v || 'Campo richiesto',
                url: (v) => (v && !!validator.isURL(v)) || 'Url non valido',
                requiredArr: (v) => (v && !!v.length) || 'Campo richiesto',
                threeDecimal: (v) => /^\d+(?:[.]\d{1,3}|$)$/.test(v) || 'campo con 3 decimali',
                twoDecimal: (v) => /^\d+(?:[.]\d{1,2}|$)$/.test(v) || 'campo con 2 decimali',
                vatnumber: (v) => /^[0-9]{11}$/.test(v) || 'inserisci una partita iva corretta'
            }
        }
    },
    computed: {
        classes () {
            return {
                field: true,
                'text-field': true,
                'text-field-has-label': !!this.label,
                'text-field-readonly': this.readonly,
                'text-field-raised': Boolean(this.focus || this.placeholder) || this.cacheValue !== null || ((this.currency || this.number) && this.numberValue !== null),
                'text-field-focused': Boolean(this.focus),
                'text-field-error': Boolean(!this.valid),
                'text-field-disabled': this.disabled,
                'text-field-hide-details': this.hideDetails,
                'text-field-hidden': this.type === 'hidden',
                disabled: this.disabled
            }
        },
        styles () {
            return {}
        },
        isPassword () {
            return this.type === 'password' || this.name === 'password' || this.name === 'oldPassword' || this.name === 'newPassword'
        }
    },
    setup (props, ctx) {
        // console.log('ctx', ctx)
        const instance = getCurrentInstance()
        let currency_input = null
        // console.log('instance', instance)

        if (props.currency || props.number) {
            let currency = {
                // locale: 'de-DE',
                currency: 'EUR',
                currencyDisplay: 'symbol',
                precision: 2,
                hideCurrencySymbolOnFocus: false,
                hideGroupingSeparatorOnFocus: false,
                hideNegligibleDecimalDigitsOnFocus: false,
                autoDecimalDigits: false,
                // valueScaling: 'precision',
                // valueRange: { min: 0, max: ! }
                useGrouping: true,
                accountingSign: false
            }

            if (props.number) {
                currency.precision = 0
                currency.valueRange = { min: 0, max: 9999 }
                currency.currencyDisplay = 'hidden'
                currency.useGrouping = false

                if (typeof props.number === 'object') {
                    currency = {
                        ...currency,
                        ...props.number
                    }
                }
            } else {
                if (typeof props.currency === 'object') {
                    currency = {
                        ...currency,
                        ...props.currency
                    }
                }
            }

            currency_input = useCurrencyInput(currency)
        }

        if (!props.solo) {
            const form = inject('bxs-form')
            // console.log(form)

            const uid = instance.uid

            onMounted(() => {
                if (form) {
                    form.bind({
                        validate: instance.ctx.validate,
                        getValue: instance.ctx.getValue,
                        reset: instance.ctx.reset,
                        resetValidation: instance.ctx.resetValidation,
                        name: props.name,
                        uid
                    })
                }
            })

            onBeforeUnmount(() => {
                if (form) {
                    form.unbind(uid)
                }
            })
        }

        return currency_input || {}
    },
    mounted () {
        this.$nextTick(this.play)
    },
    beforeUnmount () {
        this.stop()
    },
    watch: {
        modelValue (newVal) {
            if (this.debug) console.log('text-field watch modelValue', this.name, newVal)
            if (this.currency || this.number) this.setValue(newVal)
            this.cacheValue = newVal
        },
        cacheValue (newVal) {
            if (this.debug) console.log('text-field watch cacheValue', this.name, newVal)
            this.charsCount = this.cacheValue ? this.cacheValue.length : 0
        },
        showPass (newVal) {
            if (newVal) this.internalType = 'text'
            else this.internalType = 'password'
        },
        valid (newVal) {
            if (newVal === false) {
                this.$refs.label.classList.add('error-anim')

                setTimeout(() => {
                    this.$refs.label.classList.remove('error-anim')
                }, 1000)
            }
        }
    },
    methods: {
        play () {
            if (this.debug) console.log('text-field play', this.name)

            if (this.required) {
                this.selected_rules.push(this.local_rules.required)
            }

            if (this.rules.length > 0) {
                this.rules.forEach((k) => {
                    this.selected_rules.push(this.local_rules[k])
                })
            }

            if (this.autofocus) {
                this.validate()
            }

            // window.addEventListener('click', this.clickOutside)
        },
        stop () {
            // window.removeEventListener('click', this.clickOutside)
        },
        //
        // clickOutside (e) {
        //     if (!this.$el.contains(e.target)) {
        //         this.handleFocus(false)
        //     }
        // },
        handleClick () {
            if (this.$refs.input) {
                this.$refs.input.focus()
            }
        },
        handleModelInput (evt) {
            if (this.debug) console.log('text-field handleModelInput', this.name)

            // this.validate()
            this.updateValue()
        },
        updateValue (val) {
            if (this.debug) console.log('text-field updateValue', this.name, this.cacheValue)

            if (this.valid) {
                this.$emit('update:modelValue', this.cacheValue)
            }
        },
        handleFocus (focused) {
            if (this.debug) console.log('text-field handleFocus', this.name)

            this.focus = focused

            if (!this.focus) {
                if (this.cacheValue) this.validate()
            } else {
                this.resetValidation()
            }
        },
        //
        reset () {
            this.cacheValue = null
            this.resetValidation()
            this.$emit('update:modelValue', this.cacheValue)
        },
        resetValidation () {
            this.cacheErrors = []
            this.valid = true
        },
        //
        getValue () {
            return this.cacheValue
        },
        validate () {
            let v = true
            this.cacheErrors = []

            // if (!this.required) {
            //     v = true
            // } else {
            //     v = this.$el ? this.$refs.input.validity.valid : this.valid
            // }

            if (this.selected_rules.length > 0) {
                this.selected_rules.forEach((rule) => {
                    if (typeof rule === 'function') {
                        const resRule = rule(this.cacheValue)

                        if (typeof resRule === 'string') {
                            this.cacheErrors.push(resRule)
                        } else if (typeof resRule === 'boolean') {
                            v = resRule
                        } else {
                            console.error('error valid input =>', resRule)
                        }
                    }
                })

                v = this.cacheErrors.length === 0
            }

            this.valid = v

            return {
                value: v,
                errors: this.cacheErrors
            }
        },
        //
        increment () {
            if (!this.number) return
            this.setValue(this.cacheValue++)
        },
        decrement () {
            if (!this.number) return
            this.setValue(this.cacheValue--)
        }
    }
}
</script>

<style lang="scss" scoped>
/* ---------------------------------------------
    text-field
--------------------------------------------- */

$space: calc(0.2rem * var(--interaction-multi));

$input-height: var(--input-height);

$font-size-base: var(--input-font-size);
$font-size-small: calc($input-height / 4);
$font-size-smallest: calc($font-size-small / 1.05);
$font-weight: normal;

$label-transition: 0.2s ease all;
$label-top: calc(50% - ($font-size-base / 2));
$label-top-raised: $space;

$input-padding-top: calc($label-top-raised * 2.5);

$hint-margin-top: 0.25rem;
$error-margin-top: 0.2rem;

$color-background: var(--input-background-color);
$color-input: var(--input-color);
$color-label: var(--input-color-label);
$color-focused: var(--input-color-focused);

$color-disabled: var(--color-disabled);
$color-placeholder: var(--input-color-placeholder);
$color-hint: $color-disabled;
$color-error: var(--color-error);

// Mixins:
@mixin label-slided-top() {
    // position: relative;
    top: $label-top-raised;
    font-size: $font-size-small;
}

.text-field {
    position: relative;
    user-select: none;
    margin-bottom: var(--input-margin-bottom);
    border: none;
    text-align: left;
    font-family: var(--typo-family-ui);
    font-size: $font-size-base;

    * {
        box-sizing: border-box;
    }

    &.text-field-hidden {
        position: absolute;
        overflow: hidden;
        clip: rect(0 0 0 0);
        width: 1px;
        height: 1px;
        margin: -1px;
        padding: 0;
        border: 0;
        word-wrap: normal;
    }

    &.text-field-hide-details {
        margin-bottom: 0;
    }

    &.outlined {
        > .text-field--content {
            background: none;
            border-color: var(--color-secondary);

            > .text-field--content--prepend-content,
            > .text-field--content--append-content {
                border-color: var(--color-secondary);
            }
        }
    }

    &.text-field-readonly {
    }

    &.text-field-disabled {
        > .text-field--content {
            color: $color-disabled !important;

            > .text-field--content--input-content {
                > input,
                > label {
                    color: $color-disabled !important;
                }
            }
        }

        &.outlined {
            > .text-field--content {
                border-style: dashed;
            }
        }
    }

    &.text-field-focused {
        > .text-field--content {
            border-color: $color-focused;

            > .text-field--content--input-content {
                > label {
                    // color: $color-focused !important;
                }
            }
        }
    }

    &.text-field-raised {
        > .text-field--content {
            // border-color: $color-focused;

            > .text-field--content--input-content {
                > label {
                    @include label-slided-top();
                    color: $color-label;
                }
            }
        }
    }

    &.text-field-error {
        > .text-field--content {
            border-color: $color-error !important;

            > .text-field--content--input-content {
                > label {
                    color: $color-error !important;
                }
            }
        }
    }

    &.text-field-has-label {
        input {
            padding-top: $input-padding-top;
        }
    }

    &--content {
        position: relative;
        display: flex;
        flex-flow: row nowrap;

        height: $input-height;

        font-family: var(--typo-family-ui);
        font-weight: var(--action-typo-weight);
        line-height: 100%;

        border-width: var(--thickness);
        border-style: solid;
        border-color: transparent;
        border-radius: var(--radius);
        background-color: $color-background;

        white-space: nowrap;

        > div {
            // display: flex;
            // flex-flow: column nowrap;
            // align-items: center;
            // justify-content: center;

            display: flex;
            flex-flow: row nowrap;
            align-items: center;
            border: none;
            padding: 0 $input-padding-top;
        }

        &--prepend-content {
            border-right-width: var(--thickness);
            border-right-style: solid;
            border-right-color: transparent;
        }

        &--append-content {
            border-left-width: var(--thickness);
            border-left-style: solid;
            border-left-color: transparent;
        }

        &--input-content {
            flex: 1;
            position: relative;
            cursor: text;
            align-items: flex-start !important;
            justify-content: flex-start !important;

            > input {
                display: block;
                font-family: var(--typo-family-ui);
                font-family: 400;
                width: 100%;
                height: 100%;
                font-size: $font-size-base;
                background: none;
                border: none;
                outline: none;
                color: $color-input;
                user-select: text !important;

                &:focus {
                    outline: none;
                    border: none;
                }

                &::placeholder {
                    font-size: $font-size-base;
                    color: $color-placeholder;
                }
            }

            > label {
                display: block;
                position: absolute;
                top: $label-top;
                color: $color-label;
                font-size: $font-size-base;
                transition: $label-transition;
                margin-bottom: $space;
                white-space: nowrap;
                line-height: 100%;
                pointer-events: none;
            }
        }
    }

    &--hint {
        position: relative;
        font-family: var(--typo-text-family);
        font-weight: normal;
        font-size: $font-size-smallest;
        font-weight: $font-weight;
        line-height: 100%;
        color: $color-hint;
        pointer-events: none;
        margin-top: $hint-margin-top;
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
        padding: 0 $input-padding-top;

        > div {
            &:first-child {
                flex: 1;
                line-height: 100%;
            }

            &:last-child {
                margin-left: 1rem;
                text-align: right;
            }
        }
    }

    &--error {
        position: relative;
        overflow: hidden;
        margin-top: $error-margin-top;
        color: $color-error;
        font-size: $font-size-smallest;
        padding: 0 $input-padding-top;

        > ul,
        li {
            display: block;
            line-height: 100%;
            overflow: hidden;
            margin-top: 0;
            margin-bottom: 0.25rem;
            padding: 0;

            &:last-child {
                margin-bottom: 0;
            }
        }
    }
}

@keyframes shake {
  0% { transform: translate(1px, 1px) rotate(0deg); }
  10% { transform: translate(-1px, -2px) rotate(-1deg); }
  20% { transform: translate(-3px, 0px) rotate(1deg); }
  30% { transform: translate(3px, 2px) rotate(0deg); }
  40% { transform: translate(1px, -1px) rotate(1deg); }
  50% { transform: translate(-1px, 2px) rotate(-1deg); }
  60% { transform: translate(-3px, 1px) rotate(0deg); }
  70% { transform: translate(3px, 1px) rotate(-1deg); }
  80% { transform: translate(-1px, -1px) rotate(1deg); }
  90% { transform: translate(1px, 2px) rotate(0deg); }
  100% { transform: translate(1px, -2px) rotate(-1deg); }
}

.error-anim {
    animation-name: shake;
    animation-duration: 0.5s;
}
</style>
