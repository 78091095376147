<template>
    <div class="rinnovo-tessera-view">
        <section>
            <bxs-layout class="text-center mb-ui">
                <div class="mb-12">
                    <logo max-width="200px" />
                </div>

                <div >
                    <h1 class="h5 mb-1">Rinnova la tua tessera</h1>
                    <router-link to="/" class="text-disabled">Vai alla home</router-link>
                </div>
            </bxs-layout>

            <bxs-layout max-width="1024">
                <form-rinnovo-tessera />
            </bxs-layout>
        </section>
    </div>
</template>

<script>
export default {
    name: 'rinnovo_tessera_view'
}
</script>

<style lang="scss" scoped>
.rinnovo-tessera-view {
}
</style>