<template>
    <div class="collaborazioni-blocco-comune">
        <bxs-hero
        src="https://leila-bologna.it/wp-content/uploads/2023/02/Le-Gazze.jpg"
        light
        overlay>
            <div class="flex column align-center justify-center text-center pa-ui fill-height">
                <div>
                    <h1 class="text-500">Collaborazione - Le Gazze</h1>
                </div>
            </div>
        </bxs-hero>

        <!-- ------------------------------------------------------------------------------------------------------------------
        ------------------------------------------------------------------------------------------------------------------ -->
        <section>
            <bxs-layout>
                <bxs-row>
                    <bxs-col
                    cols="12"
                    ds="6">
                        <div class="flex nowrap">
                            <bxs-figure
                            src="https://leila-bologna.it/wp-content/uploads/2023/02/Le-Gazze.jpg"
                            max-width="120"
                            class="mr-ui"></bxs-figure>

                            <div>
                                <p class="mb-2 text-400">Le Gazze</p>
                            </div>
                        </div>
                    </bxs-col>

                    <bxs-col
                    cols="12"
                    ds="6">
                        <p class="mb-2 text-400 text-mute">Contatti</p>
                    </bxs-col>
                </bxs-row>
            </bxs-layout>
        </section>

        <!-- ------------------------------------------------------------------------------------------------------------------
        ------------------------------------------------------------------------------------------------------------------ -->
        <section>
            <bxs-carousel
            :items="[
                'https://leila-bologna.it/wp-content/uploads/2023/02/le-gazze-1.jpg',
                'https://leila-bologna.it/wp-content/uploads/2023/02/le-gazze-2-500x700.jpg',
                'https://leila-bologna.it/wp-content/uploads/2023/02/le-gazze-3-500x700.jpg'
            ]"
            items-per-view="4">
                <template #item="{ item }">
                    <bxs-figure
                    :src="item"
                    ratio="3-2" />
                </template>
            </bxs-carousel>
        </section>

        <!-- ------------------------------------------------------------------------------------------------------------------
        ------------------------------------------------------------------------------------------------------------------ -->
        <section>
            <bxs-layout>
                <div>
                    <h2>4 ragazze per Leila posson swappare!</h2>
                </div>

                <div>
                    <p>e Gazze sono un collettivo informale e si occupano di sostenibilità. Vivono in gruppo, si prendono cura del proprio territorio (il mondo intero) spesso trascurato dagli esseri umani. Sono adattabili e curiose, sanno bene che non si butta via niente, che ogni oggetto ha ancora tanto da dare.</p>
                    <p>Chiara, Cristina, Emanuela e Laura dal 2021 organizzano swap party, momenti di scambio e condivisione di oggetti che non utilizziamo più e a cui può essere data una seconda vita attraverso il baratto. Tra oggetti e vestiti s’incontrano le idee e i racconti custoditi in ogni cosa, di cui le Gazze amano prendersi cura. Dallo scambio delle storie nascono nuove storie, nuovi capitoli e intrecci inaspettati.</p>
                    <p>Collaborano con tante realtà bolognesi, tra cui Exaequo Bottega del Mondo, Libera Bologna, Blocco Comune, Porta Pratello, Maison Ventidue, Penelope Recupera e me_per_e.</p>
                </div>

                <div>
                    <p>Nel 2022 hanno partecipato a Rivestiti, festival della moda etica, con una performance e un’installazione su alcuni progetti di commercio equo. Hanno tante idee per la testa e le condividono davanti a essenziali birrette. Dalla passione per lo scambio e la condivisione nasce l’incontro con Leila, con cui condividono la filosofia del riuso e la ricerca di spazi di comunità al di fuori delle dinamiche commerciali e di consumo. Leila e Le Gazze hanno unito le forze poiché entrambe sostengono la filosofia del riuso, la ricerca di spazi di condivisione, e hanno come obiettivo comune quello di ridurre l’impronta e l’impatto ambientale: dalla condivisione degli oggetti, al riutilizzo di vestiti. Infatti, così come Leila vuole far rivivere gli oggetti portandoli a più persone, le Gazze vogliono far rivivere i vestiti passandoli di proprietà.</p>
                    <p>Gli spazi di Leila ospiteranno gli swap party delle Gazze per un progetto sperimentale che si svolge in quattro giornate: 10 febbraio; 24 febbraio; 10 marzo; 24 marzo;</p>
                    <p>(Gli swap party si svolgono il venerdì in orario di apertura nella nostra sede (16:00-19:00) di via Serra. Se hai un oggetto che vuoi mettere in condivisione con la nostra comunità con la possibilità di riprenderlo quando vuoi (attrezzatura per bricolage, falegnameria, utensili cucina), la biblioteca degli oggetti è pronta ad accoglierti. Se invece hai un capo d’abbigliamento o un oggetto di uso personale (set tazzine caffè, soprammobili, scarpe, accessori), puoi dargli nuova vita tramite le gazze che si occuperanno di fargli trovare un nuovo proprietario.</p>
                </div>
            </bxs-layout>
        </section>
    </div>
</template>

<script>
export default {
    name: 'collaborazioni_le_gazze'
}
</script>