<template>
    <section id="section-oggetti">
        <bxs-layout>
            <p class="label">Gli oggetti</p>
        </bxs-layout>

        <bxs-layout class="mb-ui text-center">
            <h3>Facile e veloce: la Webapp!</h3>
            <p>Il servizio di condivisione di ultima generazione pensato apposta per te</p>
        </bxs-layout>

        <bxs-layout class="my-ui">
            <bxs-data-lazy :call="() => $api.get('/v1/catalogs/products/highlighted')">
                <template #data="{ value }">
                    <bxs-row>
                        <bxs-col
                        v-for="(product, i) in value"
                        :key="i"
                        cols="2">
                            <bxs-figure
                            :src="product.imgs.length > 0 ? $filters.toStaticFileMaster(product.imgs[0]) : null"
                            ratio="4-3" />
                        </bxs-col>
                    </bxs-row>
                </template>
            </bxs-data-lazy>

            <div class="mt-ui text-center">
                <bxs-btn
                href="https://webapp.leila-bologna.it"
                target="_blank">Scopri gli oggetti</bxs-btn>
            </div>
        </bxs-layout>
    </section>
</template>

<script>
export default {
    name: 'section_oggetti'
}
</script>