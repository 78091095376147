<template>
    <div class="collaborazioni-opera-marella">
        <bxs-hero
        src="https://leila-bologna.it/wp-content/uploads/2023/04/logo-opera-marella.png"
        light
        overlay>
            <div class="flex column align-center justify-center text-center pa-ui fill-height">
                <div>
                    <h1 class="text-500">Collaborazione - Opera Marella</h1>
                </div>
            </div>
        </bxs-hero>

        <!-- ------------------------------------------------------------------------------------------------------------------
        ------------------------------------------------------------------------------------------------------------------ -->
        <section class="pb-0">
            <bxs-layout>
                <bxs-row>
                    <bxs-col
                    cols="12"
                    ds="6">
                        <div class="flex nowrap">
                            <bxs-figure
                            src="https://leila-bologna.it/wp-content/uploads/2023/04/logo-opera-marella.png"
                            max-width="160"
                            class="mr-ui"></bxs-figure>

                            <div>
                                <p class="mb-1 text-400">Fraternità Cristiana Opera di Padre Marella - Città dei Ragazzi</p>
                                <small>Via dei Ciliegi, 6 40068 San Lazzaro di Savena - Bologna</small> <br>
                                <small>info@barca59.org</small>
                            </div>
                        </div>
                    </bxs-col>

                    <bxs-col
                    cols="12"
                    ds="6">
                        <p class="mb-1 text-400">Contatti</p>
                        <!-- <small>Federico Palmas <span class="text-disabled ml-1">Community Manager</span></small> <br> -->
                        <a href="tel:0516255070"><small>051 6255070</small></a> <br>
                        <a href="mailto:amministrazione@operapadremarella.it"><small>redazione@operapadremarella.it</small></a>
                    </bxs-col>
                </bxs-row>
            </bxs-layout>
        </section>

        <!-- ------------------------------------------------------------------------------------------------------------------
        ------------------------------------------------------------------------------------------------------------------ -->
        <section>
            <bxs-layout>
                <p>La collaborazione tra Opera Marella e Leila è nata dalla volontà di trovare strade sempre nuove per la lotta allo spreco. Bologna è una città molto generosa e abbiamo la responsabilità di valorizzare ciò che ci viene donato: dal cibo al denaro, dagli oggetti agli indumenti.</p>
                <p>Tutto ciò che arriva dalla città, viene rigenerato e rimesso in circolo attraverso i nostri canali.</p>
                <p>Con Leila ne abbiamo aperto uno nuovo, fresco e culturalmente innovativo. Grazie a questa nuova collaborazione, parte delle cose che ci vengono donate, tornano alla città attraverso la biblioteca degli oggetti.</p>
                <p>Ciò che prima apparteneva ad una persona o ad una famiglia, diventa bene comune, acquista un nuovo senso, una nuova dignità, una nuova vita.</p>
                <p>Il progetto di Leila e l’Opera Marella hanno in comune un obiettivo forte, quello di tessere relazioni basate sulla fiducia, per un futuro di pace e condivisione.</p>
            </bxs-layout>
        </section>
    </div>
</template>

<script>
export default {
    name: 'collaborazioni_opera_marella'
}
</script>