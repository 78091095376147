<template>
    <section id="section-la-rete">
        <bxs-layout>
            <p class="label">La rete</p>

            <bxs-row>
                <bxs-col
                cols="12"
                ds="8">
                    <h3>La rete delle biblioteche degli oggetti</h3>
                    <p class="h5 line-height-ui">Leila Bologna fa parte della Rete Nazionale delle Biblioteche degli Oggetti.</p>
                    <p>Un progetto su scala nazionale che al momento è in fase di sperimentazione, ma che offre già diversi vantaggi a chi ne fa parte. Fra tutti, la possibilità di utilizzare un gestionale e una <bxs-link href="https://webapp.leila-bologna.it" label="webapp" hide-arrow /> (come quelli di Leila) che semplificano la vita a chi offre il servizio e rendono l’esperienza di prestito degli oggetti molto più semplice e veloce per gli utenti!</p>
                    <h5>Esplora di più e scropri i vantaggi!</h5>
                    <bxs-btn to="/voi-e-leila/crea-una-leila-anche-tu">Scopri di più</bxs-btn>
                </bxs-col>

                <bxs-col
                cols="12"
                ds="4">
                    <bxs-figure src="https://leila-bologna.it/wp-content/uploads/2023/10/cartinasenzasfondo.png" />
                </bxs-col>
            </bxs-row>
        </bxs-layout>
    </section>
</template>