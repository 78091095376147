<template>
    <section id="section-sedi">
        <bxs-layout>
            <div class="mb-ui">
                <p class="label">Punti Leila</p>
                <h3>Dove siamo</h3>
            </div>

            <ul class="mt-ui">
                <li
                v-for="headq in items"
                :key="headq.id">
                    <leila-list-item
                    :src="headq.img"
                    icon="chevron-right"
                    @click="setItem(headq)">
                        <p class="text-500 mb-1">{{ headq.name }}</p>
                    </leila-list-item>
                </li>
            </ul>
        </bxs-layout>

        <bxs-drawer
        v-model="on"
        :title="selected_item ? selected_item.name : null"
        max-width="768">
            <div v-if="selected_item">
                <bxs-figure
                v-if="selected_item.img"
                :src="selected_item.img"
                ratio="21-9"
                class="mb-ui" />

                <div class="mb-ui">
                    <p
                    v-if="selected_item.address"
                    class="mb-1">
                        <span>{{ selected_item.address }}</span>
                    </p>

                    <p class="mb-1">{{ selected_item.opening_days.map(v => t[v.toLowerCase()] || v).join(', ') }}</p>

                    <p class="mb-0 small">{{ selected_item.opening_times }}</p>

                    <p
                    v-if="selected_item.opening_times_2"
                    class="mb-0 small">{{  selected_item.opening_times_2 }}</p>
                </div>

                <iframe
                ref="iframe_map"
                :src="maps[selected_item.id]"
                allowfullscreen=""
                loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"
                style="min-height: 400px;"
                @load="handleLoadedIframeMap" />
            </div>

            <template #actions>
                <div class="text-right px-layout py-2">
                    <bxs-btn
                    v-if="selected_item && selected_item.map"
                    text
                    :href="selected_item.map"
                    target="_blank"
                    prepend-icon="geo-al-fill">Vedi su maps</bxs-btn>
                </div>
            </template>
        </bxs-drawer>
    </section>
</template>

<script>
export default {
    name: 'section_sedi',
    data () {
        return {
            t: {
                monday: 'Lunedì',
                tuesday: 'Martedì',
                wednesday: 'Mercoledì',
                thursday: 'Giovedì',
                friday: 'Venerdì',
                saturday: 'Sabato',
                domenica: 'Domenica'
            },
            maps: {
                '0000': 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2845.325931423188!2d11.340841896214357!3d44.5084769964924!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x477fd5250f189157%3A0x8c7bf3d382d7a49d!2sLeila%20Bologna%20-%20La%20Biblioteca%20degli%20Oggetti!5e0!3m2!1sit!2sit!4v1699377833547!5m2!1sit!2sit',
                1111: 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2846.0015634141905!2d11.339357377540855!3d44.4946347710747!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x477fd4bfc278adc1%3A0xc266fcbe4097cdfd!2sBiblioteca%20Salaborsa!5e0!3m2!1sit!2sit!4v1699377777984!5m2!1sit!2sit',
                2222: 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2845.1982918362796!2d11.392055377541292!3d44.51109167107438!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x477e2ca253811307%3A0xf68ade31f69da1d4!2sBiblioteca%20%22Luigi%20Spina%22!5e0!3m2!1sit!2sit!4v1699377677483!5m2!1sit!2sit',
                3333: 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2845.624281637788!2d11.325904876221978!3d44.502364871074526!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x477fd4861c70f21f%3A0xdd75a1e24a77314d!2sBiblioteca%20Jorge%20Luis%20Borges!5e0!3m2!1sit!2sit!4v1699377453393!5m2!1sit!2sit'
            },
            items: [
                {
                    id: '0000',
                    name: 'Sede - Leila la Biblioteca degli Oggetti',
                    address: 'Via Luigi Serra, 2 G/H, 40129 Bologna BO',
                    opening_days: ['Lunedì', 'Mercoledì', 'Giovedì', 'Venerdì'],
                    opening_times: '16:00 - 19:00',
                    opening_times_2: 'Martedì dalle ore 10:00 - 13:00',
                    img: 'https://webapp.leila-bologna.it/uploads/133006000000027019.jpeg',
                    map: 'https://www.google.com/maps/dir//Leila+Bologna+-+La+Biblioteca+degli+Oggetti,+Via+Luigi+Serra,+2+g%2Fh,+40129+Bologna+BO/@44.508477,11.345713,16z/data=!4m9!4m8!1m0!1m5!1m1!1s0x477fd5250f189157:0x8c7bf3d382d7a49d!2m2!1d11.3457128!2d44.5084771!3e0?hl=it&entry=ttu'
                },
                {
                    id: '1111',
                    name: 'Biblioteca Salaborsa',
                    address: 'Piazza Del Nettuno 3, 40129 Bologna BO',
                    opening_days: ['Martedì'],
                    opening_times: '16:00 - 19:00',
                    img: 'https://webapp.leila-bologna.it/uploads/133006000000027023.jpeg',
                    map: 'https://www.google.com/maps/dir//Biblioteca+Salaborsa+Piazza+del+Nettuno,+3+40124+Bologna+BO/@44.4946348,11.3419323,16z/data=!4m5!4m4!1m0!1m2!1m1!1s0x477fd4bfc278adc1:0xc266fcbe4097cdfd'
                },
                {
                    id: '2222',
                    name: 'Biblioteca Luigi Spina',
                    address: 'Via Tommaso Casini, 5, 40127 Bologna BO',
                    opening_days: ['Martedì'],
                    opening_times: '16:00 - 19:00',
                    img: '/img/biblioteca-spina.jpg',
                    map: 'https://www.google.com/maps/dir//Biblioteca+%22Luigi+Spina%22+Via+Tommaso+Casini,+5+40127+Bologna+BO/@44.5110917,11.3946303,16z/data=!4m5!4m4!1m0!1m2!1m1!1s0x477e2ca253811307:0xf68ade31f69da1d4'
                },
                {
                    id: '3333',
                    name: 'Biblioteca Jorge Luis Borges',
                    address: 'Via dello Scalo, 21/2, 40131 Bologna BO',
                    opening_days: ['Giovedì'],
                    opening_times: '16:00 - 19:00',
                    img: '/img/biblioteca-borges.jpg',
                    map: 'https://www.google.com/maps/dir//Biblioteca+Jorge+Luis+Borges+Via+dello+Scalo,+21%2F2+40131+Bologna+BO/@44.5023649,11.3284798,16z/data=!4m5!4m4!1m0!1m2!1m1!1s0x477fd4861c70f21f:0xdd75a1e24a77314d'
                }
            ],
            on: false,
            selected_item: null
        }
    },
    created () {
        // this.$api.get('/v1/headquarters').then((docs) => {
        //     // this.items = ...docs
        // })
    },
    methods: {
        setItem (item) {
            this.selected_item = item
            this.on = true
        },
        handleLoadedIframeMap () {
        }
    }
}
</script>