<template>
    <div class="footer bxs-footer fourth light">
        <bxs-layout class="py-12">
            <bxs-row>
                <bxs-col
                cols="12"
                ml="6"
                dm="4">
                    <!-- <h6 class="mb-6">Leila</h6> -->
                    <logo
                    src="/img/logo-typo-light.png"
                    max-width="60px"
                    class="mb-6" />

                    <div class="mb-6">
                        <ul>
                            <li>
                                <a
                                href="https://www.google.com/maps/dir/43.7689164,11.2614653/leila+bologna/@44.1251686,10.738903,9z/data=!3m1!4b1!4m9!4m8!1m1!4e1!1m5!1m1!1s0x477fd5250f189157:0x8c7bf3d382d7a49d!2m2!1d11.3457095!2d44.5084718"
                                target="_blank">
                                    <bxs-icon class="mr-3">
                                        <path d="M12.166 8.94c-.524 1.062-1.234 2.12-1.96 3.07A31.493 31.493 0 0 1 8 14.58a31.481 31.481 0 0 1-2.206-2.57c-.726-.95-1.436-2.008-1.96-3.07C3.304 7.867 3 6.862 3 6a5 5 0 0 1 10 0c0 .862-.305 1.867-.834 2.94zM8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10z"/>
                                        <path d="M8 8a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm0 1a3 3 0 1 0 0-6 3 3 0 0 0 0 6z"/>
                                    </bxs-icon>

                                    <span>Via Luigi Serra, 2 G/H, 40129 Bologna (BO)</span>
                                </a>
                            </li>

                            <li>
                                <a
                                href="mailto:info@leila-bologna.it"
                                target="_blank">
                                    <bxs-icon
                                    name="send"
                                    class="mr-3"></bxs-icon>

                                    <span>info@leila-bologna.it</span>
                                </a>
                            </li>

                            <li>
                                <a
                                href="tel:0510281845"
                                target="_blank">
                                    <bxs-icon class="mr-3">
                                        <path d="M11 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h6zM5 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H5z"/>
                                        <path d="M8 14a1 1 0 1 0 0-2 1 1 0 0 0 0 2z"/>
                                    </bxs-icon>

                                    <span>051 0281845</span>
                                </a>
                            </li>

                            <li>
                                <p>
                                    <bxs-icon
                                    name="calendar"
                                    class="mr-3"></bxs-icon>
                                    <span>Lunedì, Mercoledì, Giovedì, Venerdì</span>
                                </p>
                            </li>

                            <li>
                                <p>
                                    <bxs-icon class="mr-3">
                                        <path d="M8 3.5a.5.5 0 0 0-1 0V9a.5.5 0 0 0 .252.434l3.5 2a.5.5 0 0 0 .496-.868L8 8.71V3.5z"/>
                                        <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm7-8A7 7 0 1 1 1 8a7 7 0 0 1 14 0z"/>
                                    </bxs-icon>

                                    dalle 16:00 alle 19:00
                                </p>
                            </li>
                        </ul>
                    </div>
                </bxs-col>

                <bxs-col
                cols="12"
                ml="6"
                dm="5">
                    <h6 class="mb-6">Link utili</h6>

                    <div class="flex nowrap">
                        <ul>
                            <li>
                                <router-link to="/">Home</router-link>
                            </li>
                            <li>
                                <router-link to="/come-funziona">Come funziona</router-link>
                            </li>
                            <li>
                                <router-link to="/rinnovo-tessera">Rinnovo tessera</router-link>
                            </li>
                            <li>
                                <a
                                href="https://webapp.leila-bologna.it"
                                target="_blank">Oggetti</a>
                            </li>

                            <li>
                                <router-link to="/la-rete">La rete</router-link>
                            </li>
                        </ul>

                        <ul class="ml-16">
                            <li>
                                <router-link to="/collaborazioni">Collaborazioni</router-link>
                            </li>

                            <li>
                                <router-link to="/dicono-di-noi">Dicono di noi</router-link>
                            </li>

                            <li>
                                <router-link to="/oggetti-suggeriti">Oggetti suggeriti</router-link>
                            </li>

                            <li>
                                <router-link to="/storie-di-leila">Storie di leila</router-link>
                            </li>

                            <li>
                                <router-link to="/news">News</router-link>
                            </li>
                        </ul>

                        <ul class="ml-16">
                            <li>
                                <router-link to="/regolamento">Regolamento</router-link>
                            </li>
                            <li>
                                <router-link to="/statuto">Statuto</router-link>
                            </li>
                            <li>
                                <router-link to="/policies/privacy">Privacy policy</router-link>
                            </li>
                            <!-- <li>
                                <router-link to="/policies/cookie">Cookie policy</router-link>
                            </li> -->
                        </ul>
                    </div>
                </bxs-col>

                <bxs-col
                cols="12"
                ml="6"
                dm="3">
                    <h6 class="mb-6">Newsletter</h6>

                    <div>
                        <bxs-form
                        ref="form"
                        style="background: none; padding: 0;"
                        @submit="submit">
                            <bxs-text-field
                            v-model="newsletter.model.name"
                            required
                            label="Nome"
                            name="newsletter_name" />

                            <bxs-text-field
                            v-model="newsletter.model.email"
                            name="newsletter_email"
                            required
                            label="Email"
                            :rules="['email']" />

                            <bxs-checkbox
                            v-model="newsletter.model.privacy"
                            required
                            id="newsletter_privacy"
                            name="newsletter_privacy">
                                <small>* Confermo di aver letto e accettato la <router-link to="/policies/privacy">privacy policy</router-link></small>
                            </bxs-checkbox>
                        </bxs-form>

                        <div class="text-right">
                            <bxs-btn
                            :loading="newsletter.loading"
                            :disabled="!newsletter.model.privacy || newsletter.loading"
                            @click="$refs.form.submit()">Iscriviti</bxs-btn>
                        </div>

                        <!-- error message -->
                        <div
                        v-if="newsletter.error"
                        class="flex nowrap align-start mt-ui text-error mt-ui">
                            <div>
                                <small class="mb-0">Oops, qualcosa è andato storto.</small> <br>
                                <small>Al momento non è possible inviare questo modulo, contattaci per ulteriore supporto.</small>
                                <!-- {{ error.message }} -->
                            </div>
                        </div>

                        <!-- response message -->
                        <bxs-alert
                        v-if="newsletter.res"
                        type="success"
                        class="mt-ui">
                            <small>Iscrizione avvenuta con successo!</small>
                        </bxs-alert>
                    </div>
                </bxs-col>
            </bxs-row>
        </bxs-layout>

        <bxs-divider style="border-color: rgb(100, 99, 99) !important;"></bxs-divider>

        <bxs-layout class="text-center-until-ml py-3">
            <bxs-row no-gutters>
                <bxs-col
                cols="12"
                ml="6"
                class="mb-2">
                    <small>© {{ new Date().getFullYear() }} Leila Bologna - La Biblioteca degli oggetti APS</small>
                </bxs-col>

                <bxs-col
                cols="12"
                ml="6"
                class="text-right-from-ml">
                    <a
                    href="https://www.instagram.com/leilabologna/"
                    target="_blank"
                    class="mr-4">
                        <bxs-icon
                        name="ig"
                        width="1.25rem"></bxs-icon>
                    </a>

                    <a
                    href="https://www.facebook.com/leila.bologna.la.biblioteca.degli.oggetti"
                    target="_blank"
                    class="mr-4">
                        <bxs-icon
                        name="fb"
                        width="1.25rem"></bxs-icon>
                    </a>

                    <a
                    href="https://www.youtube.com/channel/UCGA-JRWPzLn1YNZGCMqjRhA"
                    target="_blank">
                        <bxs-icon
                        name="yt"
                        width="1.25rem"></bxs-icon>
                    </a>
                </bxs-col>
            </bxs-row>
        </bxs-layout>
    </div>
</template>

<script>
export default {
    name: 'bxs_footer',
    data () {
        return {
            newsletter: {
                key: 1,
                loading: false,
                error: null,
                res: null,
                model: {
                    email: null,
                    name: null,
                    privacy: false
                }
            }
        }
    },
    methods: {
        async submit () {
            if (!this.$refs.form.valid) return

            this.newsletter.loading = true
            this.newsletter.error = null

            try {
                this.newsletter.res = await this.$api.post('v1/forms/newsletter', this.newsletter.model)
                this.$notify.success('Iscrizione avvenuta con successo!')

                this.newsletter.model = {
                    email: null,
                    name: null,
                    privacy: false
                }

                this.newsletter.key += 1

                setTimeout(() => {
                    this.newsletter.res = null
                }, 4000)
            } catch (err) {
                this.newsletter.error = err
            } finally {
                this.newsletter.loading = false
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.bxs-footer {
    font-size: 14px;
}

a {
    display: inline-block;
    font-size: inherit;
    font-weight: inherit;
    color: var(--color-mute);
}

p {
    display: inline-block;
    margin-bottom: 0;
    color: var(--color-mute);
}

small {
    font-size: 0.7rem;
}

li {
    margin-bottom: 0.6rem;

    &:last-child {
        margin-bottom: 0;
    }
}
</style>