<template>
    <section id="section-come-funziona">
        <products-ux />

        <bxs-layout>
            <div>
                <p class="label">Come funziona</p>
            </div>

            <div class="mb-ui text-center">
                <div>
                    <!-- Condividi e utilizza tutti gli oggetti che vuoi -->
                    <h2>Gli oggetti che vuoi, <br class="show-ds"> come vuoi, quando vuoi, gratis!</h2>
                    <p>Contribuisci a rendere il mondo un luogo sempre più equo, etico ed ecologico. <br> Attiva il servizio di condivisione, ti aspettiamo nelle <bxs-link href="#section-sedi" hide-arrow label="nostre sedi" /> o visita la pagina <bxs-link to="/contatti" hide-arrow label="contatti" /> per ulteriori informazioni.</p>
                </div>

                <bxs-row class="mb-ui">
                    <bxs-col
                    cols="12"
                    mm="6"
                    ds="3">
                        <h2 class="text-primary">.1</h2>
                        <p class="text-500 text-uppercase mb-2 small">Fai la tessera</p>
                        <bxs-divider></bxs-divider>
                    </bxs-col>

                    <bxs-col
                    cols="12"
                    mm="6"
                    ds="3">
                        <h2 class="text-primary">.2</h2>
                        <p class="text-500 text-uppercase mb-2 small">Porta almeno un oggetto in condivisione</p>
                        <bxs-divider></bxs-divider>
                    </bxs-col>

                    <bxs-col
                    cols="12"
                    mm="6"
                    ds="3">
                        <h2 class="text-primary">.3</h2>
                        <p class="text-500 text-uppercase mb-2 small">Prendi in prestito quello che ti serve</p>
                        <bxs-divider></bxs-divider>
                    </bxs-col>

                    <bxs-col
                    cols="12"
                    mm="6"
                    ds="3">
                        <h2 class="text-primary">.4</h2>
                        <p class="text-500 text-uppercase mb-2 small">Rinnova il servizio di condivisione</p>
                        <bxs-divider></bxs-divider>
                    </bxs-col>
                </bxs-row>

                <div class="mt-ui">
                    <bxs-btn to="/come-funziona">Scopri come funziona</bxs-btn>
                </div>
            </div>
        </bxs-layout>
    </section>
</template>

<script>
export default {
    name: 'section_come_funziona'
}
</script>