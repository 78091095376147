<template>
    <div class="products-ux">
        <div>
            <img
            v-for="(v, i) in local_items"
            :key="i"
            class="products-ux-img"
            :src="v">
        </div>
    </div>
</template>

<script>
function getRandomInt (min, max) {
    return Math.floor(Math.random() * (max - min + 1)) + min
}

const randomOrder = (element, blur) => {
    const cont = document.querySelector('#section-come-funziona')

    // Viewport Dimensions
    // let vpHeight = window.innerHeight
    const vpHeight = cont ? cont.clientHeight : window.innerHeight
    const vpWidth = window.innerWidth

    // Image Position
    const xPos = getRandomInt(0, vpWidth - element.offsetWidth)
    const yPos = getRandomInt(0, vpHeight - element.offsetHeight)
    const zIndex = getRandomInt(0, 1)
    const width = getRandomInt(40, 400)
    const int_blur = getRandomInt(6, 10)
    const rotate = getRandomInt(0, 45)

    element.style.cssText += `--x-position: ${xPos}px; --y-position: ${yPos}px; z-index: ${zIndex}; --max-width: ${width}px; --blur: ${int_blur}px; --rotate: ${rotate}deg`
}

export default {
    name: 'products_ux',
    props: {
        items: {
            type: Array,
            required: false,
            default: () => ['/img/abstract/shape_1.png', '/img/abstract/shape_2.png', '/img/abstract/shape_3.png', '/img/abstract/shape_4.png', '/img/abstract/shape_5.png', '/img/abstract/shape_6.png']
        },
        blur: {
            type: [String, Number],
            required: false,
            default: null
        },
        copy: {
            type: [String, Number],
            required: false,
            default: 1
        }
    },
    data () {
        return {
            local_items: this.items
        }
    },
    mounted () {
        // this.items = await this.$api.get('/v1/catalogs/products/highlighted')

        for (let i = 1; i < this.copy; i++) {
            this.items.forEach((v) => {
                this.local_items.push(v)
            })
        }

        this.$nextTick(() => {
            const imgs = document.querySelectorAll('.products-ux-img')

            imgs.forEach((the_img) => {
                randomOrder(the_img, this.blur)
            })

            window.addEventListener('resize', () => {
                imgs.forEach((the_img) => {
                    randomOrder(the_img, this.blur)
                })
            })
        })
    }
}
</script>

<style lang="scss" scoped>
.products-ux {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    pointer-events: none;

    div {
        position: relative;
        width: 100%;
        height: 100%;

        img {
            position: absolute;
            max-width: var(--max-width);
            opacity: 0.8;
            transition: all 0.5s ease;

            transform: translate(
                var(--x-position),
                var(--y-position)
            ) rotate(var(--rotate));

            -webkit-filter: blur(var(--blur));
            -moz-filter: blur(var(--blur));
            -ms-filter: blur(var(--blur));
            -o-filter: blur(var(--blur));
            filter: blur(var(--blur));
        }
    }
}
</style>