<template>
    <section>
        <bxs-layout>
            <div>
                <p class="label">I numeri</p>
            </div>

            <bxs-row class="mb-ui">
                <bxs-col
                cols="6"
                ds="3">
                    <h1 class="text-primary">+700</h1>
                    <p class="text-600 text-uppercase mb-2 small">Membri</p>
                    <bxs-divider></bxs-divider>
                </bxs-col>

                <bxs-col
                cols="6"
                ds="3">
                    <h1 class="text-primary">+4</h1>
                    <p class="text-600 text-uppercase mb-2 small">Punti Leila</p>
                    <bxs-divider></bxs-divider>
                </bxs-col>

                <bxs-col
                cols="6"
                ds="3">
                    <h1 class="text-primary">+600</h1>
                    <p class="text-600 text-uppercase mb-2 small">Oggetti</p>
                    <bxs-divider></bxs-divider>
                </bxs-col>

                <bxs-col
                cols="6"
                ds="3">
                    <h1 class="text-primary">+10</h1>
                    <p class="text-600 text-uppercase mb-2 small">Prestiti giornalieri</p>
                    <bxs-divider></bxs-divider>
                </bxs-col>
            </bxs-row>
        </bxs-layout>
    </section>
</template>

<script>
export default {
    name: 'section_i_numeri',
    data () {
        return {
            items: [
                {
                    title: '28.06.2023',
                    subtitle: 'INTERNAZIONALE KIDS',
                    href: 'HTTPS://WWW.INTERNAZIONALE.IT/OPINIONE/MARTINA-RECCHIUTI/2023/06/28/RETI'
                },
                {
                    title: '04.03.2023',
                    subtitle: 'RAI 1 - LINEA VERDE LIFE - BOLOGNA',
                    href: 'HTTPS://WWW.RAIPLAY.IT/VIDEO/2023/03/LINEA-VERDE-LIFE---BOLOGNA---04032023-ABCF0E8D-2D35-4E3B-A633-247B18676B37.HTML?FBCLID=IWAR0AA3RBA5N3MON1CXBCDDNVGZOIECFXLLAWPSFPLG7DZ4HSUXZZRPRUKP0'
                },
                {
                    title: '18.03.2022',
                    subtitle: 'SKY TG24: A BOLOGNA IN BIBLIOTECA SI PRESTANO ANCHE GLI OGGETTI',
                    href: 'HTTPS://TG24.SKY.IT/BOLOGNA/2022/03/18/A-BOLOGNA-IN-BIBLIOTECA-SI-PRESTANO-ANCHE-GLI-OGGETTI'
                },
                {
                    title: '16.04.2022',
                    subtitle: 'LA REPUBBLICA: NON PIÙ SOLO LIBRI: ORA IN BIBLIOTECA SI PRENDONO IN PRESTITO ANCHE OGGETTI ED ESPERIENZE',
                    href: 'HTTPS://WWW.REPUBBLICA.IT/GREEN-AND-BLUE/2022/04/16/NEWS/BIBLIOTECA_PRESTITO_OGGETTI_LEILA_BOLOGNA-345509759/'
                },
                {
                    title: '18.03.2022',
                    subtitle: 'ANSA: A BOLOGNA IN BIBLIOTECA SI PRESTANO ANCHE GLI OGGETTI',
                    href: 'HTTPS://WWW.ANSA.IT/EMILIAROMAGNA/NOTIZIE/2022/03/18/A-BOLOGNA-IN-BIBLIOTECA-SI-PRESTANO-ANCHE-GLI-OGGETTI_67F14903-2D51-4394-9609-1F1E8C8B3D46.HTML'
                },
                {
                    title: '05.07.2020',
                    subtitle: 'SKY VIDEO - BOLOGNA, SUCCESSO PER LEILA LA BIBLIOTECA DEGLI OGGETTI',
                    href: 'HTTPS://VIDEO.SKY.IT/NEWS/CRONACA/VIDEO/BOLOGNA-SUCCESSO-PER-LEILA-LA-BIBLIOTECA-DEGLI-OGGETTI-601354'
                }
            ]
        }
    }
}
</script>