<template>
    <div class="oggetti-mancanti-view">
        <bxs-hero title="Oggetti mancanti"></bxs-hero>

        <section>
            <bxs-layout>
                <div class="mb-ui">
                    <h6 class="text-primary">SUGGERIMENTI PER LA CONDIVISIONE</h6>
                    <h1 class="h2">OGGETTI CHE ANCORA NON ABBIAMO IN BIBLIOTECA</h1>
                </div>

                <bxs-row>
                    <bxs-col cols="12" ds="6" dm="4">
                        <h6 class="text-primary">TECNOLOGIA</h6>

                        <ul>
                            <li>Computer</li>
                            <li>Tablet</li>
                            <li>Smartphone</li>
                            <li>Caricabatterie solare</li>
                            <li>Macchina fotografica reflex</li>
                            <li>Videocamera</li>
                            <li>Videoproiettore</li>
                            <li>Scannerizzatore libri portatile</li>
                            <li>Stampante photo bluetooth</li>
                            <li>Fototrappole</li>
                            <li>Lettore videocassette/cassette</li>
                            <li>Drone</li>
                            <li>Polaroid</li>
                            <li>Videocamera</li>
                        </ul>
                    </bxs-col>

                    <bxs-col cols="12" ds="6" dm="4">
                        <h6 class="text-primary">ATTREZZI</h6>

                        <ul>
                            <li>Cassetta degli attrezzi completa</li>
                            <li>Set chiavi inglesi</li>
                            <li>Set riparazione bici</li>
                            <li>Maschera saldatore automatica</li>
                            <li>Set cacciaviti</li>
                            <li>Paranco</li>
                            <li>Saldatrice a elettrodo/ a filo</li>
                            <li>Spelacavi elettrici</li>
                            <li>Motosega</li>
                            <li>Set riparazione auto</li>
                            <li>Occhiellatrice</li>
                            <li>Smaglia catena per bici</li>
                            <li>Flessibile</li>
                            <li>Piccone</li>
                            <li>Set pinze</li>
                            <li>Rastrello</li>
                            <li>Metro Laser</li>
                            <li>Scalpelli per legno</li>
                            <li>Trapano avvitatore (modelli nuovi)</li>
                            <li>Pistola per silicone</li>
                            <li>Cric auto</li>
                        </ul>
                    </bxs-col>

                    <bxs-col cols="12" ds="6" dm="4">
                        <h6 class="text-primary">GIOCHI & SPORT & HOBBY</h6>

                        <ul>
                            <li>Mazza baseball + pallina + guantone</li>
                            <li>Monociclo</li>
                            <li>Attrezzi giocoleria</li>
                            <li>Giochi da tavolo</li>
                            <li>Videogiochi</li>
                            <li>Walky Talky</li>
                            <li>Biliardino e biliardo portatili</li>
                            <li>Spikeball</li>
                            <li>Racchette badminton</li>
                            <li>Arco con frecce</li>
                            <li>Freccette</li>
                            <li>Cappello a cilindro (tipo mago)</li>
                            <li>Palla pallavolo</li>
                            <li>Rete pallavolo</li>
                            <li>Racchette da padel</li>
                            <li>Mazza baseball + pallina + guantone</li>
                            <li>Racchette badminton</li>
                            <li>Arco con frecce</li>
                            <li>Monopattino adulto</li>
                            <li>Ramponcini</li>
                            <li>Scarpette arrampicata</li>
                            <li>Sci da bambino</li>
                            <li>Racchette da padel</li>
                            <li>Ciaspole</li>
                            <li>Pesi fitness > 3 kg</li>
                            <li>Set pesi fitness</li>
                            <li>Bici pieghevole</li>
                            <li>Vortex</li>
                            <li>Snowboard</li>
                            <li>Vela per kitesurf</li>
                            <li>Crash pad</li>
                            <li>Tappetino yoga</li>
                            <li>Monopattino elettrico</li>
                            <li>Canne da pesca</li>
                            <li>Telescopio</li>
                            <li>Pinne</li>
                            <li>Cavalletto per dipingere</li>
                            <li>Macchina da cucire (modelli nuovi)</li>
                            <li>Videogiochi</li>
                            <li>Tappetino yoga</li>
                            <li>Spine per birra</li>
                            <li>Retini da pesca</li>
                            <li>Set di esche artificiali</li>
                            <li>Faretti da festa</li>
                            <li>Palla da discoteca</li>
                            <li>Microscopio</li>
                            <li>Biliardino e biliardo portatili</li>
                            <li>Minibar</li>
                            <li>Arco con frecce</li>
                            <li>Binocolo</li>
                            <li>Narghilè</li>
                            <li>Set da cocktail</li>
                            <li>Kindle</li>
                            <li>Kamishibai</li>
                        </ul>
                    </bxs-col>

                    <bxs-col cols="12" ds="6" dm="4">
                        <h6 class="text-primary">CUCINA</h6>

                        <ul>
                            <li>Planetaria</li>
                            <li>Imbottigliatrice vino</li>
                            <li>Bilancino</li>
                            <li>Minibar</li>
                            <li>Impastatrice</li>
                            <li>Rice Cooker</li>
                            <li>Tritacarne</li>
                            <li>Tortiera</li>
                            <li>Essiccatore</li>
                        </ul>
                    </bxs-col>

                    <bxs-col cols="12" ds="6" dm="4">
                        <h6 class="text-primary">CAMPEGGIO</h6>

                        <ul>
                            <li>Tende</li>
                            <li>Sacco a pelo</li>
                            <li>Doccia da campeggio</li>
                            <li>Corde elastiche (lunghezza minima 10m)</li>
                            <li>Tavolino da campeggio con sedie</li>
                        </ul>
                    </bxs-col>

                    <bxs-col cols="12" ds="6" dm="4">
                        <h6 class="text-primary">MUSICA</h6>

                        <ul>
                            <li>Giradischi</li>
                            <li>Consolle da dj + mixer</li>
                            <li>Scheda audio</li>
                            <li>Strumenti musicali</li>
                        </ul>
                    </bxs-col>

                    <bxs-col cols="12" ds="6" dm="4">
                        <h6 class="text-primary">SALUTE & CURA DELLA PERSONA</h6>

                        <ul>
                            <li>Tutori</li>
                            <li>Luce pulsata</li>
                        </ul>
                    </bxs-col>

                    <bxs-col cols="12" ds="6" dm="4">
                        <h6 class="text-primary">CASA</h6>

                        <ul>
                            <li>Imbottigliatrice vino</li>
                            <li>Dispositivo scaccia topi</li>
                            <li>Museruola per cani</li>
                            <li>Albero di natale</li>
                            <li>Vaporetto</li>
                            <li>Zanzariera da letto</li>
                            <li>Set per cocktail</li>
                            <li>Albero di natale</li>
                            <li>Essiccatore</li>
                        </ul>
                    </bxs-col>
                </bxs-row>
            </bxs-layout>
        </section>
    </div>
</template>

<script>
export default {
    name: 'oggetti_mancanti'
}
</script>