<template>
    <div class="bxs-textarea-field">
        <label
        ref="label"
        :for="name || id"
        class="mb-1 small">{{ required ? '* ' + label : label }}</label>

        <textarea
        v-model="local_value"
        ref="input"
        :name="name || id"
        :id="id"
        :placeholder="placeholder"
        cols="30"
        rows="10"></textarea>
    </div>
</template>

<script>
import {
    onMounted,
    onBeforeUnmount,
    inject,
    getCurrentInstance
} from 'vue'

export default {
    name: 'bxs-textarea-field',
    props: {
        modelValue: {
            type: [String, Number],
            required: false,
            default: null
        },
        rules: {
            type: Array,
            required: false,
            default: () => []
        },
        required: {
            type: Boolean,
            required: false,
            default: false
        },
        placeholder: {
            type: String,
            required: false,
            default: null
        },
        label: {
            type: String,
            required: false,
            default: null
        },
        name: {
            type: String,
            required: true
        },
        id: {
            type: String,
            required: false,
            default: null
        },
        disabled: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    emits: [
        'update:modelValue',
        'change'
    ],
    setup (props, ctx) {
        // console.log('ctx', ctx)
        const instance = getCurrentInstance()
        // console.log('instance', instance)

        if (!props.hideForm) {
            const form = inject('bxs-form')
            // console.log(props.name, ' bxs-form', form)
            const uid = instance.uid

            onMounted(() => {
                if (form) {
                    form.bind({
                        validate: instance.ctx.validate,
                        getValue: instance.ctx.getValue,
                        reset: instance.ctx.reset,
                        resetValidation: instance.ctx.resetValidation,
                        name: props.name,
                        uid
                    })
                }
            })

            onBeforeUnmount(() => {
                if (form) {
                    form.unbind(uid)
                }
            })
        }
    },
    data () {
        return {
            local_value: this.modelValue,
            valid: true,
            cacheErrors: [],
            selected_rules: []
        }
    },
    watch: {
        local_value (newVal) {
            this.$emit('update:modelValue', newVal)
        }
    },
    methods: {
        reset () {
            this.local_value = null
            this.resetValidation()
            this.$emit('update:modelValue', this.local_value)
        },
        resetValidation () {
            this.cacheErrors = []
            this.valid = true
        },
        getValue () {
            return this.local_value
        },
        validate () {
            let v = true
            this.cacheErrors = []

            if (this.selected_rules.length > 0) {
                this.selected_rules.forEach((rule) => {
                    if (typeof rule === 'function') {
                        const resRule = rule(this.local_value)

                        if (typeof resRule === 'string') {
                            this.cacheErrors.push(resRule)
                        } else if (typeof resRule === 'boolean') {
                            v = resRule
                        } else {
                            console.error('error valid input =>', resRule)
                        }
                    }
                })

                v = this.cacheErrors.length === 0
            }

            this.valid = v

            return {
                value: v,
                errors: this.cacheErrors
            }
        }
    }
}
</script>

<style lang="scss" scoped>
$space: calc(0.2rem * var(--interaction-multi));

$input-height: var(--input-height);

$font-size-base: var(--input-font-size);
$font-size-small: calc($input-height / 4);
$font-size-smallest: calc($font-size-small / 1.05);
$font-weight: normal;

$label-transition: 0.2s ease all;
$label-top: calc(50% - ($font-size-base / 2));
$label-top-raised: $space;

$input-padding-top: calc($label-top-raised * 2.5);

$hint-margin-top: 0.25rem;
$error-margin-top: 0.2rem;

$color-background: var(--input-background-color);
$color-input: var(--input-color);
$color-label: var(--input-color-label);
$color-focused: var(--input-color-focused);

$color-disabled: var(--color-disabled);
$color-placeholder: var(--input-color-placeholder);
$color-hint: $color-disabled;
$color-error: var(--color-error);

.bxs-textarea-field {
    position: relative;
    user-select: none;
    margin-bottom: var(--input-margin-bottom);
    border: none;
    text-align: left;
    font-family: var(--typo-family-ui);

    label {
        display: block;
        color: $color-label;
    }

    > textarea {
        width: 100%;
        font-size: $font-size-base;
        font-family: var(--typo-family-ui);
        font-family: 400;
        user-select: text !important;
        line-height: normal;
        color: $color-input;

        border-width: var(--thickness);
        border-style: solid;
        border-color: transparent;
        border-radius: var(--radius);
        background-color: var(--input-background-color);

        padding: 1rem;

        resize: none;

        &::placeholder {
            font-size: $font-size-base;
            font-family: 400;
            color: $color-placeholder;
            font-family: var(--typo-family-ui);
        }

        &:focus {
            outline: none !important;
            border: 1px solid $color-focused;
        }
    }
}
</style>