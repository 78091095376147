<template>
  <div class="form-test-page">
    <form-rinnovo-tessera />
    <hr style="margin: 60px 0"/>
    <form-cargo-bike />
    <hr style="margin: 60px 0"/>
    <form-crea-leila />
    <hr style="margin: 60px 0"/>
    <form-proponi-progetto />
    <hr style="margin: 60px 0"/>
    <form-volontariato />
</div>
</template>

<script>
import FormVolontariato from '@/components/project/FormVolontariato.vue'
import FormProponiProgetto from '@/components/project/FormProponiProgetto.vue'
import FormCreaLeila from '@/components/project/FormCreaLeila.vue'
export default {
  components: { FormVolontariato, FormProponiProgetto, FormCreaLeila },
    name: 'form_test_page'
}
</script>
