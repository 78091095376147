<template>
    <div class="voi-e-leila-volontariato-view">
        <bxs-hero title="Volontariato" />
        <section>
            <bxs-layout>
                <div class="mb-ui">
                    <p class="label">Volontariato</p>
                    <h3>Leila cresce sempre di più</h3>
                </div>

                <bxs-row>
                    <bxs-col cols="12" ds="5">
                        <bxs-figure src="https://leila-bologna.it/wp-content/uploads/2022/12/leila-bologna-volontariato-2048x1366.jpg"></bxs-figure>
                    </bxs-col>
                    <bxs-col cols="12" ds="7">
                        <p>
                          Leila cresce sempre di più e proprio per questo ha bisogno di sempre più aiuto!
                          Vuoi dedicare il tuo tempo, energie e competenze per rendere il mondo un luogo più sostenibile, etico ed ecologico? Leila è il posto giusto! La comunità della condivisione si basa sulle relazioni tra persone, oggetti, spazi e saperi.
                        </p>
                        <p>
                          Dietro tutto questo c’è il team di Leila che fa mette tutto se stesso per far vivere questo fantastico progetto!
                        </p>
                        <p>
                          Noi ci crediamo fermamente, d’altronde tutto ciò si basa sulla fiducia! <strong>E tu?</strong>
                        </p>
                    </bxs-col>
                  </bxs-row>

                <bxs-row>
                    <bxs-col cols="12" ds="12">
                        <div class="text-center yellow-banner">CONDIVIDI LA TUA VOGLIA DI MIGLIORARE IL MONDO! ENTRA A FAR PARTE DEL TEAM DI LEILA!</div>
                    </bxs-col>
                </bxs-row>

                <form-volontariato />
            </bxs-layout>
        </section>
    </div>
</template>

<script>
export default {
    name: 'voi_e_leila_volontariato'
}
</script>
