<template>
    <section
    id="section-social"
    class="secondary"
    style="padding: 12.5vh 0;">
        <bxs-layout class="text-center">
            <div class="mb-8">
                <h4>Seguici sui canali social</h4>
            </div>

            <div>
                <a
                href="https://www.facebook.com/leila.bologna.la.biblioteca.degli.oggetti"
                target="_blank"
                class="mr-8">
                    <bxs-icon
                    name="ig"
                    width="48"
                    height="48"></bxs-icon>
                </a>

                <a
                href="https://www.instagram.com/leilabologna/"
                target="_blank"
                class="mr-8">
                    <bxs-icon
                    name="fb"
                    width="48"
                    height="48"></bxs-icon>
                </a>

                <a
                href="https://www.youtube.com/channel/UCGA-JRWPzLn1YNZGCMqjRhA"
                target="_blank">
                    <bxs-icon
                    name="yt"
                    width="48"
                    height="48"></bxs-icon>
                </a>
            </div>
        </bxs-layout>
    </section>
</template>

<script>
export default {
    name: 'section_social'
}
</script>