<template>
    <div class="fare-condivisione-coworking">
        <bxs-hero
        title="Fare condivisione - Coworking"
        src="https://leila-bologna.it/wp-content/uploads/2022/12/leila-bologna-coworking.jpg" />

        <section>
            <bxs-layout>
                <p>Per accedere alla condivisione del coworking è necessario il tesseramento all’associazione e la sottoscrizione al suo regolamento.</p>
                <p>Da Leila c’è posto per chiunque lo desideri. Sei uno studente e cerchi un posto tranquillo dove scrivere la tesi? Sei una nomad worker? Lavori in smartworking?</p>
                <p>Da Leila trovi postazioni fisse mensili.</p>

                <bxs-figure src="https://leila-bologna.it/wp-content/uploads/2022/11/Esterno-Coworking-scaled.jpg"></bxs-figure>

                <p>Il contributo richiesto per le spese di gestione, le utenze e ai fini del progetto è di 150€ mensili a postazione – con questa opzione avrai le chiavi per gestire i tuoi orari e l’uso del laboratorio per le riunioni.</p>
                <p>Se trovi una persona con cui condividere la postazione, per noi non è assolutamente un problema.</p>
                <p>La sede di via Serra è dotata di wifi, stampante e tranquillità. Fuori dalla ztl, ma a due passi dal centro. La zona è servitissima da mezzi pubblici e comoda per chi arriva in treno (comunque in bici si va ovunque e puoi prenderla in condivisione da Leila).</p>
                <p>Il soppalco è dotato di angolo ristoro accessibile.</p>
            </bxs-layout>
        </section>
    </div>
</template>

<script>
export default {
    name: 'fare_condivisione_coworking'
}
</script>