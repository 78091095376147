<template>
    <div class="collaborazioni-blocco-comune">
        <bxs-hero
        src="https://leila-bologna.it/wp-content/uploads/2022/11/LOGO_Ar.coWoodWorkshop_jpeg-1024x724.jpg"
        light
        overlay>
            <div class="flex column align-center justify-center text-center pa-ui fill-height">
                <div>
                    <h1 class="text-500">Collaborazione - Blocco comune</h1>
                </div>
            </div>
        </bxs-hero>

        <!-- ------------------------------------------------------------------------------------------------------------------
        ------------------------------------------------------------------------------------------------------------------ -->
        <section>
            <bxs-layout>
                <bxs-row>
                    <bxs-col
                    cols="12"
                    ds="6">
                        <div class="flex nowrap">
                            <bxs-figure
                            src="https://leila-bologna.it/wp-content/uploads/2022/11/LOGO_Ar.coWoodWorkshop_jpeg-1024x724.jpg"
                            max-width="120"
                            class="mr-ui"></bxs-figure>

                            <div>
                                <p class="mb-2 text-400">Ar.co Wood Workshop</p>
                                <small>via Nicoli, 4 - 40026 Imola (BO)</small> <br>
                                <small>info@arcowood.it</small> <br>
                                <a href="https://www.arcowood.it" target="_blank"><small>Sito web</small></a> <br>
                                <a href="https://www.instagram.com/ar.cowoodworkshop/" target="_blank"><small>Instagram</small></a>
                            </div>
                        </div>
                    </bxs-col>

                    <bxs-col
                    cols="12"
                    ds="6">
                        <p class="mb-2 text-400">Referente</p>
                        <small>Arturo</small> <br>
                    </bxs-col>
                </bxs-row>
            </bxs-layout>
        </section>

        <!-- ------------------------------------------------------------------------------------------------------------------
        ------------------------------------------------------------------------------------------------------------------ -->
        <section>
            <bxs-carousel
            :items="[
                'https://leila-bologna.it/wp-content/uploads/elementor/thumbs/blocco-comune-1-q9o3b47odukywm0gktzxgldnje2fcqd6897i28humw.jpg',
                'https://leila-bologna.it/wp-content/uploads/elementor/thumbs/blocco-comune-2-q9o3bdm2a6xu4pmt1y275j09h8s3hpehljqcv03wwo.jpg'
            ]"
            items-per-view="4">
                <template #item="{ item }">
                    <bxs-figure
                    :src="item"
                    ratio="3-2" />
                </template>
            </bxs-carousel>
        </section>

        <!-- ------------------------------------------------------------------------------------------------------------------
        ------------------------------------------------------------------------------------------------------------------ -->
        <section>
            <bxs-layout>
                <p>Ar.co Wood è un’attività specializzata nella progettazione e realizzazione di strutture e allestimenti in legno. L’idea nasce nel 2018, in un contesto cittadino di provincia creativo e collaborativo, da quattro giovani ingegneri e studenti dell’Università di Bologna, con la passione comune per l’architettura, il dialogo e la sperimentazione.</p>
                <p>Oggi Ar.co si occupa principalmente di allestimenti sperimentali nello spazio pubblico, e di coinvolgere la cittadinanza in questo tipo di interventi attraverso percorsi partecipativi, laboratori ed eventi.</p>
                <p>Ar.co e Leila oggi condividono uno spazio all’interno di <a href="https://dumbospace.it/" target="_blank">DumBO</a>, nell’ambito del progetto <a href="https://dumbospace.it/bologna-attiva/" target="_blank">Bologna Attiva</a> supportato dalla <a href="https://fondazioneinnovazioneurbana.it/progetto/bolognaattiva">Fondazione Innovazione Urbana</a>. Sotto lo stesso tetto queste ed altre realtà lavorano per promuovere l’artigianato, la collaborazione e la socialità a livello locale. Lo spazio si chiama Blocco Comune e vi invitiamo a seguirlo per non perdervi nessuna delle succose attività che nasceranno al suo interno.</p>
            </bxs-layout>
        </section>
    </div>
</template>

<script>
export default {
    name: 'collaborazioni_blocco_comune'
}
</script>