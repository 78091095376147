<template>
    <bxs-form
    ref="form"
    @submit="submit">
        <div class="mb-ui">
            <p class="mb-2">Categoria <em class="el-required">*</em></p>

            <div>
                <bxs-radio-field
                v-model="model.categoria"
                name="categoria"
                required
                value="Persona fisica"
                class="mb-1">Persona fisica</bxs-radio-field>

                <bxs-radio-field
                v-model="model.categoria"
                name="categoria"
                required
                value="Ente">Ente (Azienda, Associazione, Pubblica Amministrazione)</bxs-radio-field>
            </div>
        </div>

        <bxs-row>
            <bxs-col
            cols="12"
            ds="6">
                <bxs-text-field
                v-model="model.nome"
                name="nome"
                label="Nome"
                required />
            </bxs-col>

            <bxs-col
            cols="12"
            ds="6">
                <bxs-text-field
                v-model="model.cognome"
                name="cognome"
                required
                label="Cognome" />
            </bxs-col>
        </bxs-row>

        <bxs-row>
            <bxs-col
            cols="12"
            ds="6">
                <bxs-text-field
                v-model="model.email"
                type="email"
                label="Email"
                name="email"
                :rules="['email']"
                required />
            </bxs-col>

            <bxs-col
            cols="12"
            ds="6">
                <bxs-text-field
                v-model="model.data_di_nascita"
                label="Data di nascita"
                name="data_di_nascita"
                required />
            </bxs-col>
        </bxs-row>

        <!--  -->
        <div>
            <bxs-text-field
            v-model="model.indirizzo"
            label="Indirizzo (linea 1)"
            name="indirizzo"
            required />

            <bxs-row>
                <bxs-col
                cols="12"
                ds="6">
                    <bxs-text-field
                    v-model="model.città"
                    label="Città"
                    name="Città"
                    required />
                </bxs-col>

                <bxs-col
                cols="12"
                ds="6">
                    <bxs-text-field
                    v-model="model.provincia"
                    label="Provincia" />
                </bxs-col>
            </bxs-row>

            <bxs-row>
                <bxs-col
                cols="12"
                ds="6">
                    <bxs-text-field
                    v-model="model.codice_postale"
                    name="codice_postale"
                    label="Codice postale" />
                </bxs-col>

                <bxs-col
                cols="12"
                ds="6">
                    <bxs-text-field
                    v-model="model.nazione"
                    name="nazione"
                    label="Nazione" />
                </bxs-col>
            </bxs-row>
        </div>

        <!--  -->
        <bxs-row>
            <bxs-col
            cols="12"
            ds="12">
                <div class="mb-2"><em class="el-required">*</em> Raccontaci chi sei, perché vorresti organizzare una Leila e qual è la tua rete di contatti sul territorio</div>

                <bxs-textarea-field
                v-model="model.raccontaci_chi_sei"
                required
                label="Max 1000 caratteri..."
                placeholder="Raccontaci chi sei, perché vorresti organizzare una Leila e qual è la tua rete di contatti sul territorio." />
            </bxs-col>
        </bxs-row>

        <!-- --------------------------------------------------------------------------------------------------------
        -------------------------------------------------------------------------------------------------------- -->
        <bxs-text-field
        v-model="model.antispam"
        label="Quiz AntiSpam, quanto fa 7+4?"
        name="antispam"
        required />

        <!--  -->
        <div class="mb-ui">
            <bxs-btn
            block
            :loading="loading"
            :disabled="!is_submitable"
            @click="$refs.form.submit()">Invia</bxs-btn>
        </div>

        <!-- foot -->
        <div>
            <bxs-checkbox
            v-model="model.privacy"
            id="privacy"
            name="privacy"><small>* Confermo di aver letto e accettato la <router-link to="/policies/privacy">privacy policy</router-link></small></bxs-checkbox>
        </div>

        <!-- error message -->
        <div
        v-if="error"
        class="flex nowrap align-start mt-ui text-error mt-ui">
            <div>
                <small class="mb-0">Oops, qualcosa è andato storto.</small> <br>
                <small>Al momento non è possible inviare questo modulo, contattaci per ulteriore supporto.</small>
                <!-- {{ error.message }} -->
            </div>
        </div>

        <!-- response message -->
        <bxs-alert
        v-if="res"
        type="success"
        class="mt-ui">Grazie per il tuo invio. Ti ricontatteremo appena possibile.</bxs-alert>
    </bxs-form>
</template>

<script>
export default {
    name: 'form_crea_leila',
    data () {
        return {
            key: 1,
            loading: false,
            error: null,
            res: null,
            model: {
                subject: 'Crea una Leila anche tu',
                categoria: 'Persona fisica',
                nome: null,
                cognome: null,
                email: null,
                provincia: null,
                antispam: null,
                privacy: false,
                indirizzo: null,
                città: null,
                codice_postale: null,
                nazione: null,
                raccontaci_chi_sei: null,
                data_di_nascita: null
            }
        }
    },
    computed: {
        is_submitable () {
            const v = !this.loading && this.model.antispam === '11' && !!this.model.privacy
            return v
        }
    },
    methods: {
        async submit () {
            this.loading = true
            this.error = null

            try {
                this.res = await this.$api.post('v1/forms/crea-una-leila-anche-tu', this.model)

                this.model = {
                    subject: 'Crea una Leila anche tu',
                    categoria: 'Persona fisica',
                    nome: null,
                    cognome: null,
                    email: null,
                    provincia: null,
                    antispam: null,
                    privacy: false,
                    indirizzo: null,
                    città: null,
                    codice_postale: null,
                    nazione: null,
                    raccontaci_chi_sei: null,
                    data_di_nascita: null
                }

                this.key += 1
            } catch (err) {
                this.error = err
            } finally {
                this.loading = false
            }
        }
    }
}
</script>