<template>
    <div class="collaborazioni-leila-nelle-biblioteche-view">
        <bxs-hero
        src="/img/leila-in-biblioteca-banner.png"
        ratio="21-6"
        mobile-ratio="2-3"
        scrolldown
        light
        overlay>
            <div class="flex column align-center justify-center text-center pa-ui fill-height">
                <div>
                    <h1 class="text-500">Leila in Biblioteca</h1>
                    <h5>Ora puoi prendere in prestito tutto quello che vuoi, gratis!</h5>
                </div>
            </div>
        </bxs-hero>

        <section>
            <bxs-layout class="text-center">
                <h2>Prendi, utilizza, riporta. <br> Facile, utile ed ecologico</h2>
                <p>Scopri come funziona il servizio di condivisione degli oggetti di Leila.</p>
                <bxs-btn
                href="#section-come-funziona-full"
                target="_self"
                append-icon="arrow-down">Scopri come funziona</bxs-btn>
            </bxs-layout>
        </section>

        <section>
            <bxs-layout>
                <bxs-row>
                    <bxs-col>
                        <bxs-figure src="/img/libro.png"></bxs-figure>
                    </bxs-col>

                    <bxs-col>
                        <bxs-figure src="/img/proiettore.png"></bxs-figure>
                    </bxs-col>

                    <bxs-col>
                        <bxs-figure src="/img/sassofono.png"></bxs-figure>
                    </bxs-col>

                    <bxs-col>
                        <bxs-figure src="/img/trapano.png"></bxs-figure>
                    </bxs-col>
                </bxs-row>
            </bxs-layout>
        </section>

        <!-- ------------------------------------------------------------------------------------------------------------------
        ------------------------------------------------------------------------------------------------------------------ -->
        <section>
            <bxs-layout>
                <div class="mb-ui">
                    <p class="label">Il progetto</p>
                </div>

                <div>
                    <p>Leila e le Biblioteche Comunali hanno unito le forze per offrire <b>un servizio capace di rispondere concretamente alle sfide del nostro tempo.</b> Ora nella tua biblioteca puoi prendere in prestito oltre 300 oggetti, senza spendere un centesimo e rispettando l’ambiente</p>
                    <p>La prima fase del progetto è nata tra le mura della piazza coperta di Biblioteca Salaborsa diversi anni fa. Nel 2022 si è suggellata con un una super promozione: <b>con la tessera delle Biblioteche Comunali di Bologna, quella di Leila è in regalo!</b></p>
                    <p>L’inizio di un percorso comune volto verso lo stesso obiettivo: <b>integrare il servizio di condivisione degli oggetti in tutte le Biblioteche Comunali di Bologna.</b></p>
                    <p>Dopo un anno, parte la seconda fase del progetto. La prima vera sperimentazione di ciò che sarà il traguardo finale. E non poteva che iniziare a dicembre, un regalo di natale per la cittadinanza. <b>Ora i Punti Leila nelle biblioteche sono tre: Salaborsa, Luigi Spina e Jorge Luis Borges.</b></p>
                    <p>La più grande avventura di Leila ha inizio, fanne parte anche tu. Il futuro è condivisione!</p>
                </div>
            </bxs-layout>
        </section>

        <!-- ------------------------------------------------------------------------------------------------------------------
        ------------------------------------------------------------------------------------------------------------------ -->
        <section-sedi />

        <!-- ------------------------------------------------------------------------------------------------------------------
        ------------------------------------------------------------------------------------------------------------------ -->
        <section-come-funziona-full />
    </div>
</template>

<script>
export default {
    name: 'collaborazioni_leile_nelle_biblioteche'
}
</script>