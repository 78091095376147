<template>
    <div class="collaborazioni-blocco-comune">
        <bxs-hero
        src="https://leila-bologna.it/wp-content/uploads/2022/12/logo.jpg"
        light
        overlay>
            <div class="flex column align-center justify-center text-center pa-ui fill-height">
                <div>
                    <h1 class="text-500">Collaborazione - Il Biricoccolo</h1>
                </div>
            </div>
        </bxs-hero>

        <!-- ------------------------------------------------------------------------------------------------------------------
        ------------------------------------------------------------------------------------------------------------------ -->
        <section>
            <bxs-layout>
                <bxs-row>
                    <bxs-col
                    cols="12"
                    ds="6">
                        <div class="flex nowrap">
                            <bxs-figure
                            src="https://leila-bologna.it/wp-content/uploads/2022/12/logo.jpg"
                            max-width="120"
                            class="mr-ui"></bxs-figure>

                            <div>
                                <p class="mb-2 text-400">Il Biricoccolo Fattoria Sociale e CSA</p>
                                <small>Via Tombetto, 13 – Località Crespellano 40053 Valsamoggia (BO)</small> <br>
                                <a href="https://www.ilbiricoccolo.it/" target="_blank"><small>Sito web</small></a> <br>
                                <a href="https://www.instagram.com/ar.cowoodworkshop/" target="_blank"><small>Instagram</small></a> <br>
                                <a href="https://www.facebook.com/ilBiricoccolofattoriasociale/" target="_blank"><small>Facebook</small></a>
                            </div>
                        </div>
                    </bxs-col>

                    <bxs-col
                    cols="12"
                    ds="6">
                        <p class="mb-2 text-400">Referente</p>
                        <small>Elena</small> <br>
                        <a href="mailto:info@ilbiricoccolo.it" target="_blank"><small>info@ilbiricoccolo.it</small></a> <br>
                        <a href="tel:+393337628062" target="_blank"><small>333 762 80 62</small></a>
                    </bxs-col>
                </bxs-row>
            </bxs-layout>
        </section>

        <!-- ------------------------------------------------------------------------------------------------------------------
        ------------------------------------------------------------------------------------------------------------------ -->
        <section>
            <bxs-carousel
            :items="[
                'https://leila-bologna.it/wp-content/uploads/2022/12/foto-slide.jpg',
                'https://leila-bologna.it/wp-content/uploads/2022/12/BRUNO.png',
                'https://leila-bologna.it/wp-content/uploads/2022/12/foto.jpg',
                'https://leila-bologna.it/wp-content/uploads/2022/12/Immagine1.jpg'
            ]"
            items-per-view="4">
                <template #item="{ item }">
                    <bxs-figure
                    :src="item"
                    ratio="3-2" />
                </template>
            </bxs-carousel>
        </section>

        <!-- ------------------------------------------------------------------------------------------------------------------
        ------------------------------------------------------------------------------------------------------------------ -->
        <section>
            <bxs-layout>
                <div>
                    <p>Leila è orgogliosa di collaborare con l’azienda agricola e fattoria sociale Il Biricoccolo. In che modo? Semplice, dal 21 ottobre 2021 Leila diventerà un punto di distribuzione dei loro buonissimi prodotti! Il ritiro è ogni giovedì dalle 16:30 alle 19:00 ed è riservato agli aderenti al Gruppo di Supporto all’Agricoltura CSA. Sicuramente ti starai chiedendo: cos’è il Gruppo di Supporto all’Agricoltura CSA? Che vantaggi avrei entrando a far parte di questo gruppo? Ormai è riconosciuto che il mercato alimentare, con i suoi sprechi e le sue logiche iper-consumistiche, è protagonista assoluto nell’incremento dei due più grandi problemi della nostra era: il cambiamento climatico e le diseguaglianze sociali. Nel suo piccolo Leila vuole fare la differenza anche in questo campo, e tu?</p>
                    <p>Esattamente come per la nostra Biblioteca degli oggetti, entrare a far parte del Gruppo di Supporto all’Agricoltura CSA del Biricoccolo offre dei vantaggi concreti a tutti:</p>
                    <ul>
                        <li>durante la coltivazione non vengono usate sostanze chimiche;</li>
                        <li>la diffusione dei prodotti avviene sul mercato locale nell’ottica della filiera corta-km0;</li>
                        <li>la vendita diretta permette di rendere accessibili i prodotti biologici a tutti;</li>
                        <li>con il progetto CSA gli agricoltori possono ricevere un reddito più stabile e sicuro;</li>
                        <li>i prodotti vengono venduti a un giusto prezzo;</li>
                        <li>in questo modo sostieni un’agricoltura che promuove la sovranità alimentare e cioè il diritto delle persone di prendere decisioni a proposito del cibo che portano in tavola;</li>
                        <li>mangi prodotti freschi, sani e di stagione.</li>
                    </ul>
                </div>

                <div>
                    <p>Siamo una piccola Azienda Agricola di 4 soci e crediamo in un modo “diverso” di praticare l’agricoltura. Abbiamo scelto di coltivare senza nessun utilizzo di sostante chimiche di sintesi e produciamo principalmente ortaggi, cereali antichi (il grano tenero Verna, un grano duro Senatore Cappelli e farro), legumi, oltre a un piccolo frutteto misto di varietà antiche e moderne, una selezione di piccoli frutti, frutti salutistici e piante “dimenticate” o poco conosciute (tra cui il Biricoccolo, un antico frutto tipico del bolognese).</p>
                    <p>Curiamo la diffusione dei nostri prodotti sul mercato locale nell’ottica della filiera corta-km0. La vendita diretta permette di rendere accessibili i prodotti biologici a tuttə e la proposta di ortaggi con formula a “cassetta-mista”, di diversi pesi e varietà, è stata pensata per venire incontro a tutte le esigenze familiari di consumo settimanale.</p>
                    <p>Portiamo avanti un progetto di CSA (Community Supported Agriculture) con il quale si intende una relazione di partenariato tra uno o più agricoltori e una comunità di sostenitori uniti tra loro in rete. Questi ultimi garantiscono una parte o tutto il bilancio operativo pre-finanziando, una o più “quote” del raccolto della stagione e ricevendo settimanalmente o quindicinalmente un paniere di ortaggi freschissimi, buoni e giusti.</p>
                    <p>I benefici sono goduti da entrambi i lati: gli agricoltori possono ricevere un reddito più stabile e sicuro e una più stretta connessione con la comunità. I prodotti vengono venduti a un giusto prezzo a una rete di conoscenti e amici che garantisce una domanda fissa e stabile con conseguente solidità degli aspetti finanziari. Gli aderenti, oltre a gustare cibo freschissimo e prossimo, sostengono un’agricoltura che promuove la sovranità alimentare e cioè il diritto delle persone di prendere decisioni a proposito del cibo che portano in tavola, un’agricoltura che pone attenzione a quanta energia e a quanta acqua sono necessarie alla produzione, a quali condizioni di lavoro nei campi, all’importanza del mantenimento della fertilità dei suoli e della biodiversità del territorio.</p>
                    <p>Miriamo a ricreare la relazione, andata purtroppo perduta, tra chi il cibo lo produce e chi lo mangia restituendogli così un valore e non solo un prezzo. Aspiriamo a essere un esempio di “Agricoltura Civica”: perseguire il bene comune secondo i principi dell’economia solidale</p>
                </div>
            </bxs-layout>
        </section>
    </div>
</template>

<script>
export default {
    name: 'collaborazioni_il_biricoccolo'
}
</script>