<template>
    <section id="section-crea-una-leila-anche-tu">
        <bxs-layout>
            <div class="mb-ui">
                <p class="label">Crea una Leila anche tu</p>
            </div>

            <div class="text-center">
                <h3>Ti aiutiamo ad aprire la tua biblioteca degli oggetti</h3>
                <p class="mb-0">Tante e sempre più persone ci contattano per conoscere meglio il progetto con la curiosità, il desiderio o il vero e proprio obiettivo di aprire una Leila nella propria città.</p>
                <p>Persona fisica, associazione, azienda o ente pubblico, con il nostro aiuto, chiunque può aprire una biblioteca degli oggetti.</p>

                <div class="mt-ui">
                    <bxs-btn to="/voi-e-leila/crea-una-leila-anche-tu">Scopri di più</bxs-btn>
                </div>
            </div>
        </bxs-layout>
    </section>
</template>

<script>
export default {
    name: 'section_crea_una_leila_anche_tu'
}
</script>