<template>
    <div :class="['bxs-menu', {
        'bxs-menu-on': local_on
    }]">
        <!-- activator -->
        <div
        v-if="!!$slots.activator && !solo"
        class="bxs-menu--activator">
            <slot
            name="activator"
            :on="{
                click: () => local_on = !local_on
            }"
            :attrs="{
                role: 'button',
                'aria-haspopup': true,
                'aria-expanded': false
            }" />
        </div>

        <div v-else-if="!$slots.activator && !solo">
            <bxs-btn
            :icon="icon"
            :x-small="activatorSize === 'x-small'"
            :small="activatorSize === 'small'"
            :large="activatorSize === 'large'"
            @click="open">
                <bxs-icon :name="activatorIcon || 'three-dots-vertical'" />
            </bxs-btn>
        </div>

        <!-- content -->
        <Teleport to="body">
            <div
            ref="menu"
            :class="['bxs-menu--menu', { 'bxs-menu--menu-on': local_on }]"
            @click.self="handleClickForeground">
                <bxs-layout
                :max-width="maxWidth"
                :min-height="minHeight"
                class="bxs-menu--menu--container bxs-elevation ma-0 pa-0">
                    <div
                    ref="header"
                    class="bxs-menu--menu--container--header">
                        <bxs-title
                        v-if="title"
                        :level="5"
                        class="mb-0">{{ title }}</bxs-title>

                        <slot name="header" />

                        <!-- <bxs-spacer v-if="closable"></bxs-spacer>

                        <bxs-btn
                        v-if="closable"
                        icon
                        @click="close">
                            <bxs-icon name="close" />
                        </bxs-btn> -->
                    </div>

                    <!--  -->
                    <div
                    ref="scroller"
                    class="bxs-menu--menu--container--body bxs-scrollable">
                        <slot />
                    </div>

                    <!--  -->
                    <div
                    ref="footer"
                    class="bxs-menu--menu--container--footer">
                        <bxs-list-item
                        v-if="closable && !solo"
                        min-height="60"
                        :class="['text-mute', 'text-uppercase', {
                            'text-center': !$slots.actions
                        }]"
                        @click="close">Chiudi</bxs-list-item>

                        <slot
                        name="actions"
                        :on="{
                            click: () => local_on = !local_on
                        }"
                        :attrs="{
                            role: 'button',
                            'aria-haspopup': true,
                            'aria-expanded': false
                        }" />
                    </div>
                </bxs-layout>
            </div>
        </Teleport>
    </div>
</template>

<script>
export default {
    name: 'bxs-menu',
    props: {
        modelValue: {
            type: Boolean,
            required: false,
            default: false
        },
        title: {
            type: String,
            required: false,
            default: null
        },
        'max-width': {
            type: [String, Number],
            required: false,
            default: 400
        },
        'min-height': {
            type: [String, Number],
            required: false,
            default: null
        },
        'activator-size': {
            type: String,
            required: false,
            default: null
        },
        'activator-icon': {
            type: String,
            required: false,
            default: null
        },
        solo: {
            type: Boolean,
            required: false,
            default: false
        },
        icon: {
            type: Boolean,
            required: false,
            default: false
        },
        'close-on-click': {
            type: Boolean,
            required: false,
            default: false
        },
        closable: {
            type: Boolean,
            required: false,
            default: true
        }
    },
    emits: [
        'update:modelValue',
        'open',
        'close',
        'infinite-scroll'
    ],
    data () {
        return {
            local_on: this.modelValue,
            body_h: 0
        }
    },
    mounted () {
        this.$nextTick(this.play)
    },
    watch: {
        local_on (newVal) {
            if (newVal) {
                this.$emit('open')

                this.$nextTick(() => {
                    if (this.$refs.scroller) {
                        this.$refs.scroller.addEventListener('scroll', this.handleScroll)
                    }
                })
            } else {
                this.$emit('close')

                if (this.$refs.scroller) {
                    this.$refs.scroller.removeEventListener('scroll', this.handleScroll)
                }
            }

            this.$emit('update:modelValue', newVal)
        },
        modelValue (newVal) {
            this.local_on = newVal
        }
    },
    methods: {
        play () {
            if (this.$refs.scroller) {
                this.$refs.scroller.addEventListener('scroll', this.handleScroll)
            }

            if (this.closeOnClick) {
                window.addEventListener('click', this._checkClose)
            }

            this.resize()
        },
        resize () {
            let h = this.$refs.header.clientHeight
            if (this.$refs.footer) h += this.$refs.footer.clientHeight
            this.body_h = (this.$refs.scroller.clientHeight - h) + 'px'
        },
        stop () {
            if (this.$refs.scroller) {
                this.$refs.scroller.removeEventListener('scroll', this.handleScroll)
            }

            if (this.closeOnClick) {
                window.removeEventListener('click', this.checkClose)
            }
        },
        handleScroll () {
            if (!this.$refs.scroller) return

            const {
                scrollTop,
                scrollHeight,
                clientHeight
            } = this.$refs.scroller

            // console.log(scrollTop + clientHeight, clientHeight, scrollHeight)

            if (
                scrollTop + clientHeight >= (scrollHeight - (clientHeight / 2))
            ) {
                this.$emit('infinite-scroll')
            }
        },
        _checkClose (e) {
            if (!this.$el.contains(e.target)) {
                // console.log('Clicked outside l2 and logo-menu')
                this.local_on = false
            }
        },
        handleClickForeground () {
            this.close()
        },
        open () {
            this.local_on = true
            console.log('menu open()', this.local_on)
        },
        close () {
            console.log('menu close()')
            this.local_on = false
        }
    },
    beforeUnmount () {
        this.stop()
    }
}
</script>

<style lang="scss" scoped>
@import '@/assets/styles/mq.scss';

// $mq-static-breakpoint: mm;

.menu-enter-active,
.menu-leave-active {
    transition: transform 0.2s ease-out;
}

.menu-enter-from,
.menu-leave-to {
    transform: translateY(100%);
}

.bxs-menu {
    &--activator {
        position: relative;
    }

    &--menu {
        position: fixed;
        width: 100%;
        height: 100vh;
        height: calc(var(--vh, 1vh) * 100);
        top: 0;
        left: 0;
        -webkit-backdrop-filter: var(--menu-foreground-backdrop-filter);
        backdrop-filter: var(--menu-foreground-backdrop-filter);

        display: flex;
        flex-flow: column nowrap;
        justify-content: flex-end;

        z-index: var(--layer-menu);

        // trans
        pointer-events: none;
        transition: background-color 0.2s ease;

        @include mq($mq-static-breakpoint) {
            justify-content: flex-start;
            align-items: flex-end;

            > .bxs-layout {
                margin-right: 0;
            }
        }

        &.bxs-menu--menu-on {
            pointer-events: inherit;
            background-color: var(--menu-foreground-background-color);

            .bxs-menu--menu--container {
                transform: translateY(0);

                @include mq($mq-static-breakpoint) {
                    transform: translateX(0);
                }
            }
        }

        &--container {
            position: relative;
            display: flex;
            flex-direction: column;
            max-height: calc(100% - var(--toolbar-height));
            background-color: var(--menu-content-background-color);
            border-radius: var(--menu-content-border-radius);
            padding: var(--menu-content-padding-y) var(--menu-content-padding-x);
            overflow: hidden;

            transform: translateY(100%);
            transition: transform 0.28s ease-out;

            @include mq($until: $mq-static-breakpoint) {
                max-width: 100% !important;
            }

            // desktop
            @include mq($mq-static-breakpoint) {
                border-top-right-radius: 0;
                height: 100% !important;
                max-height: 100%;
                transform: translateX(100%);
            }

            &--header {
                position: relative;
                min-height: var(--toolbar-height);
                padding-left: var(--size-layout);
                padding-right: var(--size-layout);
                padding-top: calc(var(--size-ui) + 18px);
                padding-bottom: var(--size-ui);
                z-index: 2;

                &::before {
                    content: "";
                    position: absolute;
                    top: 6px;
                    left: 0;
                    right: 0;
                    margin: auto;
                    width: 40px;
                    height: 4px;
                    border-radius: 4px;
                    background-color: var(--divider-color);
                }
            }

            &--body {
                position: relative;
                flex: 1;
                z-index: 1;
            }

            &--footer {
                position: relative;
                margin-top: auto;
                min-height: var(--list-item-min-height);
                z-index: 2;
            }
        }
    }
}
</style>