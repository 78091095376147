<template>
    <div class="fare-condivisione-leila-social-club-view">
        <bxs-hero
        title="Fare condivisione - Leila social club"
        src="https://leila-bologna.it/wp-content/uploads/2023/02/leila-social-club.jpg" />

        <section>
            <bxs-layout>
                <div>
                    <h3>La massima espressione della condivisione dei saperi</h3>
                    <p>Fai la tessera di Leila ed entra a far parte di LEILA SOCIAL CLUB: condividi le tue competenze e beneficia di quelle della comunità della condivisione!</p>
                </div>
            </bxs-layout>
        </section>

        <section>
            <bxs-layout>
                <div>
                    <p class="label">Come funziona</p>
                </div>

                <bxs-row>
                    <bxs-col cols="12" ds="6">
                        <p>Sai fare qualcosa che pensi possa essere utile? Condividila! Scrivi a info@leila-bologna.it</p>
                        <p>Hai bisogno di qualcosa che non sai fare? Scorri la pagina e scopri se la comunità della condivisione può aiutarti! Se così non fosse puoi scriverci e possiamo cercare una persona che può fare al caso tuo!</p>
                        <p>Il servizio di condivisione dei saperi di LEILA SOCIAL CLUB è gratuito, questo significa che non si deve pagare l’aiuto ricevuto nè riceverai un compenso se offri le tue competenze.</p>
                    </bxs-col>

                    <bxs-col cols="12" ds="6">
                        <bxs-figure src="https://leila-bologna.it/wp-content/uploads/2022/12/leila-bologna-social-club-1-1024x683.jpg"></bxs-figure>
                    </bxs-col>
                </bxs-row>
            </bxs-layout>
        </section>

        <section>
            <bxs-layout>
                <div>
                    <p class="label">Perchè Leila social club?</p>
                </div>

                <bxs-row>
                    <bxs-col cols="12" ds="6">
                        <p>Perché Leila crede che non c’è cosa più preziosa delle relazioni.</p>
                        <p>Perché pensa che riparare e costruire sia una delle vie maestre per combattere il cambiamento climatico e assicurare un futuro al mondo.</p>
                        <p>Perché ha deciso di creare un luogo dove non si vende, ma si condivide. Dove non si acquista, ma si impara.</p>
                        <p>Un luogo dove il valore delle relazioni, della partecipazione attiva ai problemi più impellenti del nostro tempo attraverso la condivisione di intenti, saperi, spazi, esperienze, paure, speranze e ovviamente oggetti, siano la chiave di volta per creare una rivoluzione culturale basata da pratiche quotidiane che ostinatamente agiscono in direzione ostinata e contraria.</p>
                    </bxs-col>

                    <bxs-col cols="12" ds="6">
                        <bxs-figure src="https://leila-bologna.it/wp-content/uploads/2022/12/leila-bologna-social-club-attivita-1024x683.jpg"></bxs-figure>
                    </bxs-col>
                </bxs-row>
            </bxs-layout>
        </section>

        <section>
            <bxs-layout>
                <div>
                    <p class="label">Il ciappano di quartiere</p>
                </div>

                <bxs-row>
                    <bxs-col cols="12" ds="6">
                        <bxs-figure src="https://leila-bologna.it/wp-content/uploads/2023/02/leila-social-club.jpg"></bxs-figure>
                    </bxs-col>

                    <bxs-col cols="12" ds="6">
                        <p>TI SI È ROTTA UNA SEDIA? IL CASSETTO NON SI APRE PIÙ BENE? NON SAI COME RIPARARE UNA CORNICE?</p>
                        <p>Amos artigiano e tuttofare sarà da Leila tutti i venerdì dalle 16:30 alle 19:00, pronto ad aiutarti a sistemare piccoli oggetti o complementi d’arredo.</p>
                        <p>⚠️ ATTENZIONE: prima di venire in sede con il tuo oggetto da riparare, chiama Amos e scopri se ti può aiutare: 349 811 5605</p>
                    </bxs-col>
                </bxs-row>

                <bxs-row>
                    <bxs-col cols="12" ds="4">
                        <bxs-figure src="https://leila-bologna.it/wp-content/uploads/2023/02/ciappinaro_1.jpg"></bxs-figure>
                    </bxs-col>

                    <bxs-col cols="12" ds="4">
                        <bxs-figure src="https://leila-bologna.it/wp-content/uploads/2023/02/ciappinaro_3.jpg"></bxs-figure>
                    </bxs-col>

                    <bxs-col cols="12" ds="4">
                        <bxs-figure src="https://leila-bologna.it/wp-content/uploads/2023/02/ciappinaro_2.jpg"></bxs-figure>
                    </bxs-col>
                </bxs-row>
            </bxs-layout>
        </section>
    </div>
</template>

<script>
export default {
    name: 'fare_condivisione_leila_social_club'
}
</script>