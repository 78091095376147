<template>
    <section id="section-about">
        <bxs-layout>
            <div>
                <p class="label">Chi siamo</p>
                <h2>Leila è condivisione</h2>
                <h4 class="text-400 text-ui">La prima biblioteca degli oggetti in Italia</h4>
                <p class="h5 text-ui line-height-normal">Leila è un luogo in cui gli oggetti si possono <bxs-link label="prendere in prestito gratuitamente" to="/come-funziona" hide-arrow actived></bxs-link>, evitando così di doverli comprare. Nasce nel 2016 come associazione culturale affermandosi sin da subito come uno strumento concreto di <b>economia circolare</b> e di prossimità, <b>tutela ambientale</b> e creazione di <b>comunità</b> attraverso la <b>cultura della condivisione</b>.</p>
            </div>

            <div>
                <bxs-link
                label="Scopri chi siamo"
                to="/storia-e-filosofia-di-leila" />
            </div>
        </bxs-layout>

        <products-ux />
    </section>
</template>

<script>
export default {
    name: 'section_about'
}
</script>