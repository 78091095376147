<template>
    <div class="contacts-view">
        <bxs-hero
        title="Contatti"
        src="/img/leila-bologna-volontariato.jpg"></bxs-hero>

        <!-- ------------------------------------------------------------------------------------------------------------------
        ------------------------------------------------------------------------------------------------------------------ -->
        <section>
            <bxs-layout>
                <div class="text-center mb-ui">
                    <h4>Siamo pronti per aiutarti, contattaci se hai bisogno di informazioni o supporto</h4>
                </div>

                <!--  -->
                <bxs-row>
                    <bxs-col
                    cols="12"
                    ds="6">
                        <ul class="mb-ui">
                            <li class="mb-2">
                                <a
                                href="https://www.google.com/maps/dir/43.7689164,11.2614653/leila+bologna/@44.1251686,10.738903,9z/data=!3m1!4b1!4m9!4m8!1m1!4e1!1m5!1m1!1s0x477fd5250f189157:0x8c7bf3d382d7a49d!2m2!1d11.3457095!2d44.5084718"
                                target="_blank">
                                    <bxs-icon class="mr-3">
                                        <path d="M12.166 8.94c-.524 1.062-1.234 2.12-1.96 3.07A31.493 31.493 0 0 1 8 14.58a31.481 31.481 0 0 1-2.206-2.57c-.726-.95-1.436-2.008-1.96-3.07C3.304 7.867 3 6.862 3 6a5 5 0 0 1 10 0c0 .862-.305 1.867-.834 2.94zM8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10z"/>
                                        <path d="M8 8a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm0 1a3 3 0 1 0 0-6 3 3 0 0 0 0 6z"/>
                                    </bxs-icon>

                                    <span>Via Luigi Serra, 2 G/H, 40129 Bologna (BO)</span>
                                </a>
                            </li>

                            <li class="mb-2">
                                <a
                                href="mailto:info@leila-bologna.it"
                                target="_blank">
                                    <bxs-icon
                                    name="send"
                                    class="mr-3"></bxs-icon>

                                    <span>info@leila-bologna.it</span>
                                </a>
                            </li>

                            <li class="mb-2">
                                <a
                                href="tel:0510281845"
                                target="_blank">
                                    <bxs-icon class="mr-3">
                                        <path d="M11 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h6zM5 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H5z"/>
                                        <path d="M8 14a1 1 0 1 0 0-2 1 1 0 0 0 0 2z"/>
                                    </bxs-icon>

                                    <span>051 0281845</span> <br>
                                </a>
                            </li>

                            <li class="mb-2 mt-ui">
                                <p>
                                    <bxs-icon
                                    name="calendar"
                                    class="mr-3"></bxs-icon>
                                    <span>Lunedì, Mercoledì, Giovedì, Venerdì</span>
                                </p>
                            </li>

                            <li>
                                <p>
                                    <bxs-icon class="mr-3">
                                        <path d="M8 3.5a.5.5 0 0 0-1 0V9a.5.5 0 0 0 .252.434l3.5 2a.5.5 0 0 0 .496-.868L8 8.71V3.5z"/>
                                        <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm7-8A7 7 0 1 1 1 8a7 7 0 0 1 14 0z"/>
                                    </bxs-icon>

                                    dalle 16:00 alle 19:00
                                </p>
                            </li>
                        </ul>

                        <div class="mt-ui">
                            <small>* telefono attivo nei giorni e negli orari di apertura</small>
                        </div>

                        <!-- <div class="mt-ui">
                            <iframe
                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2845.3259273717663!2d11.345712761262309!3d44.508477079486994!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x477fd5250f189157%3A0x8c7bf3d382d7a49d!2sLeila%20Bologna%20-%20La%20Biblioteca%20degli%20Oggetti!5e0!3m2!1sit!2sit!4v1699275481452!5m2!1sit!2sit"
                            width="600"
                            height="450"
                            style="border:0;"
                            allowfullscreen=""
                            loading="lazy"
                            referrerpolicy="no-referrer-when-downgrade"></iframe>
                        </div> -->
                    </bxs-col>

                    <bxs-col
                    cols="12"
                    ds="6">
                        <form-contatto />
                        <!-- <p>Al momento il form contatto è fuori servizio, ritornerà il prima possibile, contattaci direttamente tramite <a href="mailto:info@leila-bologna.it" target="_blank"><span>indirizzo email</span></a>.</p> -->
                    </bxs-col>
                </bxs-row>
            </bxs-layout>
        </section>

        <!-- ------------------------------------------------------------------------------------------------------------------
            sedi
        ------------------------------------------------------------------------------------------------------------------ -->
        <section-sedi />

        <!-- ------------------------------------------------------------------------------------------------------------------
            social
        ------------------------------------------------------------------------------------------------------------------ -->
        <section-social />
    </div>
</template>

<script>
export default {
    name: 'contacts',
    data () {
        return {
            model: {
                name: null,
                email: null,
                message: null
            }
        }
    }
}
</script>

<style lang="scss" scoped>
p {
    margin-bottom: 0;
}

small {
    font-size: 0.7rem;
}

iframe {
    display: block;
    width: 100%;
    max-width: 100%;
}
</style>