<template>
    <div class="statuto-view">
        <bxs-hero title="Statuto"></bxs-hero>

        <iframe
        src="https://leila-bologna.it/wp-content/uploads/2022/10/statuto.pdf"
        frameborder="0"
        allowfullscreen=""
        loading="lazy"
        referrerpolicy="no-referrer-when-downgrade"></iframe>
    </div>
</template>

<script>
export default {
    name: 'statuto'
}
</script>

<style lang="scss" scoped>
iframe {
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
}
</style>