<template>
    <div class="index-view">
        <!-- no-gutters -->
        <bxs-hero
        v-if="!$route.query.hero"
        src="/img/leila-home.jpg"
        overlay
        light
        fullscreen>
            <div class="flex column align-center justify-center text-center fill-height">
                <div>
                    <h5 class="text-secondary mb-1">Il futuro è condivisione</h5>
                    <h1 class="h2 text-500">La Biblioteca Degli Oggetti</h1>
                    <p class="h6 text-ui text-transform-none text-400">Utilizza tutti gli oggetti che vuoi, come vuoi, quando vuoi e senza spendere un centesimo.</p>
                </div>

                <div>
                    <bxs-btn
                    to="/come-funziona"
                    color="secondary"
                    dark>Scopri come funziona</bxs-btn>

                    <!-- <div class="mt-1">
                        <small>Contribuisci a rendere il mondo un luogo più equo, etico ed ecologico</small>
                    </div> -->
                </div>
            </div>
        </bxs-hero>

        <bxs-hero
        v-else-if="$route.query.hero === '2'"
        :src="null"
        fullscreen>
            <products-ux />

            <div
            class="flex column align-center justify-center text-center fill-height"
            style="margin-top: -5vh;">
                <div>
                    <h5 class="text-secondary mb-1">Leila è condivisione</h5>
                    <h1 class="text-500">La Biblioteca Degli Oggetti</h1>
                    <p class="h6 text-ui text-transform-none text-400">Utilizza tutti gli oggetti che vuoi, come vuoi, quando vuoi e senza spendere un centesimo.</p>
                </div>

                <div>
                    <bxs-btn
                    to="/come-funziona"
                    color="secondary"
                    dark>Scopri come funziona</bxs-btn>
                </div>
            </div>
        </bxs-hero>

        <!-- ------------------------------------------------------------------------------------------------------------------
            chi siamo
        ------------------------------------------------------------------------------------------------------------------ -->
        <section-about />

        <!-- ------------------------------------------------------------------------------------------------------------------
            i numeri
        ------------------------------------------------------------------------------------------------------------------ -->
        <section-i-numeri class="pb-0" />

        <!-- ------------------------------------------------------------------------------------------------------------------
            numero + dicono di noi
        ------------------------------------------------------------------------------------------------------------------ -->
        <section-dicono-di-noi class="pt-0" />

        <!-- ------------------------------------------------------------------------------------------------------------------
            breath section
        ------------------------------------------------------------------------------------------------------------------ -->
        <section class="px-layout">
            <bxs-figure src="/img/team-leila-bologna.jpg" />
        </section>

        <!-- ------------------------------------------------------------------------------------------------------------------
            come funziona
        ------------------------------------------------------------------------------------------------------------------ -->
        <section-come-funziona />

        <!-- ------------------------------------------------------------------------------------------------------------------
           webapp
        ------------------------------------------------------------------------------------------------------------------ -->
        <section-oggetti />

        <!-- ------------------------------------------------------------------------------------------------------------------
            punti leila
        ------------------------------------------------------------------------------------------------------------------ -->
        <section-sedi />

        <!-- ------------------------------------------------------------------------------------------------------------------
            tessera
        ------------------------------------------------------------------------------------------------------------------ -->
        <section-tessera />

        <!-- ------------------------------------------------------------------------------------------------------------------
            collaborazioni
        ------------------------------------------------------------------------------------------------------------------ -->
        <section-collaborazioni />

        <!-- ------------------------------------------------------------------------------------------------------------------
            breath section
        ------------------------------------------------------------------------------------------------------------------ -->
        <!-- <section class="mute">
            <p class="text-big">Abbiamo bisogno di utilizzare, <br class="show-ds"> <span class="text-outlined text-line-through">non di possedere</span></p>
        </section> -->

        <!-- ------------------------------------------------------------------------------------------------------------------
            crea una leila anche tu
        ------------------------------------------------------------------------------------------------------------------ -->
        <section-crea-una-leila-anche-tu />

        <!-- ------------------------------------------------------------------------------------------------------------------
            la rete
        ------------------------------------------------------------------------------------------------------------------ -->
        <section-la-rete />

        <!-- ------------------------------------------------------------------------------------------------------------------
            voi e leila
        ------------------------------------------------------------------------------------------------------------------ -->
        <section-voi-e-leila />

        <!-- ------------------------------------------------------------------------------------------------------------------
            fare condivisione
        ------------------------------------------------------------------------------------------------------------------ -->
        <section-fare-condivisione />

        <!-- ------------------------------------------------------------------------------------------------------------------
            storie di leila
        ------------------------------------------------------------------------------------------------------------------ -->
        <section-storie-di-leila />

        <!-- ------------------------------------------------------------------------------------------------------------------
            news
        ------------------------------------------------------------------------------------------------------------------ -->
        <section-latest-news />

        <!-- ------------------------------------------------------------------------------------------------------------------
            social
        ------------------------------------------------------------------------------------------------------------------ -->
        <section-social />
    </div>
</template>

<script>
export default {
    name: 'index'
}
</script>