<template>
    <div class="collaborazioni-barca-59">
        <bxs-hero
        src="https://leila-bologna.it/wp-content/uploads/2022/12/logo-barca-59.png"
        light
        overlay>
            <div class="flex column align-center justify-center text-center pa-ui fill-height">
                <div>
                    <h1 class="text-500">Collaborazione - Barca59</h1>
                </div>
            </div>
        </bxs-hero>

        <!-- ------------------------------------------------------------------------------------------------------------------
        ------------------------------------------------------------------------------------------------------------------ -->
        <section class="pb-0">
            <bxs-layout>
                <bxs-row>
                    <bxs-col
                    cols="12"
                    ds="6">
                        <div class="flex nowrap">
                            <bxs-figure
                            src="https://leila-bologna.it/wp-content/uploads/2022/12/logo-barca-59.png"
                            max-width="120"
                            class="mr-ui"></bxs-figure>

                            <div>
                                <p class="mb-2 text-400">Barca59 Impresa Sociale Società Cooperativa ETS</p>
                                <small>Via Piero della Francesca 1/2 – 40133 Bologna (BO)</small> <br>
                                <small>info@barca59.org</small>
                            </div>
                        </div>
                    </bxs-col>

                    <bxs-col
                    cols="12"
                    ds="6">
                        <p class="mb-2 text-400">Referente</p>
                        <small>Federico Palmas <span class="text-disabled ml-1">Community Manager</span></small> <br>
                        <a href="tel:329 4246549"><small>329 4246549</small></a> <br>
                        <a href="mailto:progetti@barca59.org"><small>progetti@barca59.org</small></a>
                    </bxs-col>
                </bxs-row>
            </bxs-layout>
        </section>

        <!-- ------------------------------------------------------------------------------------------------------------------
        ------------------------------------------------------------------------------------------------------------------ -->
        <section>
            <bxs-carousel
            :items="[,
                'https://leila-bologna.it/wp-content/uploads/2022/12/leila-bologna-barca-59-inaugurazione.jpg',
                'https://leila-bologna.it/wp-content/uploads/2022/12/leila-bologna-barca-59-repair.jpg',
                'https://leila-bologna.it/wp-content/uploads/2022/12/leila-bologna-barca-59-officina.jpg'
            ]"
            items-per-view="4"
            navigation
            pagination>
                <template #item="{ item }">
                    <bxs-figure
                    :src="item"
                    ratio="3-2" />
                </template>
            </bxs-carousel>
        </section>

        <!-- ------------------------------------------------------------------------------------------------------------------
        ------------------------------------------------------------------------------------------------------------------ -->
        <section>
            <bxs-layout>
                <div>
                    <p>Barca59 è una Impresa Sociale Cooperativa radicata nella zona Barca, nel Quartiere Borgo Panigale – Reno, composta attualmente da 6 soci con professionalità diverse che spaziano dalla progettazione sociale all’educazione e formazione, il contrasto alla povertà educativa e genitoriale, passione per il fundraising e il marketing, e il supporto legale.</p>
                    <p>Lavoriamo per il contrasto alle fragilità sociali emergenti e le nuove povertà, sviluppando progettualità integrate che mettano insieme cittadinanza, privato sociale e istituzioni.</p>
                </div>

                <div>
                    <p>La Biblioteca degli Oggetti alla Barca, è frutto di un percorso partecipativo e di co-progettazione realizzato da Barca 59 e Leila Bologna, facilitato dalla Fondazione per l’Innovazione Urbana, nel quadro delle azioni finanziate dal Bilancio Partecipativo 2019-2020 dal Comune di Bologna – Quartiere Borgo Panigale Reno. Il percorso partecipativo ha riunito cittadine e cittadini, associazioni e istituzioni del Quartiere che hanno lavorato alla identificazione di nuovi possibili servizi di prossimità che potessero nel contempo favorire l’aggregazione in maniera orizzontale.</p>
                    <p>È nata così la collaborazione con Leila Bologna, che ha dato la sua disponibilità ad allestire presso i locali dell’officina dismessa della Casa di Quartiere Rosa Marchi una nuova Biblioteca degli Oggetti, la più decentrata rispetto alla rete delle biblioteche degli oggetti della città di Bologna.</p>
                    <p>La Biblioteca degli Oggetti alla Barca raccoglie una doppia sfida, quella di rigenerare uno spazio in disuso all’interno di un Centro Sociale Autogestito dagli Anziani mantenendo viva l’identità di officina e integrare in questo spazio “rigenerato” un nuovo servizio di condivisione, che non si sostituisce al pre-esistente ma lo rivitalizza, valorizzando la storia e contribuendo al percorso di costruzione delle Case di Quartiere a partire dal l’importantissima esperienza di autogestione dei Centri Anziani.</p>
                    <p>Inaugurata il 14 Giugno 2022, conta già numerosi oggetti in condivisione e nuovi soci e socie che hanno deciso di abbracciare la filosofia di Leila Bologna per una cultura della condivisione e dell’uso condiviso, contro quella del possesso.</p>
                </div>
            </bxs-layout>
        </section>
    </div>
</template>

<script>
export default {
    name: 'collaborazioni_barca_59'
}
</script>