<template>
    <router-link
    v-if="!href"
    :to="to"
    :exact="exact"
    :class="['bxs-link', {
        'bxs-link-block': block || !!$slots.default,
        'bxs-link-actived': actived
    }]">
        <span class="bxs-link--container">
            <span class="bxs-link--label">
                <span v-if="!$slots.activator">{{ label }}</span>
                <slot name="activator" />
            </span>

            <span
            v-if="!hideArrow"
            class="bxs-link--arrow">
                <span>{{ arrow }}</span>
                <span>{{ arrow }}</span>
                <!-- <bxs-icon name="arrow-down-right"></bxs-icon>
                <bxs-icon name="arrow-down-right"></bxs-icon> -->
            </span>
        </span>

        <slot />
    </router-link>

    <a
    v-else
    :href="href"
    :exact="exact"
    :class="['bxs-link', {
        'bxs-link-block': block || $slots.default,
        'bxs-link-actived': actived
    }]">
        <span class="bxs-link--container">
            <span class="bxs-link--label">
                {{ label }}
                <slot name="activator" />
            </span>

            <span
            v-if="!hideArrow"
            class="bxs-link--arrow">
                <span>{{ arrow }}</span>
                <span>{{ arrow }}</span>
                <!-- <bxs-icon name="arrow-down-right"></bxs-icon>
                <bxs-icon name="arrow-down-right"></bxs-icon> -->
            </span>
        </span>

        <slot />
    </a>
</template>

<script>
export default {
    name: 'bxs_link',
    props: {
        to: [String, Object],
        href: String,
        label: String,
        'hide-arrow': Boolean,
        block: Boolean,
        exact: Boolean,
        arrow: {
            type: String,
            required: false,
            default: '→'
        },
        actived: Boolean
    },
    mounted () {
        this.$nextTick(this.start)
    },
    methods: {
        start () {
        }
    }
}
</script>

<style lang="scss" scoped>
.bxs-link {
    font-family: inherit;
    font-size: inherit;
    text-decoration: none;
    color: inherit;
    display: inline-block;

    &.router-link-active {
        color: var(--color-primary);
        .bxs-link--label {
            &::after {
                transform: scaleX(1);
                // transform-origin: left bottom;
            }
        }
        .bxs-link--arrow {
            span {
                transition-delay: 0.15s;
                transform: translateX(200%);

                &:last-child {
                    transform: translateX(0%);
                }
            }
        }
    }

    &.bxs-link-actived {
        .bxs-link--label {
            &::after {
                transform: scaleX(1);
                // transform-origin: left bottom;
            }
        }

        @media (hover: hover) and (pointer: fine) {
            &:hover {
                .bxs-link--label {
                    text-decoration: underline;

                        &::after {
                            transform-origin: right bottom;
                            transform: scaleX(0);
                        }
                    }
            }
        }
    }

    &.bxs-link-block {
        display: block;
        flex: 1;

        .bxs-link--container {
            display: flex;
            flex: 1;
            justify-content: space-between;
        }
    }

    &--container {
        display: inline-flex;
        flex-flow: row nowrap;
        align-items: center;
        text-decoration: none;
    }

    &--label {
        display: inline-block;
        position: relative;
        transition: color 0.1s ease-out;
        z-index: 1;

        &::after {
            content: "";
            position: absolute;
            bottom: -1px;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: var(--color-secondary);
            transform-origin: left bottom;
            transform: scaleX(0);
            transition: transform 0.2s ease-out;
            z-index: -1;
        }
    }

    &--arrow {
        position: relative;
        display: inline-flex;
        flex-flow: row nowrap;
        justify-content: center;
        align-items: center;
        vertical-align: middle;

        width: 1em;
        height: 1em;
        font-size: 1.1rem;
        font-weight: var(--typo-weight-action);
        line-height: 100%;
        margin-left: 0.75rem;
        margin-bottom: -1px;
        // padding: 20px;
        overflow: hidden;
        // border: 1px solid red;

        span {
            position: absolute;
            transition: transform 0.15s ease-out;
            transition-delay: 0;

            &:last-child {
                transform: translateX(-200%);
            }
        }
    }

    @media (hover: hover) and (pointer: fine) {
        &:hover {
            color: var(--color-primary);

            .bxs-link--label {
                &::after {
                    transform: scaleX(1);
                    // transform-origin: left bottom;
                }
            }
            .bxs-link--arrow {
                span {
                    transition-delay: 0.15s;
                    transform: translateX(200%);

                    &:last-child {
                        transform: translateX(0%);
                    }
                }
            }
        }
    }
}
</style>