<template>
    <div class="dicono-di-noi-view">
        <bxs-hero
        title="Dicono di noi - Archivio"
        src="https://leila-bologna.it/wp-content/uploads/2023/09/photo_2023-05-31_10-35-29.jpg"></bxs-hero>

        <section>
            <bxs-layout>
                <div class="mb-ui">
                    <h4>Scopri cosa dicono di noi attraverso podcast, interviste, servizi televisivi e molto altro</h4>

                    <div>
                        <bxs-chip
                        class="mr-1 mb-1"
                        :outlined="$route.query.year !== '2023'"
                        @click="$router.push({ query: {
                            year: '2023'
                        } })">2023</bxs-chip>

                        <bxs-chip
                        class="mr-1 mb-1"
                        :outlined="$route.query.year !== '2022'"
                        @click="$router.push({ query: {
                            year: '2022'
                        } })">2022</bxs-chip>

                        <bxs-chip
                        :outlined="$route.query.year !== '2021'"
                        class="mr-1 mb-1"
                        @click="$router.push({ query: {
                            year: '2021'
                        } })">2021</bxs-chip>

                        <bxs-chip
                        :outlined="$route.query.year !== '2020'"
                        class="mr-1 mb-1"
                        @click="$router.push({ query: {
                            year: '2020'
                        } })">2020</bxs-chip>

                        <bxs-chip
                        v-if="$route.query.year"
                        outlined
                        class="mr-1 mb-1"
                        @click="$router.push({ query: {} })">x</bxs-chip>
                    </div>
                </div>

                <ul>
                    <li
                    v-for="(item, i) in filtered_items"
                    :key="i">
                        <leila-list-item
                        :title="item.title"
                        :subtitle="item.subtitle"
                        :href="item.href.toLowerCase()"
                        icon="arrow-top-right" />
                    </li>
                </ul>
            </bxs-layout>
        </section>

        <!-- ------------------------------------------------------------------------------------------------------------------
            chi siamo
        ------------------------------------------------------------------------------------------------------------------ -->
        <section-about />

        <!-- ------------------------------------------------------------------------------------------------------------------
            come funziona
        ------------------------------------------------------------------------------------------------------------------ -->
        <section-come-funziona />

        <!-- ------------------------------------------------------------------------------------------------------------------
            tessera
        ------------------------------------------------------------------------------------------------------------------ -->
        <section-tessera />
    </div>
</template>

<script>
export default {
    name: 'dicono_di_noi_view',
    data () {
        return {
            items: [
                {
                    title: '28.06.2023',
                    subtitle: 'INTERNAZIONALE KIDS',
                    href: 'HTTPS://WWW.INTERNAZIONALE.IT/OPINIONE/MARTINA-RECCHIUTI/2023/06/28/RETI'
                },
                {
                    title: '25.04.2023',
                    subtitle: 'ECONOMIACIRCOLARE.COM - LEILA OGGETOTECA DI BOLOGNA COME FUNZIONA E PERCHÉ È DA PRENDERE AD ESEMPIO',
                    href: 'HTTPS://ECONOMIACIRCOLARE.COM/LEILA-OGGETTOTECA-DI-BOLOGNA-COME-FUNZIONA/'
                },
                {
                    title: '04.04.2023',
                    subtitle: 'ADMAIORA - CONDIVIDERE OGGETTI E SAPERI: LEILA LA BIBLIOTECA DEGLI OGGETTI - EPISODIO 8',
                    href: 'HTTPS://YOUTU.BE/6AOD-UC00_0'
                },
                {
                    title: '04.03.2023',
                    subtitle: 'RAI 1 - LINEA VERDE LIFE - BOLOGNA',
                    href: 'HTTPS://WWW.RAIPLAY.IT/VIDEO/2023/03/LINEA-VERDE-LIFE---BOLOGNA---04032023-ABCF0E8D-2D35-4E3B-A633-247B18676B37.HTML?FBCLID=IWAR0AA3RBA5N3MON1CXBCDDNVGZOIECFXLLAWPSFPLG7DZ4HSUXZZRPRUKP0'
                },
                {
                    title: '19.11.2022',
                    subtitle: 'RADIO 1 MARY POP - TENDENZE: ECONOMIA CIRCOLARE E NUOVE ATTITUDINI AI CONSUMI',
                    href: 'HTTPS://WWW.RAIPLAYSOUND.IT/AUDIO/2022/11/MARY-POP-DEL-19112022-837377CE-5BFA-449A-91D2-E60EF6293DDA.HTML'
                },
                {
                    title: '19.11.2022',
                    subtitle: 'Y-ANG NETWORK - ARCHITETTURA SOSTENIBILE E BIBLIOTECA DEGLI OGGETTI',
                    href: 'HTTPS://WWW.SPREAKER.COM/USER/16806366/ROLE-MODEL-ZANIN-LEILA'
                },
                {
                    title: '07.10.2022',
                    subtitle: 'IO DONNA - BIBLIOTECHE DELLE COSE: IL FRULLATORE LO PRENDO IN PRESTITO',
                    href: 'HTTPS://WWW.IODONNA.IT/ATTUALITA/COSTUME-E-SOCIETA/2022/10/07/IL-FRULLATORE-LO-PRENDO-IN-BIBLIOTECA/'
                },
                {
                    title: '18.09.2022',
                    subtitle: 'CANTIERE BOLOGNA - LE BIBLIOTECHE DEGLI OGGETTI E LA CRESCITA QUALITATIVA',
                    href: 'HTTPS://CANTIEREBOLOGNA.COM/2022/09/18/13317/'
                },
                {
                    title: '04.07.2022',
                    subtitle: 'THE MAP REPORT - SHARING ECONOMY: SI FANNO LARGO LE TOOL LIBRARY, PICCOLE “BIBLIOTECHE DEGLI OGGETTI”',
                    href: 'HTTPS://WWW.THEMAPREPORT.COM/2022/07/04/SHARING-ECONOMY-SI-FANNO-LARGO-LE-TOOL-LIBRARY-PICCOLE-BIBLIOTECHE-DEGLI-OGGETTI/'
                },
                {
                    title: '21.06.2022',
                    subtitle: 'POST DI WILL_ITA SU INSTAGRAM',
                    href: 'HTTPS://WWW.INSTAGRAM.COM/P/CFDNTL9TLL7/'
                },
                {
                    title: '28.06.2022',
                    subtitle: 'COME SI RIDUCONO I RIFIUTI CHE PRODUCIAMO? ASCOLTA LA NUOVA PUNTATA DEL PODCAST',
                    href: 'HTTPS://WWW.CHIARA.ECO/PODCAST-RIFIUTI/'
                },
                {
                    title: '28.06.2022',
                    subtitle: 'SPREAKER: EP. 34 - LEILA BOLOGNA: LA BIBLIOTECA DELLE COSE',
                    href: 'HTTPS://WWW.SPREAKER.COM/USER/DAVIDE.FRANZAGO/LEILA-BOLOGNA-BIBLIOTECA-COSE'
                },
                {
                    title: '02.05.2022',
                    subtitle: 'ECOBIONEWS: "LEILA" LA BIBLIOTECA DEGLI OGGETTI A BOLOGNA: NON SOLO LIBRI MA SOPRATTUTTO CONDIVISIONI IN "PRESTITO"',
                    href: 'HTTPS://WWW.ECOBIONEWS.EU/2022/05/02/LEILA-LA-BIBLIOTECA-DEGLI-OGGETTI-A-BOLOGNA-NON-SOLO-LIBRI-MA-SOPRATTUTTO-CONDIVISIONI-IN-PRESTITO/'
                },
                {
                    title: '16.04.2022',
                    subtitle: 'LA REPUBBLICA: NON PIÙ SOLO LIBRI: ORA IN BIBLIOTECA SI PRENDONO IN PRESTITO ANCHE OGGETTI ED ESPERIENZE',
                    href: 'HTTPS://WWW.REPUBBLICA.IT/GREEN-AND-BLUE/2022/04/16/NEWS/BIBLIOTECA_PRESTITO_OGGETTI_LEILA_BOLOGNA-345509759/'
                },
                {
                    title: '28.03.2022',
                    subtitle: 'RADIO CITTÀ FUJIKO: LA BIBLIOTECA DEGLI OGGETTI LEILA ARRIVA IN SALABORSA',
                    href: 'HTTPS://WWW.RADIOCITTAFUJIKO.IT/LA-BIBLIOTECA-DEGLI-OGGETTI-LEILA-ARRIVA-IN-SALABORSA/'
                },
                {
                    title: '27.03.2022',
                    subtitle: 'È TV RETE 7: NON SOLO LIBRI: IN SALABORSA ORA SI PRENDONO IN PRESTITO ANCHE GLI OGGETTI',
                    href: 'HTTPS://E-TV.IT/2022/03/27/NON-SOLO-LIBRI-IN-SALABORSA-ORA-SI-PRENDONO-IN-PRESTITO-ANCHE-GLI-OGGETTI/'
                },
                {
                    title: '24.03.2022',
                    subtitle: 'VOICE BOOK RADIO: NON SOLO LIBRI IN PRESTITO ALLA BIBLIOTECA DI BOLOGNA',
                    href: 'HTTPS://WWW.VOICEBOOKRADIO.COM/NON-SOLO-LIBRI-IN-PRESTITO-ALLA-BIBLIOTECA-DI-BOLOGNA/'
                },
                {
                    title: '23.03.2022',
                    subtitle: 'EMILIAROMAGNANEWS24.IT: LEILA E SALABORSA UNISCONO LE LORO FORZE PER UN FUTURO SEMPRE PIÙ SOSTENIBILE',
                    href: 'HTTPS://WWW.EMILIAROMAGNANEWS24.IT/LEILA-E-SALABORSA-UNISCONO-LE-LORO-FORZE-PER-UN-FUTURO-SEMPRE-PIU-SOSTENIBILE-230491.HTML'
                },
                {
                    title: '21.03.2022',
                    subtitle: 'SULPANARO.NET: GIOCATTOLI, CATENE D’AUTO, MESTOLI: IN BIBLIOTECA ORA SI PRENDONO IN PRESTITO ANCHE GLI OGGETTI',
                    href: 'HTTPS://WWW.SULPANARO.NET/2022/03/GIOCATTOLI-CATENE-DAUTO-MESTOLI-IN-BIBLIOTECA-ORA-SI-PRENDONO-IN-PRESTITO-ANCHE-GLI-OGGETTI/'
                },
                {
                    title: '21.03.2022',
                    subtitle: 'BOLOGNADAVIVERE.COM: LEILA E SALABORSA UNISCONO LE LORO FORZE PER UN FUTURO SEMPRE PIÙ SOSTENIBILE',
                    href: 'HTTPS://WWW.BOLOGNADAVIVERE.COM/2022/03/LEILA-E-SALABORSA-UNISCONO-LE-LORO-FORZE-PER-UN-FUTURO-SEMPRE-PIU-SOSTENIBILE/'
                },
                {
                    title: '20.03.2022',
                    subtitle: 'TUTTOPERLEI: BOLOGNA, IN BIBLIOTECA SI PRESTANO ANCHE GLI OGGETTI',
                    href: 'HTTPS://WWW.TUTTOPERLEI.IT/2022/03/20/BOLOGNA-IN-BIBLIOTECA-SI-PRESTANO-ANCHE-GLI-OGGETTI/'
                },
                {
                    title: '19.03.2022',
                    subtitle: 'BOLOGNA24ORE.IT: BOLOGNA, LEILA E SALABORSA SI UNISCONO PER UN FUTURO PIÙ SOSTENIBILE',
                    href: 'HTTPS://WWW.BOLOGNA24ORE.IT/NOTIZIE/SOCIETA/2022/03/19/BOLOGNA-LEILA-E-SALABORSA-SI-UNISCONO-PER-UN-FUTURO-PIU-SOSTENIBILE/'
                },
                {
                    title: '18.03.2022',
                    subtitle: 'BIBLIOTECHE BOLOGNA: LEILA E SALABORSA PER UN FUTURO SEMPRE PIÙ SOSTENIBILE',
                    href: 'HTTPS://WWW.BIBLIOTECHEBOLOGNA.IT/DOCUMENTS/LEILA-E-SALABORSA-PER-UN-FUTURO-SEMPRE-PIU-SOSTENIBILE'
                },
                {
                    title: '18.03.2022',
                    subtitle: 'SKY TG24: A BOLOGNA IN BIBLIOTECA SI PRESTANO ANCHE GLI OGGETTI',
                    href: 'HTTPS://TG24.SKY.IT/BOLOGNA/2022/03/18/A-BOLOGNA-IN-BIBLIOTECA-SI-PRESTANO-ANCHE-GLI-OGGETTI'
                },
                {
                    title: '18.03.2022',
                    subtitle: 'MSN: BOLOGNA, IN SALABORSA SI PRESTANO ANCHE GLI OGGETTI',
                    href: 'HTTPS://WWW.MSN.COM/IT-IT/NOTIZIE/BOLOGNA/BOLOGNA-IN-SALABORSA-SI-PRESTANO-ANCHE-GLI-OGGETTI/AR-AAVF6SK'
                },
                {
                    title: '18.03.2022',
                    subtitle: 'ANSA: A BOLOGNA IN BIBLIOTECA SI PRESTANO ANCHE GLI OGGETTI',
                    href: 'HTTPS://WWW.ANSA.IT/EMILIAROMAGNA/NOTIZIE/2022/03/18/A-BOLOGNA-IN-BIBLIOTECA-SI-PRESTANO-ANCHE-GLI-OGGETTI_67F14903-2D51-4394-9609-1F1E8C8B3D46.HTML'
                },
                {
                    title: '18.03.2022',
                    subtitle: 'FONDAZIONE DEL MONTE: IL PROGETTO LEILA BOLOGNA: LA BIBLIOTECA DEGLI OGGETTI',
                    href: 'HTTPS://WWW.FONDAZIONEDELMONTE.IT/IL-PROGETTO-LEILA-BOLOGNA-LA-BIBLIOTECA-DEGLI-OGGETTI/'
                },
                {
                    title: '18.03.2022',
                    subtitle: 'IL RESTO DEL CARLINO: BOLOGNA, IN SALABORSA SI PRESTANO ANCHE GLI OGGETTI',
                    href: 'HTTPS://WWW.ILRESTODELCARLINO.IT/BOLOGNA/CRONACA/SALABORSA-PRESTITO-OGGETTI-1.7478563'
                },
                {
                    title: '18.03.2022',
                    subtitle: 'ZERO BOLOGNA: LA BIBLIOTECA DEGLI OGGETTI LEILA ARRIVA ANCHE IN SALABORSA',
                    href: 'HTTPS://ZERO.EU/IT/NEWS/LA-BIBLIOTECA-DEGLI-OGGETTI-LEILA-ARRIVA-ANCHE-IN-SALABORSA/'
                },
                {
                    title: '09.02.2022',
                    subtitle: 'FLASHGIOVANI.IT: LEILA BOLOGNA - LA BIBLIOTECA DEGLI OGGETTI',
                    href: 'HTTP://WWW.FLASHGIOVANI.IT/INSIDEBO-LEILA-BOLOGNA'
                },
                {
                    title: '23.01.2022',
                    subtitle: 'IL FATTO QUOTIDIANO: LEILA, LA BIBLIOTECA DEGLI OGGETTI CHE A BOLOGNA UNISCE RECUPERO E CONDIVISIONE',
                    href: 'HTTPS://WWW.ILFATTOQUOTIDIANO.IT/2022/01/23/LEILA-LA-BIBLIOTECA-DEGLI-OGGETTI-CHE-A-BOLOGNA-UNISCE-RECUPERO-E-CONDIVISIONE/6448209/'
                },
                {
                    title: '25.12.2021',
                    subtitle: 'INSIDER DENTRO LA TECNOLOGIA: RIUTILIZZO, CONDIVISIONE E REGALI DI NATALE',
                    href: 'HTTPS://WWW.DENTROLATECNOLOGIA.IT/S3E52'
                },
                {
                    title: '15.03.2021',
                    subtitle: 'RADIO CITTÀ FUJIKO - LEILA BOLOGNA: IN BOLOGNINA LA PRIMA BIBLIOTECA DEGLI OGGETTI',
                    href: 'HTTPS://WWW.RADIOCITTAFUJIKO.IT/LEILA-BOLOGNA-IN-BOLOGNINA-LA-PRIMA-BIBLIOTECA-DEGLI-OGGETTI'
                },
                {
                    title: '10.03.2021',
                    subtitle: 'ZERO BOLOGNA - IN BOLOGNINA LA PRIMA BIBLIOTECA ITALIANA DEGLI OGGETTI: ANTONIO BERALDI RACCONTA LEILA',
                    href: 'HTTPS://ZERO.EU/IT/PERSONE/ANTONIO-BERALDI-LEILA/'
                },
                {
                    title: '21.10.2020',
                    subtitle: 'ABOUTBOLOGNA - ABBIAMO BISOGNO DI UTILIZZARE, NON DI POSSEDERE. LEILA, LA BIBLIOTECA DEGLI OGGETTI',
                    href: 'HTTPS://ABOUTBOLOGNA.IT/LEILA-BIBLIOTECA-OGGETTI-BOLOGNA-UTILIZZARE-NON-POSSEDERE/'
                },
                {
                    title: '02.10.2020',
                    subtitle: 'CHRISTIANIA BIKES® UK - MOBILE TOOL LIBRARY IN BOLOGNA',
                    href: 'HTTPS://CHRISTIANIABIKESUK.COM/2020/10/02/MOBILE-TOOL-LIBRARY-IN-BOLOGNA/'
                },
                {
                    title: '28.09.2020',
                    subtitle: 'ITALIA CHE CAMBIA - LEILA, LA BIBLIOTECA DEGLI OGGETTI APRE ALLA CONDIVISIONE DEI SAPERI',
                    href: 'HTTPS://WWW.ITALIACHECAMBIA.ORG/2020/09/LEILA-BIBLIOTECA-DEGLI-OGGETTI-CONDIVISIONE-SAPERI/'
                },
                {
                    title: '26.09.2020',
                    subtitle: 'LA REPUBBLICA BOLOGNA - A BOLOGNA LA BIBLIOTECA DEGLI OGGETTI DA PRENDERE IN PRESTITO',
                    href: 'HTTPS://BOLOGNA.REPUBBLICA.IT/CRONACA/2020/09/26/NEWS/A_BOLOGNA_LA_BIBLIOTECA_DEGLI_OGGEWTTI_DA_PRENDERE_IN_PRESTITO-268556567/'
                },
                {
                    title: '07.09.2020',
                    subtitle: 'RSI NEWS - LA BIBLIOTECA DEGLI OGGETTI',
                    href: 'HTTPS://WWW.RSI.CH/PLAY/TV/RSI-NEWS/VIDEO/LA-BIBLIOTECA-DEGLI-OGGETTI?URN=URN:RSI:VIDEO:13354641'
                },
                {
                    title: '05.07.2020',
                    subtitle: 'SKY VIDEO - BOLOGNA, SUCCESSO PER LEILA LA BIBLIOTECA DEGLI OGGETTI',
                    href: 'HTTPS://VIDEO.SKY.IT/NEWS/CRONACA/VIDEO/BOLOGNA-SUCCESSO-PER-LEILA-LA-BIBLIOTECA-DEGLI-OGGETTI-601354'
                },
                {
                    title: '15.03.2020',
                    subtitle: 'BERNARDO CUMBO - LEILA, LA BIBLIOTECA DEGLI OGGETTI',
                    href: 'HTTPS://WWW.YOUTUBE.COM/WATCH?V=SJM2MCWMRBQ'
                }
            ]
        }
    },
    computed: {
        filtered_items () {
            if (!this.$route.query.year) {
                return this.items
            }

            return this.items.filter((item) => {
                const year = item.title.split('.')[2]
                return year === this.$route.query.year
            })
        }
    },
    watch: {
        $route () {
            this.start()
        }
    },
    methods: {
        start () {
            this.$nextTick(() => {
                window.scrollTo(0, 0)
            })
        }
    }
}
</script>