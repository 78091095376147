<template>
    <div class="crea-una-leila-anche-tu-view">
        <bxs-hero
        title="Crea una Leila anche tu"
        src="https://leila-bologna.it/wp-content/uploads/2022/12/leila-bologna-crea-una-leila-anche-tu.jpg" />

        <!-- ------------------------------------------------------------------------------------------------------------------
        ------------------------------------------------------------------------------------------------------------------ -->
        <section>
            <bxs-layout>
                <h3>Ti aiutamo ad aprire la tua biblioteca degli oggetti</h3>
                <h4>La nostra esperienza a tua disposizione</h4>
                <p>Persona fisica, associazione, azienda o ente pubblico, con il nostro aiuto, chiunque può aprire una biblioteca degli oggetti.</p>
            </bxs-layout>
        </section>

        <!-- ------------------------------------------------------------------------------------------------------------------
        ------------------------------------------------------------------------------------------------------------------ -->
        <section>
            <bxs-layout>
                <h4>Forniamo consulenze, formazioni, servizi e prodotti IT su misura per le tue esigenze</h4>

                <bxs-row align="center">
                    <bxs-col
                    cols="12"
                    ds="4">
                        <bxs-card height="200">
                            <h5>Gestionale e Web app</h5>
                            <p>Affidabile, semplice, funzionale.</p>

                            <div class="text-right">
                                <bxs-btn
                                href="https://www.facebook.com/leila.bologna.la.biblioteca.degli.oggetti"
                                text
                                target="_blank">Vai la web app</bxs-btn>
                            </div>
                        </bxs-card>
                    </bxs-col>

                    <bxs-col
                    cols="12"
                    ds="4">
                        <bxs-card height="200">
                            <h5>Know-How</h5>
                            <p>Esperienza, formazione, visione</p>
                        </bxs-card>
                    </bxs-col>

                    <bxs-col
                    cols="12"
                    ds="4">
                        <bxs-card height="200">
                            <h5>Network</h5>
                            <p>La rete nazionale delle Biblioteche degli Oggetti</p>
                        </bxs-card>
                    </bxs-col>
                </bxs-row>
            </bxs-layout>
        </section>

        <!-- ------------------------------------------------------------------------------------------------------------------
        ------------------------------------------------------------------------------------------------------------------ -->
        <section>
            <bxs-layout>
                <h5>Seleziona la tua categoria e compila il form</h5>
                <form-crea-leila />
            </bxs-layout>
        </section>

        <!-- ------------------------------------------------------------------------------------------------------------------
        ------------------------------------------------------------------------------------------------------------------ -->
        <section>
            <bxs-layout>
                <h4>FAQ - FREQUENTLY ASKED QUESTIONS</h4>

                <bxs-collapse-item label="Come posso aprire una biblioteca degli oggetti nella mia città?">
                    <p>Questo è un progetto che va declinato e modellato sulle caratteristiche del luogo in cui si vuole farlo nascere. Oggi si contano quasi una trentina di biblioteche degli oggetti in Europa e 90 tra Nord America, Europa e Australia. Tutte hanno caratteristiche simili ma anche molte differenze, proprio per questo motivo. </p>

                    <div>
                        <h5>Il gruppo di lavoro</h5>
                        <p>si tratta di un progetto che necessita di diverse competenze e impegni.</p>
                    </div>

                    <div>
                        <h5>Il tempo</h5>
                        <p>La biblioteca degli oggetti è innanzitutto un servizio, che ha bisogno di cura e attenzione </p>
                    </div>

                    <div>
                        <h5>Lo spazio</h5>
                        <p>Un luogo dove poter depositare gli oggetti e interfacciarsi con le persone</p>
                    </div>
                </bxs-collapse-item>

                <bxs-collapse-item label="La biblioteca degli oggetti è sostenibile?">
                    <p>Sì. La maggior parte delle biblioteche degli oggetti esistenti sono a pagamento (ognuna a modo suo). Fattore che le rende assolutamente sostenibili. Per quanto ci riguarda, Leila Bologna è l’unica Biblioteca degli Oggetti al mondo basata sul principio della condivisione. Tutti gli oggetti disponibili al prestito sono di proprietà delle persone che usufruiscono del servizio, gratuitamente.  La sostenibilità di Leila Bologna proviene da diverse attività: partecipazione ai bandi, collaborazioni con enti pubblici e privati, organizzazione di eventi, laboratori e altri servizi</p>
                </bxs-collapse-item>

                <bxs-collapse-item label="Leila è un franchising?">
                    <p>No, Leila è un progetto open source. Qualunque sia il nome, l’aspetto e l’impostazione che vuoi dare alla tua Biblioteca degli Oggetti, noi possiamo aiutarti a realizzarla!</p>
                </bxs-collapse-item>
            </bxs-layout>
        </section>

        <!-- ------------------------------------------------------------------------------------------------------------------
        ------------------------------------------------------------------------------------------------------------------ -->
        <section>
            <bxs-layout>
                <p>Se vi interessa approfondire la proposta, contattateci per avere e per darci più informazioni, possiamo costruire un percorso modulare ritagliato sulle vostre esigenze ed avanzare una richiesta di contributo adeguata e consapevole.</p>
            </bxs-layout>
        </section>
    </div>
</template>

<script>
export default {
    name: 'voi_e_leila_crea_una_leila_anche_tu'
}
</script>