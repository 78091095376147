<template>
    <div class="error-view">
        <section>
            <bxs-layout>
                <div class="pa-ui text-center">
                    <h5 class="mb-3">Ops, error 404</h5>
                    <p>Page <span v-if="$router.redirectedFrom">{{ $router.redirectedFrom.fullPath }}</span> not found</p>
                </div>
            </bxs-layout>
        </section>
    </div>
</template>

<script>
export default {
    name: 'error'
}
</script>