<template>
  <div :class="['app', {
    'app-loading': in_transition || !preloaded,
    'no-evt': in_transition
  }]">
    <preloader
    v-if="!preloaded"
    ref="preloader"
    :resources="resources"
    @complete="preloadComplete" />

    <div
    v-else
    ref="wrapper"
    id="wrapper"
    class="app--wrapper">
      <navframe
      v-if="!$route.meta.is_funnell"
      ref="navframe"
      :actived="navframe.actived || menu.on"
      :on="menu.on"
      class="app--navframe"
      @click:menu="menu.on = !menu.on" />

      <!-- view -->
      <main
      ref="main"
      class="app--main">
        <router-view
        ref="view"
        v-slot="{ Component }">
          <transition
          @enter="enterPage"
          @leave="leavePage"
          mode="out-in"
          appear>
            <component
            v-if="preloaded"
            :is="Component"
            :key="$route.path"
            ref="page"
            class="app--page" />
          </transition>
        </router-view>
      </main>

      <!-- foot -->
      <bxs-footer
      v-if="!$route.meta.is_funnell"
      ref="footer"
      class="app--footer" />

      <!--  -->
      <coookies
      buttonText="Accept"
      storageName="https://leila-bologna.it:cookie:accepted">
        <template #default="{ accept }">
          <div class="flex-1">
            <div class="mb-8">
              <small class="text-600">Notice!</small>
            </div>

            <!--  -->
            <div class="mb-ui">
              <p
              style="font-family: sans-serif;"
              class="small">This website does not use profiling cookies. Instead, technical cookies, including third-party cookies, are used in order to improve the browsing experience. <br> By clicking on the "Accept" button, you will close the banner without giving consent to any cookies except those that are necessary.</p>
            </div>

            <!--  -->
            <div class="flex nowrap justify-end">
              <bxs-btn
              color="success"
              min-width="80"
              class="mr-2"
              @click="accept">Accept</bxs-btn>

              <bxs-drawer title="Cookie settings">
                <template #activator="{ on }">
                  <bxs-btn
                  v-on="on"
                  outlined
                  min-width="80">Settings</bxs-btn>
                </template>

                <div>
                  <div class="py-4">
                    <logo
                    max-width="120px"
                    class="mb-2" />

                    <div>
                      <small>This website does not use profiling cookies. Instead, technical cookies, including third-party cookies, are used in order to improve the browsing experience. <br> By clicking on the "Accept" button, you will close the banner without giving consent to any cookies except those that are necessary.</small>
                    </div>
                  </div>

                  <div class="bxs-divider"></div>

                  <div class="py-4">
                    <p class="text-ui text-500 text-disabled h6 mb-2">Strictly necessary cookies</p>
                    <p class="mb-4 text-disabled">Strictly necessary cookies should always be turned on in order to save your preferences for cookie settings.</p>

                    <bxs-switch-field
                    :model-value="true"
                    disabled
                    label="Actived"
                    class="mb-0" />
                  </div>

                  <div class="bxs-divider"></div>

                  <div class="py-4">
                    <p class="text-ui text-500 h6 text-disabled mb-2">Third-party cookies</p>
                    <p class="mb-4 text-disabled">Google Maps is a map display service operated by Google that allows this site to show the location of your business. This service is installed without the use of cookies.</p>

                    <bxs-switch-field
                    :model-value="true"
                    disabled
                    label="Actived"
                    class="mb-0" />
                  </div>

                  <div class="bxs-divider"></div>
                </div>

                <!--  -->
                <template #actions>
                  <div class="px-layout py-2 text-right">
                    <bxs-btn
                    light
                    color="success"
                    min-width="80"
                    @click="accept">Accept</bxs-btn>
                  </div>
                </template>
              </bxs-drawer>
            </div>
          </div>
        </template>
      </coookies>

      <!--  -->
      <!-- <bxs-modal
      v-model="modal"
      max-width="768">
        <template #header>
          <h6 class="mb-0 pr-ui">Costruisci il futuro di Leila assieme a noi</h6>
        </template>

        <div class="text-center py-4 pa-2">
          <h5 class="mb-2 text-disabled">la tua opinione è importante!</h5>
          <bxs-btn
          href="https://docs.google.com/forms/d/e/1FAIpQLSflEW6bunHAFI5g_PhOhNl0R2YEwa7RE_VnfwTeCDs5f9GoSA/viewform"
          append-icon="arrow-right"
          block>Compila il questionario</bxs-btn>

          <bxs-figure
          src="/img/modal-1.jpeg"
          class="mb-4" />
        </div>
      </bxs-modal> -->
    </div>

    <!-- <div class="debug-frameview">
      <div></div>
    </div> -->
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import { fixVh } from '@/assets/libs/utils'
// import FontFaceObserver from 'fontfaceobserver'
import transitions from '@/assets/libs/transitions'

import Cookie from '@/components/Cookie.vue'
import Preloader from '@/components/Preloader.vue'
import Navframe from '@/components/layout/Navbar.vue'
import Footer from '@/components/layout/Footer.vue'

export default {
  name: 'App',
  components: {
    preloader: Preloader,
    navframe: Navframe,
    'bxs-footer': Footer,
    coookies: Cookie
  },
  computed: {
    ...mapState({
      resources: state => state.resources,
      preloaded: state => state.preloaded,
      loading: state => state.loading,
      in_transition: state => state.in_transition
    }),
    ...mapGetters([
      'getCurrentPage'
    ])
  },
  data () {
    return {
      modal: true,
      navframe: {
        hidden: false,
        actived: true,
        height: 0
      },
      window: {
        height: 0
      },
      menu: {
        on: false
      }
    }
  },
  head () {
    const page = this.getCurrentPage()
    const has_dynamic_seo = !!page && !!page.seo
    // const has_robots = has_dynamic_seo && !!this.page.seo.data.robots
    const has_og_img = has_dynamic_seo && page.seo.data && page.seo.data.og_image && page.seo.data.og_image.length > 0

    return {
      title: has_dynamic_seo ? page.seo.data.title : '',
      meta: [
        { name: 'description', content: has_dynamic_seo ? page.seo.data.description : '' },
        { name: 'article:modified_time', content: has_dynamic_seo ? page.seo.data.article_modified_time : '' },
        // og
        { name: 'og:type', content: has_dynamic_seo ? page.seo.data.og_type : '' },
        { name: 'og:site_name', content: has_dynamic_seo ? page.seo.data.title : '' },
        { name: 'og:title', content: has_dynamic_seo ? page.seo.data.og_description : '' },
        { name: 'og:description', content: has_dynamic_seo ? page.seo.data.title : '' },
        { name: 'og:image', content: has_og_img ? page.seo.data.og_image[0].url : '' },
        { name: 'og:url', content: window.location.href },
        // twc
        { name: 'twitter:card', content: has_dynamic_seo ? page.seo.data.twitter_card : '' },
        { name: 'twitter:title', content: has_dynamic_seo ? page.seo.data.og_description : '' },
        { name: 'twitter:description', content: has_dynamic_seo ? page.seo.data.title : '' },
        { name: 'twitter:image', content: has_og_img ? page.seo.data.og_image[0].url : '' },
        // indexing
        { name: 'robots', content: has_dynamic_seo ? `${page.seo.data.robots.index},${page.seo.data.robots.follow}` : '' }
      ],
      script: [
        { type: 'application/ld+json', content: has_dynamic_seo ? JSON.stringify(page.seo.data.schema) : '' }
      ]
    }
  },
  created () {
    this.$router.beforeEach((to, from, next) => {
      console.log('int beforeEach')

      if (this.menu.on) {
        this.menu.on = false
        console.log('close big menu')
        console.log('\n')
        return this.$eventHub.$on('big-menu-trasition-complete', next)
      }

      console.log('\n')
      next()
    })
  },
  mounted () {
    this.$nextTick(this.start)
  },
  watch: {
    in_transition (newVal) {
      console.log('in_transition', newVal)

      if (newVal === true) {
        this.pause()
      } else {
        this.play()
      }
    },
    'menu.on' (newVal) {
      if (newVal === true) {
        this.pause()
      } else {
        this.play()
      }
    }
  },
  methods: {
    start () {
      console.log('start', this)
      console.log('preloading')

      this.resize()
      window.addEventListener('resize', this.resize)
      this.$refs.preloader.start()
    },
    preloadComplete () {
      console.log('preload complete')
      this.$store.commit('setAppPreloaded', true)
    },
    play () {
      this.$nextTick(() => {
        console.log('play')

        this.resize()

        const blanks = [...document.querySelectorAll(['a[target="_blank"]'])]
        blanks.forEach((el) => {
          if (!el.hasAttribute('rel')) el.setAttribute('rel', 'noopener')
        })
      })
    },
    pause () {
      console.log('pause')
    },
    // handlers
    resize () {
      console.log('resize')

      fixVh()
      this.$store.commit('resize')

      this.window.height = window.innerHeight
    },
    //
    async enterPage (el, next) {
      try {
        console.log('enterPage')
        console.log('\n')

        const els = [el]

        if (this.$refs.footer) {
          els.push(this.$refs.footer.$el)
        }

        await transitions.get('fallback')(els, 'in')

        // console.log('aa', this.$route)
        // if (this.$route.meta.is_page && this.$store.state.page_enter !== this.$store.state.page_leave) {
        //   await this.$store.dispatch('getPage', this.$route.path === '/' ? 'home' : this.$route.path)
        // }

        this.$store.commit('setAppIsInTransition', false)
        next()
      } catch (err) {
        console.log('enterPage err', err)
        this.$router.push('/404')
      }
    },
    leavePage (el, next) {
      console.log('leavePage')
      const els = [el]

      if (this.$refs.footer) {
        els.push(this.$refs.footer.$el)
      }

      transitions.get('fallback')(els, 'out').then(() => {
        next()
        // debugger
      })
    },
    enterBigMenu (el, next) {
      return transitions.get('big_menu')(el, 'in').then(() => {
        next()
      })
    },
    leaveBigMenu (el, next) {
      return transitions.get('big_menu')(el, 'out').then(() => {
        this.$eventHub.$emit('big-menu-trasition-complete')
        next()
      })
    }
  }
}
</script>

<style lang="scss">
@import '@/assets/styles/index.scss';
</style>