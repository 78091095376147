<template>
    <section
    id="section-voi-e-leila"
    class="mute">
        <bxs-layout>
            <div class="mb-ui">
                <p class="label">Voi e Leila</p>
                <h2>Unisciti a noi</h2>
                <p>Vuoi metterti in gioco e dare una mano? Desideri realizzare una Leila nella tua città? Hai un progetto che vorresti condividere? Leila c’è!</p>
            </div>
        </bxs-layout>

        <bxs-layout fluid>
            <bxs-row>
                <bxs-col
                cols="12"
                ml="6"
                ds="3">
                    <bxs-link
                    to="/voi-e-leila/crea-una-leila-anche-tu"
                    class="mb-ui">
                        <template #activator>
                            <h6 class="mb-0">Crea una Leila anche tu</h6>
                        </template>

                        <bxs-figure
                        src="https://leila-bologna.it/wp-content/uploads/2022/12/leila-bologna-crea-una-leila-anche-tu.jpg"
                        ratio="3-2"
                        class="mt-ui" />
                    </bxs-link>
                </bxs-col>

                <bxs-col
                cols="12"
                ml="6"
                ds="3">
                    <bxs-link
                    to="/voi-e-leila/volontariato"
                    class="mb-ui">
                        <template #activator>
                            <h6 class="mb-0">Volontariato</h6>
                        </template>

                        <bxs-figure
                        src="https://leila-bologna.it/wp-content/uploads/2022/12/leila-bologna-volontariato.jpg"
                        ratio="3-2"
                        class="mt-ui" />
                    </bxs-link>
                </bxs-col>

                <bxs-col
                cols="12"
                ml="6"
                ds="3">
                    <bxs-link
                    to="/voi-e-leila/proponi-il-tuo-progetto"
                    class="mb-ui">
                        <template #activator>
                            <h6 class="mb-0">Proponi il tuo progetto</h6>
                        </template>

                        <bxs-figure
                        src="https://leila-bologna.it/wp-content/uploads/2022/12/leila-bologna-proponi-il-tuo-progetto.jpg"
                        ratio="3-2"
                        class="mt-ui" />
                    </bxs-link>
                </bxs-col>

                <bxs-col
                cols="12"
                ml="6"
                ds="3">
                    <bxs-link
                    to="/voi-e-leila/tirocinio"
                    class="mb-ui">
                        <template #activator>
                            <h6 class="mb-0">Tirocinio</h6>
                        </template>

                        <bxs-figure
                        src="https://leila-bologna.it/wp-content/uploads/2022/12/leila-bologna-tirocinio.jpg"
                        ratio="3-2"
                        class="mt-ui" />
                    </bxs-link>
                </bxs-col>
            </bxs-row>
        </bxs-layout>
    </section>
</template>

<script>
export default {
    name: 'section_voi_e_leila'
}
</script>