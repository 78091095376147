<template>
    <section
    v-if="items && items.docs && items.docs.length > 0"
    id="latest-news">
        <bxs-layout>
            <div class="mb-ui">
                <p class="label">News</p>
                <h2>Ultime news</h2>

                <bxs-link
                label="Scopri tutte le news"
                to="/news"></bxs-link>
            </div>
        </bxs-layout>

        <bxs-layout fluid>
            <bxs-carousel
            :items="items.docs"
            :breakpoints="{
                0: {
                    slidesPerView: 1
                },
                319: {
                    slidesPerView: 2
                },
                767: {
                    slidesPerView: 3
                },
                959: {
                    slidesPerView: 4
                },
                1280: {
                    slidesPerView: 5
                }
            }"
            :items-space-between="40"
            :pagination="{
                clickable: true
            }"
            navigation>
                <template #item="{ item }">
                    <lel-single-post-card :item="item" />
                </template>
            </bxs-carousel>
        </bxs-layout>
    </section>
</template>

<script>
import { mapState } from 'vuex'
import SinglePostCard from '@/components/project/SinglePostCard.vue'

export default {
    name: 'section_latest_news',
    components: {
        'lel-single-post-card': SinglePostCard
    },
    computed: {
        ...mapState({
            items: state => state.latest_news
        })
    }
}
</script>