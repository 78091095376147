<template>
    <div class="voi-e-leila-proponi-il-tuo-progetto-view">
        <bxs-hero title="Proponi il tuo progetto" />
        <section>
            <bxs-layout>
                <div class="mb-ui">
                    <p class="label">Il tuo progetto</p>
                    <h3>Proponi il tuo progetto</h3>
                </div>

                <bxs-row>
                    <bxs-col cols="12" ds="5">
                        <bxs-figure src="https://leila-bologna.it/wp-content/uploads/2022/12/leila-bologna-proponi-il-tuo-progetto-1024x683.jpg"></bxs-figure>
                    </bxs-col>
                    <bxs-col cols="12" ds="7">
                        <p>
                          Leila è un luogo di condivisione. E questo vale anche per <strong>te</strong>. <br>
                          <strong>Te</strong> che hai un progetto nel cassetto e non sai dove poterlo realizzare.<br>
                          <strong>Te</strong> che hai scoperto Leila e hai pensato ad un’attività perfetta per lei.<br>
                          <strong>Te</strong> che hai una passione da sempre e pensi sia arrivato il momento di condividerla.<br>
                          <strong>Te</strong> che che fai questo da una vita e potresti guadagnarci un sacco di soldi, ma Leila è una realtà troppo bella e faresti di tutto pur di collaborare con lei!
                        </p>
                        <p>
                          Te che non rientri in queste categorie, ma, in un modo o nell’altro, vuoi condividere il tuo progetto.
                        </p>
                        <p>
                          <strong>Sappi che Leila c’è!</strong>
                        </p>
                    </bxs-col>
                  </bxs-row>

                <bxs-row>
                    <bxs-col cols="12" ds="12">
                        <div class="text-center yellow-banner">COMPILA IL FORM PER INVIARE LA TUA PROPOSTA</div>
                        <small><i>In caso di mancanza di informazioni, compila comunque il form indicando le risposte approssimative.</i></small>
                    </bxs-col>
                </bxs-row>

                <form-proponi-progetto />
            </bxs-layout>
        </section>
    </div>
</template>

<script>
export default {
    name: 'voi_e_leila_proponi_il_tuo_progetto'
}
</script>
