<template>
    <div class="collaborazioni-view">
        <router-view />

        <bxs-hero
        src="/img/collaborazioni.jpg"
        title="Collaborazioni" />

        <section>
            <bxs-layout>
                <!--  -->
                <div class="mb-12">
                    <h3>Tutte le realtà con cui condividiamo progetti, obbiettivi e sogni</h3>
                </div>

                <bxs-row>
                    <bxs-col
                    cols="12"
                    ds="3">
                        <bxs-link
                        to="/collaborazioni/leila-nelle-biblioteche"
                        exact
                        class="mb-ui">
                            <template #activator>
                                <h6 class="mb-0">Biblioteche comunali Bologna</h6>
                            </template>

                            <bxs-figure
                            src="/img/logo-salaborsa-comune-bologna-colore.jpg"
                            ratio="1-1"
                            contain
                            class="mt-ui" />
                        </bxs-link>
                    </bxs-col>

                    <bxs-col
                    cols="12"
                    ds="3">
                        <bxs-link
                        to="/collaborazioni/backbo"
                        exact
                        class="mb-ui">
                            <template #activator>
                                <h6 class="mb-0">BackBo</h6>
                            </template>

                            <bxs-figure
                            src="/img/backbo-logo-orizzontale.png"
                            ratio="1-1"
                            contain
                            class="mt-ui mx-auto" />
                        </bxs-link>
                    </bxs-col>

                    <bxs-col
                    cols="12"
                    ds="3">
                        <bxs-link
                        to="/collaborazioni/opera-marella"
                        exact
                        class="mb-ui">
                            <template #activator>
                                <h6 class="mb-0">Opera Marella</h6>
                            </template>

                            <bxs-figure
                            src="https://leila-bologna.it/wp-content/uploads/2023/04/logo-opera-marella.png"
                            ratio="1-1"
                            contain
                            class="mt-ui" />
                        </bxs-link>
                    </bxs-col>

                    <bxs-col
                    cols="12"
                    ds="3">
                        <bxs-link
                        to="/collaborazioni/barca59"
                        exact
                        class="mb-ui">
                            <template #activator>
                                <h6 class="mb-0">Barca 59</h6>
                            </template>

                            <bxs-figure
                            src="https://leila-bologna.it/wp-content/uploads/2022/12/logo-barca-59.png"
                            ratio="1-1"
                            contain
                            class="mt-ui" />
                        </bxs-link>
                    </bxs-col>

                    <bxs-col
                    cols="12"
                    ds="3">
                        <bxs-link
                        to="/collaborazioni/blocco-comune"
                        exact
                        class="mb-ui">
                            <template #activator>
                                <h6 class="mb-0">Blocco comune</h6>
                            </template>

                            <bxs-figure
                            src="https://leila-bologna.it/wp-content/uploads/2022/11/LOGO_Ar.coWoodWorkshop_jpeg-1024x724.jpg"
                            ratio="1-1"
                            contain
                            class="mt-ui" />
                        </bxs-link>
                    </bxs-col>

                    <bxs-col
                    cols="12"
                    ds="3">
                        <bxs-link
                        to="/collaborazioni/il-biricoccolo"
                        exact
                        class="mb-ui">
                            <template #activator>
                                <h6 class="mb-0">Il Biricoccolo</h6>
                            </template>

                            <bxs-figure
                            src="https://leila-bologna.it/wp-content/uploads/2022/12/logo.jpg"
                            ratio="1-1"
                            contain
                            class="mt-ui" />
                        </bxs-link>
                    </bxs-col>

                    <bxs-col
                    cols="12"
                    ds="3">
                        <bxs-link
                        to="/collaborazioni/quattrocchi"
                        exact
                        class="mb-ui">
                            <template #activator>
                                <h6 class="mb-0">Quattrocchi</h6>
                            </template>

                            <bxs-figure
                            src="https://leila-bologna.it/wp-content/uploads/2022/11/Quattrocchi_Logo-1024x755.png"
                            ratio="1-1"
                            contain
                            class="mt-ui" />
                        </bxs-link>
                    </bxs-col>

                    <bxs-col
                    cols="12"
                    ds="3">
                        <bxs-link
                        to="/collaborazioni/le-gazze"
                        exact
                        class="mb-ui">
                            <template #activator>
                                <h6 class="mb-0">Le Gazze</h6>
                            </template>

                            <bxs-figure
                            src="https://leila-bologna.it/wp-content/uploads/2023/02/Le-Gazze.jpg"
                            ratio="1-1"
                            contain
                            class="mt-ui" />
                        </bxs-link>
                    </bxs-col>
                </bxs-row>
            </bxs-layout>
        </section>

        <!--  -->
        <section-about />

        <!--  -->
        <section-come-funziona class="mute" />

        <!--  -->
        <section-sedi />
    </div>
</template>

<script>
export default {
    name: 'collaborazioni'
}
</script>