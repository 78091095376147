<template>
    <div class="fare-condivisione-laboratorio-view">
        <bxs-hero
        title="Fare condivisione - Laboratorio"
        src="/img/leila-bologna-laboratorio.jpg" />

        <section>
            <bxs-layout>
                <p>Per utilizzare il laboratorio è necessario il tesseramento all’associazione.</p>
                <p>Può essere prenotato per: corsi teorici e/o pratici, lavori di ciappinaggio, attività individuali o di gruppo.</p>

                <bxs-figure src="/img/leila-bologna-laboratorio.jpg"></bxs-figure>
            </bxs-layout>
        </section>

        <section>
            <bxs-layout>
                <p class="label">Lavori di ciappinaggio</p>
                <p>Il contributo richiesto è di 10€ l’ora, ma può variare in base all’utilizzo degli elettroutensili. Al termine dell’utilizzo, il laboratorio deve essere lasciato in ordine e pulito. L’orario di accesso dipende dal tipo di utilizzo e dalle lavorazioni che si intendono effettuare al suo interno. Leila è inserita in un condominio ed è vincolata al rispetto del regolamento. Per prenotazioni e informazioni scrivere a: laboratorio@leila-bologna.it</p>
            </bxs-layout>
        </section>

        <section>
            <bxs-layout>
                <p class="label">Attività individuali o di gruppo</p>
                <p>Qualunque sia la tua necessità di utilizzo dello spazio Laboratorio, il team di Leila è pronto ad ascoltare e valutare se può esserti d’aiuto! Scrivi una mail a laboratorio@leila-bologna.it</p>
            </bxs-layout>
        </section>
    </div>
</template>

<script>
export default {
    name: 'fare_condivisione_laboratorio'
}
</script>