<template>
    <bxs-form
    ref="form"
    @submit="submit">
        <template #default>
            <bxs-text-field
            v-model="model.name"
            name="name"
            required
            label="Nome" />

            <bxs-text-field
            v-model="model.email"
            type="email"
            required
            :rules="['email']"
            name="email"
            label="Email" />

            <bxs-textarea-field
            v-model="model.message"
            required
            name="message"
            label="Il tuo messaggio" />

            <!-- --------------------------------------------------------------------------------------------------------
            -------------------------------------------------------------------------------------------------------- -->
            <bxs-text-field
            v-model="model.antispam"
            label="Quiz AntiSpam, quanto fa 7+4?"
            name="antispam"
            required />

            <!--  -->
            <div class="mb-ui">
                <bxs-btn
                :disabled="!is_submitable"
                :loading="loading"
                block
                @click="$refs.form.submit()">Invia</bxs-btn>
            </div>

            <!-- foot -->
            <bxs-checkbox
            v-model="model.privacy"
            id="privacy"
            name="privacy">
                <small>* Confermo di aver letto e accettato la <router-link to="/policies/privacy">privacy policy</router-link></small>
            </bxs-checkbox>

            <!-- error message -->
            <div
            v-if="error"
            class="flex nowrap align-start mt-ui text-error mt-ui">
                <div>
                    <small class="mb-0">Oops, qualcosa è andato storto.</small> <br>
                    <small>Al momento non è possible inviare questo modulo, contattaci per ulteriore supporto.</small>
                    <!-- {{ error.message }} -->
                </div>
            </div>

            <!-- response message -->
            <bxs-alert
            v-if="res"
            type="success"
            class="mt-ui">Grazie per il tuo invio. Ti ricontatteremo appena possibile.</bxs-alert>
        </template>
    </bxs-form>
</template>

<script>
// Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum
export default {
    name: 'form_contact',
    data () {
        return {
            key: 1,
            loading: false,
            error: null,
            res: null,
            model: {
                name: null,
                email: null,
                message: null,
                antispam: null,
                privacy: false
            }
        }
    },
    computed: {
        is_submitable () {
            const v = !this.loading && this.model.antispam === '11' && !!this.model.privacy
            return v
        }
    },
    methods: {
        async submit () {
            if (!this.$refs.form.valid) return

            this.loading = true
            this.error = null

            try {
                this.res = await this.$api.post('v1/forms/cargo-bike', this.model)

                this.model = {
                    name: null,
                    email: null,
                    message: null
                }

                this.key += 1
            } catch (err) {
                this.error = err
            } finally {
                this.loading = false
            }
        }
    }
}
</script>