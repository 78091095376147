<template>
    <div class="share-page">
        <div>
            <small class="text-disabled mb-0">Condividi</small>
        </div>

        <div class="flex wrap align-center">
            <button
            :disabled="is_link_copied"
            :class="['flex', 'align-center', 'pointer', 'mb-2', 'mr-6', {
                disabled: is_link_copied
            }]"
            @click="clipToBoard">
                <svg
                xmlns="http://www.w3.org/2000/svg"
                width="1em"
                height="1em"
                viewBox="0 0 16 16">
                    <path d="M4 1.5H3a2 2 0 0 0-2 2V14a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V3.5a2 2 0 0 0-2-2h-1v1h1a1 1 0 0 1 1 1V14a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V3.5a1 1 0 0 1 1-1h1v-1z"/>
                    <path d="M9.5 1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5h3zm-3-1A1.5 1.5 0 0 0 5 1.5v1A1.5 1.5 0 0 0 6.5 4h3A1.5 1.5 0 0 0 11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3z"/>
                </svg>

                <span class="ml-3">{{ is_link_copied ? 'link copiato' : 'Copa link' }}</span>
            </button>

            <button
            class="mb-2"
            @click="share('tw')">
                <svg
                xmlns="http://www.w3.org/2000/svg"
                width="1em"
                height="1em"
                viewBox="0 0 16 16"
                class="mr-6">
                    <path d="M5.026 15c6.038 0 9.341-5.003 9.341-9.334 0-.14 0-.282-.006-.422A6.685 6.685 0 0 0 16 3.542a6.658 6.658 0 0 1-1.889.518 3.301 3.301 0 0 0 1.447-1.817 6.533 6.533 0 0 1-2.087.793A3.286 3.286 0 0 0 7.875 6.03a9.325 9.325 0 0 1-6.767-3.429 3.289 3.289 0 0 0 1.018 4.382A3.323 3.323 0 0 1 .64 6.575v.045a3.288 3.288 0 0 0 2.632 3.218 3.203 3.203 0 0 1-.865.115 3.23 3.23 0 0 1-.614-.057 3.283 3.283 0 0 0 3.067 2.277A6.588 6.588 0 0 1 .78 13.58a6.32 6.32 0 0 1-.78-.045A9.344 9.344 0 0 0 5.026 15z"/>
                </svg>
            </button>

            <button
            class="mb-2"
            @click="share('fb')">
                <svg
                xmlns="http://www.w3.org/2000/svg"
                width="1em"
                height="1em"
                viewBox="0 0 16 16"
                class="mr-6">
                    <path d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951z"/>
                </svg>
            </button>

            <button
            class="mb-2"
            @click="share('in')">
                <svg
                xmlns="http://www.w3.org/2000/svg"
                width="1em"
                height="1em"
                viewBox="0 0 16 16">
                    <path d="M0 1.146C0 .513.526 0 1.175 0h13.65C15.474 0 16 .513 16 1.146v13.708c0 .633-.526 1.146-1.175 1.146H1.175C.526 16 0 15.487 0 14.854V1.146zm4.943 12.248V6.169H2.542v7.225h2.401zm-1.2-8.212c.837 0 1.358-.554 1.358-1.248-.015-.709-.52-1.248-1.342-1.248-.822 0-1.359.54-1.359 1.248 0 .694.521 1.248 1.327 1.248h.016zm4.908 8.212V9.359c0-.216.016-.432.08-.586.173-.431.568-.878 1.232-.878.869 0 1.216.662 1.216 1.634v3.865h2.401V9.25c0-2.22-1.184-3.252-2.764-3.252-1.274 0-1.845.7-2.165 1.193v.025h-.016a5.54 5.54 0 0 1 .016-.025V6.169h-2.4c.03.678 0 7.225 0 7.225h2.4z"/>
                </svg>
            </button>
        </div>
    </div>
</template>

<script>
export default {
    name: 'share_page',
    props: {
        item: {
            type: Object,
            required: true
        }
    },
    data () {
        return {
            is_link_copied: false
        }
    },
    methods: {
        clipToBoard () {
            console.log('clipToBoard')

            navigator.clipboard.writeText(this.article.permalink).then(() => {
                this.is_link_copied = true

                setTimeout(() => {
                    this.is_link_copied = false
                }, 2000)
            })
        },
        share (social) {
            const left = (screen.width - 570) / 2
            const top = (screen.height - 570) / 2
            const params = 'menubar=no,toolbar=no,status=no,width=570,height=570,top=' + top + ',left=' + left
            let url = null

            if (social === 'tw') {
                url = `https://twitter.com/share?url=${this.item.permalink}&text=${this.item.title}`
            } else if (social === 'fb') {
                url = `https://www.facebook.com/sharer/sharer.php?u=${this.item.permalink}`
            } else if (social === 'in') {
                url = `https://www.linkedin.com/shareArticle?mini=true&url=${this.item.permalink}`
            }

            console.log('share', url)

            // if (this.$store.state.is_touch_device) window.open(url, '_blank')
            window.open(url, 'TwitterWindow', params)
        }
    }
}
</script>

<style lang="scss" scoped>
.share-page {
    button {
        font-size: 0.85em;
    }
}
</style>