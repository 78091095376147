<template>
    <div class="la-rete-view">
        <router-view />

        <bxs-hero
        src="https://leila-bologna.it/wp-content/uploads/2023/10/cartinasenzasfondo.png"
        ratio="21-6"
        mobile-ratio="2-3"
        scrolldown>
            <div class="flex column align-center justify-center text-center pa-ui fill-height light">
                <div>
                    <h1 class="text-500">La rete</h1>
                    <p>Leila Bologna fa parte della Rete Nazionale delle Biblioteche degli Oggetti.</p>
                </div>
            </div>
        </bxs-hero>

        <!-- ------------------------------------------------------------------------------------------------------------------
        ------------------------------------------------------------------------------------------------------------------ -->
        <section>
            <bxs-layout>
                <bxs-row>
                    <bxs-col cols="6">
                        <h3>La rete delle biblioteche degli oggetti</h3>
                        <p class="h4 line-height-ui">Leila Bologna fa parte della Rete Nazionale delle Biblioteche degli Oggetti.</p>
                        <p>Un progetto su scala nazionale che al momento è in fase di sperimentazione, ma che offre già diversi vantaggi a chi ne fa parte. Fra tutti, la possibilità di utilizzare un gestionale e una <bxs-link href="https://webapp.leila-bologna.it" label="webapp" hide-arrow /> (come quelli di Leila) che semplificano la vita a chi offre il servizio e rendono l’esperienza di prestito degli oggetti molto più semplice e veloce per gli utenti!</p>
                        <h5>Esplora di più e scropri i vantaggi!</h5>
                        <bxs-btn to="/voi-e-leila/crea-una-leila-anche-tu">Scopri come aprire una Leila anche tu</bxs-btn>
                    </bxs-col>

                    <bxs-col cols="6">
                        <bxs-figure src="https://leila-bologna.it/wp-content/uploads/2023/10/cartinasenzasfondo.png" />
                    </bxs-col>
                </bxs-row>

                <!-- <h3>La rete delle biblioteche degli oggetti</h3>
                <p class="h4">Leila Bologna fa parte della Rete Nazionale delle Biblioteche degli Oggetti.</p>
                <p>Un progetto su scala nazionale che al momento è in fase di sperimentazione, ma che offre già diversi vantaggi a chi ne fa parte. Fra tutti, la possibilità di utilizzare un gestionale e una <bxs-link href="https://webapp.leila-bologna.it" label="webapp" hide-arrow /> (come quelli di Leila) che semplificano la vita a chi offre il servizio e rendono l’esperienza di prestito degli oggetti molto più semplice e veloce per gli utenti!</p>
                <h5>Vuoi saperne di più e scoprire gli altri vantaggi? </h5>
                <bxs-btn to="/fare-condivisione/crea-una-leila-anche-tu">Scopri come aprire una Leila anche tu</bxs-btn> -->
            </bxs-layout>
        </section>

        <section>
            <!-- ------------------------------------------------------------------------------------------------------------------
            ------------------------------------------------------------------------------------------------------------------ -->
            <bxs-layout>
                <!--  -->
                <div class="mb-12">
                    <h3>Biblioteche nella rete</h3>
                </div>

                <bxs-row>
                    <bxs-col
                    cols="12"
                    ds="3">
                        <bxs-link
                        to="/la-rete/atpico"
                        class="mb-ui">
                            <template #activator>
                                <h6 class="mb-0">ATPiCO A.P.S.</h6>
                            </template>

                            <bxs-figure
                            src="/img/la-rete/atpico/logo-atpico.png"
                            ratio="1-1"
                            contain
                            class="mt-ui" />
                        </bxs-link>
                    </bxs-col>
                </bxs-row>
            </bxs-layout>
        </section>
    </div>
</template>

<script>
export default {
    name: 'la_rete'
}
</script>