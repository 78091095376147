<template>
    <div class="policies-privacy-view policy-view">
        <bxs-hero title="Privacy policy"></bxs-hero>

        <section>
            <bxs-layout max-width="1024">
                <div class="policies-privacy-view--section">
                    <div class="mb-ui small">
                        <p class="mb-0">Privacy Policy del sito web www.leila-bologna.it</p>
                        <p class="text-disabled">Ultimo aggiornamento: 01-12-2023</p>
                    </div>

                    <bxs-divider class="mb-ui"></bxs-divider>

                    <div>
                        <p>Benvenuto su www.leila-bologna.it. <br>  La tua privacy è importante per noi. Questa Privacy Policy spiega come raccogliamo, utilizziamo, divulghiamo e proteggiamo le tue informazioni personali quando utilizzi il nostro sito web.</p>
                    </div>
                </div>

                <!--  -->
                <div class="policies-privacy-view--section">
                    <div class="policies-privacy-view--section--header">
                        <h5>1. Informazioni Raccolte</h5>
                    </div>

                    <div class="policies-privacy-view--section--item">
                        <p>1.1 <b>Informazioni fornite volontariamente:</b> Raccogliamo le informazioni che ci fornisci volontariamente attraverso i vari form presenti sul nostro sito. Queste informazioni possono includere, ma non sono limitate a:</p>

                        <ul>
                            <li>Nome e Cognome</li>
                            <li>Indirizzo email</li>
                            <li>Numero di telefono</li>
                            <li>Data di nascita</li>
                            <li>Indirizzo</li>
                            <li>Località</li>
                            <li>Codice postale</li>
                            <li>Altre informazioni fornite nei campi del form</li>
                        </ul>
                    </div>

                    <div class="policies-privacy-view--section--item">
                        <p>1.2 <b>Informazioni raccolte automaticamente:</b> Quando visiti il nostro sito, possiamo raccogliere automaticamente informazioni come indirizzi IP, tipo di browser, pagine visualizzate e altri dati di navigazione.</p>
                    </div>
                </div>

                <!--  -->
                <div class="policies-privacy-view--section">
                    <div class="policies-privacy-view--section--header">
                        <h5>2. Uso delle Informazioni</h5>
                        <p>Utilizziamo le informazioni raccolte per i seguenti scopi:</p>
                    </div>

                    <div class="policies-privacy-view--section--item">
                        <p>2.1 <b>Fornire i servizi richiesti:</b> Utilizziamo le informazioni per rispondere alle tue richieste e fornirti i servizi o le informazioni richieste.</p>
                    </div>

                    <div class="policies-privacy-view--section--item">
                        <p>2.2 <b>Comunicazioni:</b> Possiamo utilizzare le informazioni per inviarti comunicazioni relative ai nostri prodotti, servizi, aggiornamenti e promozioni.</p>
                    </div>

                    <div class="policies-privacy-view--section--item">
                        <p>2.2 <b>Migliorare il sito:</b> Analizziamo le informazioni per migliorare il nostro sito, i prodotti e i servizi offerti.</p>
                    </div>
                </div>

                <!--  -->
                <div class="policies-privacy-view--section">
                    <div class="policies-privacy-view--section--header">
                        <h5>3. Condivisione delle Informazioni con Terze Parti</h5>
                    </div>

                    <div class="policies-privacy-view--section--item">
                        <p>3.1 <b>Fornitori di servizi:</b> Possiamo condividere le informazioni con terze parti che ci forniscono servizi, come il trattamento dei pagamenti, l'hosting del sito o altri servizi tecnici.</p>
                    </div>

                    <div class="policies-privacy-view--section--item">
                        <p>3.2 <b>Conformità legale:</b> Condividiamo informazioni quando richiesto per ottemperare a leggi, regolamenti o rispondere a richieste legali.</p>
                    </div>
                </div>

                <!--  -->
                <div class="policies-privacy-view--section">
                    <div class="policies-privacy-view--section--header">
                        <h5>4. Sicurezza delle Informazioni</h5>
                    </div>

                    <p>Adottiamo misure di sicurezza adeguate per proteggere le informazioni personali dai rischi di accesso non autorizzato, divulgazione, alterazione o distruzione.</p>
                </div>

                <!--  -->
                <div class="policies-privacy-view--section">
                    <div class="policies-privacy-view--section--header">
                        <h5>5. Cookie e Altre Tecnologie di Tracciamento</h5>
                    </div>

                    <p>Il nostro sito può utilizzare <router-link to="/policies/cookie">cookie</router-link> e tecnologie simili. Puoi gestire le impostazioni dei cookie attraverso il tuo browser.</p>
                </div>

                <!--  -->
                <div class="policies-privacy-view--section">
                    <div class="policies-privacy-view--section--header">
                        <h5>6. Modifiche a questa Privacy Policy</h5>
                    </div>

                    <p>Ci riserviamo il diritto di modificare questa Privacy Policy in qualsiasi momento. Le modifiche saranno pubblicate su questa pagina con l'indicazione della data di aggiornamento.</p>
                </div>

                <!--  -->
                <div class="policies-privacy-view--section">
                    <div class="policies-privacy-view--section--header">
                        <h5>7. Contatti</h5>
                    </div>

                    <p>Per domande o commenti riguardanti questa Privacy Policy, puoi contattarci all'indirizzo email .</p>
                    <p>Leggi attentamente questa Privacy Policy prima di utilizzare il nostro sito. Continuando a utilizzare il sito, acconsenti alla nostra raccolta e all'uso delle tue informazioni personali come descritto in questa Privacy Policy.</p>
                </div>
            </bxs-layout>
        </section>
    </div>
</template>

<script>
export default {
    name: 'policies_privacy'
}
</script>