<template>
    <div class="come-funziona-view">
        <bxs-hero
        src="/img/come-funziona-1.jpg"
        title="Come funziona"></bxs-hero>

        <!-- ------------------------------------------------------------------------------------------------------------------
            come funziona
        ------------------------------------------------------------------------------------------------------------------ -->
        <section-come-funziona-full />

        <!-- ------------------------------------------------------------------------------------------------------------------
            sedi
        ------------------------------------------------------------------------------------------------------------------ -->
        <section-sedi />

        <!-- ------------------------------------------------------------------------------------------------------------------
        ------------------------------------------------------------------------------------------------------------------ -->
        <section-tessera
        hide-cta
        hide-label>
            <h1 class="text-gradient">Entra a far parte <br class="show-ds"> della comunità</h1>
            <p class="h5 text-400 mb-2">Con la tessera di Leila attivi il servizio di condivisione della Biblioteca degli oggetti con cui condividere e prendere in prestito tutti gli oggetti che vuoi. Grazie al tesseramento hai diritto all’assicurazione che ti permette di partecipare a <b>qualsiasi attività di Leila</b> come laboratori, workshop, LEILA SOCIAL CLUB e altri servizi.</p>
        </section-tessera>

        <!-- ------------------------------------------------------------------------------------------------------------------
        ------------------------------------------------------------------------------------------------------------------ -->
        <section>
            <bxs-layout>
                <div>
                    <p class="label">Mezza tessera</p>
                    <h5>“E SE NON POTESSI ATTIVARE IL SERVIZIO DI CONDIVISIONE, MA VOLESSI COMUNQUE PARTECIPARE A UN’ATTIVITÀ DI LEILA?”</h5>
                    <p>La soluzione è semplice: la <b>mezza tessera</b>! Con la mezza tessera potrai beneficiare dell’assicurazione, senza però poter partecipare al servizio di condivisione della biblioteca degli oggetti. L’attivazione sia della tessera che della mezza tessera avviene in presenza nella sede in via Serra 2 G/H o in biblioteca Salaborsa nei giorni e orari di apertura (clicca qui).</p>
                </div>

                <div>
                    <p class="label">Presenza</p>
                    <h5>Perché in presenza?</h5>
                    <p>Per il tesseramento all’associazione serve la tua firma. Inoltre il servizio di condivisione degli oggetti si attiva solo se porti almeno un tuo oggetto!</p>
                </div>

                <div>
                    <p class="label">Tessera in regalo</p>
                    <p>Porta la tua tessera delle biblioteche comunali e ricevi quella di Leila in REGALO!</p>
                </div>

                <div>
                    <h4>Vogliamo incontrarti e ti aspettiamo!</h4>
                    <p>Per qualsiasi dubbio o perplessità scrivi a <a href="mailto:info@leila-bologna.it">info@leila-bologna.it</a> o visita la pagina contatti per ulteriori informazioni.</p>
                </div>
            </bxs-layout>
        </section>

        <!-- ------------------------------------------------------------------------------------------------------------------
            social
        ------------------------------------------------------------------------------------------------------------------ -->
        <section id="section-rinnovo-tessera">
            <bxs-layout>
                <div class="mb-ui">
                    <p class="label">Rinnovo</p>
                    <h4>Rinnova la tua tessera</h4>
                </div>

                <form-rinnovo-tessera />
            </bxs-layout>
        </section>

        <!-- ------------------------------------------------------------------------------------------------------------------
            chi siamo
        ------------------------------------------------------------------------------------------------------------------ -->
        <section-about />

        <!-- ------------------------------------------------------------------------------------------------------------------
            dicono di noi
        ------------------------------------------------------------------------------------------------------------------ -->
        <section-dicono-di-noi />

        <!-- ------------------------------------------------------------------------------------------------------------------
            social
        ------------------------------------------------------------------------------------------------------------------ -->
        <section-social />
    </div>
</template>

<script>
export default {
    name: 'come_funziona'
}
</script>