<template>
    <div class="startup-view">
        <bxs-hero src="https://leila-bologna.it/wp-content/uploads/2023/10/cartinasenzasfondo.png">
            <div class="flex column align-center justify-center text-center pa-ui fill-height light">
                <div>
                    <h1 class="text-500">Start up innovativa</h1>
                </div>
            </div>
        </bxs-hero>

        <!-- ------------------------------------------------------------------------------------------------------------------
        ------------------------------------------------------------------------------------------------------------------ -->
        <section>
            <bxs-layout>
                <div class="mb-16">
                    <p class="h5 mb-4">Negli ultimi anni, Leila - La Biblioteca degli Oggetti APS ha consolidato il proprio successo e la propria <br class="show-ds"> visibilità mediatica, culminando nella fondazione di LEILA Srl Società Benefit, una start-up innovativa</p>
                    <h5>Ci impegniamo a soddisfare le tue esigenze offrendo una gamma <br class="show-ds"> completa di servizi che si adattano alle tue necessità</h5>
                </div>

                <div class="left-box">
                    <div class="mb-12">
                        <h5>Aziende</h5>
                        <p class="mb-2">Welfare aziendale, il servizio di condivisione degli oggetti migliora la qualità della vita dei dipendenti. Riducendo la necessità di acquisti costosi o occasionali, si allevia il carico finanziario aumentando il benessere generale.</p>
                        <p class="mb-2">Sostenibilità ambientale, la condivisione degli oggetti rappresenta uno strumento tangibile per le aziende che abbracciano un modello di economia collaborativa, ottimizzando l'uso delle risorse esistenti e preservando l'ambiente.</p>
                        <p class="mb-2">Il servizio si inserisce perfettamente nel report di sostenibilità nel rispetto dei criteri ESG, permettendo all'azienda di dimostrare impegno e responsabilità da un punto di vista ambientale, sociale e di governance</p>
                        <p>Gestionale e web app, strumenti che garantiscono qualità e comodità. Ideati e progettati attraverso la nostra esperienza.</p>

                        <bxs-btn
                        color="fourth"
                        href="/leila-la-biblioteca-degli-oggetti-aziende.pdf">Scarica la brochure</bxs-btn>

                        <div class="left-line"></div>
                    </div>

                    <bxs-divider class="mb-12"></bxs-divider>

                    <div class="mb-12">
                        <h5>AMMINISTRAZIONE PUBBLICA</h5>
                        <p class="mb-2">Welfare e cittadinanza attiva: con il servizio di condivisione degli oggetti il cittadino riduce le spese, aumenta la qualità della vita e agisce attivamente alla lotta al cambiamento climatico, aumentando la responsabilità e il senso di comunità. </p>
                        <p class="mb-2">Sostenibilità ambientale: la biblioteca degli oggetti è uno strumento concreto capace di calmierare gli sprechi, sfruttare pienamente gli oggetti, ridurre i rifiuti e quindi abbattere le emissioni di CO2.</p>
                        <p class="mb-2">Gestionale e web app: strumenti che garantiscono qualità e comodità per gli utenti e gli esercenti del servizio. Ideati e progettati attraverso la nostra esperienza.</p>
                        <p>Rete nazionale delle biblioteche degli oggetti, ovvero entrare a far parte di un movimento culturale che si estende in tutta Italia e offrire la possibilità  di prendere in prestito l’oggetto di cui si ha bisogno in tutta Italia, in qualsiasi Biblioteca degli Oggetti che fa parte della rete nazionale. Con una web-app comoda e funzionale!</p>

                        <bxs-btn
                        color="fourth"
                        href="/leila-la-biblioteca-degli-oggetti-amministrazioni.pdf">Scarica la brochure</bxs-btn>
                    </div>

                    <bxs-divider class="mb-12"></bxs-divider>

                    <div>
                        <h5>ASSOCIAZIONI</h5>
                        <p class="mb-2">Servizi di consulenza e tutoraggio per l’apertura e/o crescita della tua biblioteca degli oggetti.</p>
                        <p class="mb-2">Gestionale e web app, strumenti che garantiscono qualità e comodità per gli utenti e gli esercenti del servizio. Ideati e progettati attraverso la nostra esperienza.</p>
                        <p>Rete nazionale delle biblioteche degli oggetti, ovvero entrare a far parte di un movimento culturale che si estende in tutta Italia e offrire la possibilità  di prendere in prestito l’oggetto di cui si ha bisogno in tutta Italia, in qualsiasi Biblioteca degli Oggetti che fa parte della rete nazionale.</p>

                        <bxs-btn
                        color="fourth"
                        href="/leila-la-biblioteca-degli-oggetti-associazioni.pdf">Scarica la brochure</bxs-btn>
                    </div>
                </div>
            </bxs-layout>
        </section>

        <!-- ------------------------------------------------------------------------------------------------------------------
        ------------------------------------------------------------------------------------------------------------------ -->
        <section class="mute">
            <bxs-layout>
                <div class="mb-16">
                    <h2>LA NOSTRA OFFERTA <br> COMODA, FACILE E FUNZIONALE</h2>
                </div>

                <bxs-row>
                    <bxs-col cols="12" ds="6">
                        <div>
                            <h5 class="mb-2">IL SOFTWARE GESTIONALE</h5>
                            <p>Per chi offre il servizio di prestito degli oggetti.</p>

                            <ul>
                                <li class="mb-1">- Catalogazione degli oggetti</li>
                                <li class="mb-1">- Organizzazione dei dati</li>
                                <li>- Monitoraggio del servizio</li>
                            </ul>
                        </div>
                    </bxs-col>

                    <bxs-col cols="12" ds="6">
                        <div>
                            <div>
                                <h5 class="mb-2">La Web App</h5>
                                <p>Per chi usufruisce del servizio di prestito degli oggetti.</p>
                            </div>

                            <ul>
                                <li class="mb-1">- Condivisione di oggetti utili, ma temporaneamente inutilizzati</li>
                                <li class="mb-1">- Cercare e prenotare ciò di cui si ha necessità, per bisogno, per desiderio o per un test prima dell’acquisto</li>
                                <li class="mb-1">- La possibilità di prendere in prestito ciò di cui si ha bisogno in tutte le biblioteche degli oggetti che fanno parte della rete nazionale</li>
                            </ul>
                        </div>
                    </bxs-col>
                </bxs-row>
            </bxs-layout>
        </section>

        <!-- ------------------------------------------------------------------------------------------------------------------
        ------------------------------------------------------------------------------------------------------------------ -->
        <section>
            <bxs-layout>
                <div>
                    <h4 class="mb-2">LA RETE NAZIONALE</h4>
                    <p class="h5">Un movimento culturale per una rivoluzione gentile.</p>
                </div>

                <div>
                    <p class="mb-1">Grazie alla nostra esperienza abbiamo aiutato tante Biblioteche degli Oggetti a nascere e crescere condividendo le nostre competenze. Negli anni sono aumentate sempre di più, trascinate anche da un grande interesse sociale e mediatico. E dal momento che la nostra parola d’ordine è CONDIVISIONE, già in tempi non sospetti sognavamo di creare una Rete Nazionale delle Biblioteche degli Oggetti. </p>
                    <p>Questo sogno è diventato realtà, stiamo muovendo i primi passi per la costruzione di un movimento culturale per una rivoluzione gentile basata sulla condivisione.</p>
                </div>

                <bxs-row>
                    <bxs-col cols="12" ds="6">
                        <div>
                            <h5>Per gli utenti</h5>
                            <p>La possibilità di prendere in prestito l’oggetto di cui si ha bisogno in tutta Italia, in qualsiasi Biblioteca degli Oggetti che fa parte della rete nazionale. Con una web-app comoda e funzionale!</p>
                        </div>
                    </bxs-col>

                    <bxs-col cols="12" ds="6">
                        <div>
                            <h5>Per le biblioteche degli oggetti</h5>
                            <p>Un luogo dove condividere esperienze, problemi, soluzioni e professionalità. Una cordata di realtà con cui progettare iniziative a livello nazionale. La garanzia di offrire un servizio professionale attraverso l’utilizzo del software gestionale realizzato su misura per le esigenze delle Biblioteche degli Oggetti.</p>
                        </div>
                    </bxs-col>
                </bxs-row>
            </bxs-layout>
        </section>

        <!-- ------------------------------------------------------------------------------------------------------------------
        ------------------------------------------------------------------------------------------------------------------ -->
        <section>
            <bxs-layout>
                <div class="mb-12">
                    <h4>Chi fa parte della rete nazionale</h4>
                </div>

                <div>
                    <bxs-row>
                        <bxs-col cols="12" ds="9">
                            <logo
                            max-width="120px"
                            class="mb-6" />

                            <div class="mb-12">
                                <h5>LEILA BOLOGNA - LA BIBLIOTECA DEGLI OGGETTI</h5>
                                <p class="mb-2">La prima Biblioteca degli Oggetti in Italia. Negli anni è diventata un punto di riferimento per tutte le realtà nate dopo di lei in tutto il territorio nazionale.</p>
                                <p>Per quanto riguarda la città di Bologna, il servizio di condivisione degli oggetti si sta espandendo a vista d’occhio, grazie al coinvolgimento e la collaborazione con il Settore biblioteche e welfare culturale del Comune di Bologna.</p>

                                <bxs-btn
                                color="mute"
                                dark
                                to="/storia-e-filosofia-di-leila">Scopri la storia di Leila</bxs-btn>
                            </div>

                            <div>
                                <p class="mb-2">Per diversi anni Leila Bologna non ha avuto neanche una sede tutta sua, ma con la passione e la determinazione abbiamo raggiunto traguardi molto importanti.</p>
                                <p>Ora abbiamo una sede ufficiale, in via Luigi Serra 2 G/H in cui il servizio di condivisione è attivo ogni giorno. In più è possibile usufruire del servizio anche nelle Biblioteche Comunali Salaborsa, Luigi Spina e Jorge Luis Borges.</p>

                                <bxs-btn
                                color="mute"
                                dark
                                to="/collaborazioni/leila-nelle-biblioteche">Scopri Leila in biblioteca</bxs-btn>
                            </div>
                        </bxs-col>

                        <bxs-col cols="12" ds="3">
                            <bxs-figure src="/img/team-leila-bologna.jpg"></bxs-figure>
                        </bxs-col>
                    </bxs-row>

                    <bxs-divider class="my-12"></bxs-divider>

                    <bxs-row>
                        <bxs-col cols="12" ds="9">
                            <bxs-figure
                            src="/img/la-rete/atpico/logo-atpico.png"
                            class="mb-6"
                            style="max-width: 120px;"></bxs-figure>

                            <h5>ATPiCO</h5>
                            <p class="mb-2">ATPiCO è una neonata associazione con sede a San Vendemiano in provincia di Treviso, fondata da Silvio Stragapede, Luca Foltran e Saverio Stragapede, con l'intento di portare nella loro terra natale le spinte culturali che hanno conosciuto ed apprezzato in giro per il mondo. Alla faccia del "Qui da noi non succede mai niente"! </p>
                            <p class="mb-2">L'attività dell'associazione è composta da tre pilastri. </p>
                            <p class="mb-2">Il primo è La Biblioteca Degli Oggetti, in rete con Leila di Bologna, che ha conquistato ATPiCO per la sua semplicità ed efficacia. </p>
                            <p class="mb-2">Il secondo, che per ora è ancora in fase progettuale, è il Laboratorio condiviso: una stanza con diversi banchi attrezzati con il necessario per la lavorazione del legno, del ferro, per lavori di cucito, di riparazione biciclette ed ogni altra attività che la creatività farà emergere.  Un luogo che darà l'opportunità a tutti gli appassionati di incontrarsi e avere uno spazio dove divertirsi, arrangiarsi, ingegnarsi nel perfezionare le proprie competenze manuali. </p>
                            <p class="mb-2">Il terzo è il Repair Café. Si tratta del movimento europeo che si occupa della periodica organizzazione di eventi che fanno incontrare i proprietari di oggetti rotti con volontari e appassionati in grado di ripararli. </p>
                            <p class="mb-2">ATPiCO è dunque un nome scelto intenzionalmente per omaggiare la vitalità nella sua forma chimica (l'ATP appunto), elemento che siamo certi caratterizzerà tutti coloro che saranno attratti da questa realtà. </p>
                        </bxs-col>

                        <bxs-col cols="12" ds="3">
                            <bxs-figure src="/img/la-rete/atpico/foto-insieme.jpg"></bxs-figure>
                        </bxs-col>
                    </bxs-row>
                </div>
            </bxs-layout>
        </section>

        <!-- ------------------------------------------------------------------------------------------------------------------
        ------------------------------------------------------------------------------------------------------------------ -->
        <section class="fourth light">
            <bxs-layout>
                <!-- <div class="text-center">
                    <p class="h5">Il nostro supporto e la nostra <b>esperienza</b>, maturata dal 2016, sarà condivisa e messa a disposizione. Offrendo un <b>percorso co-costruito</b> in cui individuare argomenti, moduli formativi e di consulenza ritagliati <b>su misura</b>.</p>
                    <h5>Contatti</h5>
                    <p class="mb-1">Antonio Beraldi <span>Ceo & Founder</span></p>
                    <p class="mb-1">
                        <a href="tel:+393401568015">3401568015</a>
                    </p>
                    <p>
                        <a href="mailto:antonio.beraldi@leila.srl">antonio.beraldi@leila.srl</a>
                    </p>
                </div> -->

                <bxs-row>
                    <bxs-col cols="12" ds="7">
                        <p class="h5">Con la nostra esperienza consolidata dal 2016, siamo pronti a offrire un supporto completo e professionale per soddisfare tutte le esigenze dei nostri clienti, garantendo un percorso co-costruito su misura.</p>
                    </bxs-col>

                    <bxs-col cols="12" ds="3" offset-ds="2">
                        <div class="small mt-1">
                            <p class="mb-2">Antonio Beraldi <span class="text-disabled text-500 ml-1">Ceo & Founder</span></p>
                            <p class="mb-0">
                                <a href="tel:+393401568015">340 15 68 015</a>
                            </p>
                            <p>
                                <a href="mailto:antonio.beraldi@leila.srl">antonio.beraldi@leila.srl</a>
                            </p>
                        </div>
                    </bxs-col>
                </bxs-row>
            </bxs-layout>
        </section>
    </div>
</template>

<script>
export default {
    name: 'startup'
}
</script>