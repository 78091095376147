<template>
    <router-link
    :to="`/news/${item.slug}`"
    class="lel-single-post-card">
        <bxs-figure
        :src="item.img_url"
        ratio="1-1"
        class="mb-2" />

        <small class="text-disabled">{{ item.created_at }}</small>

        <div class="text-truncate mt-1 ">
            <h6 class="text-truncate">{{ item.title.trim() }}</h6>
        </div>
    </router-link>
</template>

<script>
export default {
    name: 'lel_single_post_card',
    props: {
        item: Object
    }
}
</script>

<style lang="scss" scoped>
.lel-single-post-card {
    display: block;
    text-decoration: none;
    color: inherit;

    .figure {
        background-color: var(--color-mute);

        .figure--div {
            transition: transform 0.3s ease-out;
        }
    }

    &:hover {
        .figure {
            .figure--div {
                transition: transform 0.25s ease-out;
                transform: scale(0.95);
            }
        }

        color: var(--color-primary);

        h6 {
            text-decoration: underline;
        }
    }
}
</style>