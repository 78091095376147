<template>
    <div class="about-view">
        <bxs-hero
        title="Chi siamo"
        src="https://leila-bologna.it/wp-content/uploads/2022/12/leila-bologna-sede-di-via-serra-contatti.jpg"></bxs-hero>

        <section>
            <bxs-layout>
                <div class="mb-ui">
                    <h2>Storia e filosofia di Leila</h2>
                    <p class="h4 text-uppercase text-400 text-ui">Leila Bologna è la prima biblioteca degli oggetti in Italia</p>
                    <p class="h5">Nasce nel 2016 come associazione culturale affermandosi sin da subito come uno strumento concreto di economia circolare e di prossimità, tutela ambientale e creazione di comunità attraverso la cultura della condivisione.</p>
                </div>

                <bxs-row>
                    <bxs-col cols="12" ds="6">
                        <p>Leila è un luogo in cui gli oggetti si possono <bxs-link label="prendere in prestito gratuitamente" to="/come-funziona" hide-arrow actived />, evitando così di doverli comprare. Leila è un modo di stare al mondo, uno strumento utile e concreto, un processo di cambiamento nelle abitudini quotidiane.</p>
                        <p>Tutto questo attraverso il risparmio economico, la soddisfazione dei propri desideri, la riduzione della propria impronta ecologica (calmierando lo smaltimento e la produzione massiva degli oggetti), l’aumento della fiducia e di appartenenza alla comunità attraverso il principio della condivisione, permettendo così azioni di cittadinanza attiva.</p>
                    </bxs-col>

                    <bxs-col cols="12" ds="6">
                        <bxs-figure src="https://leila-bologna.it/wp-content/uploads/2022/12/leila-inaugurazione-via-serra-2.jpg"></bxs-figure>
                    </bxs-col>
                </bxs-row>
            </bxs-layout>
        </section>

        <!-- ------------------------------------------------------------------------------------------------------------------
            breath section
        ------------------------------------------------------------------------------------------------------------------ -->
        <section class="px-layout">
            <bxs-figure src="/img/team-leila-bologna.jpg" />
        </section>

        <!-- ------------------------------------------------------------------------------------------------------------------
        ------------------------------------------------------------------------------------------------------------------ -->
        <section>
            <bxs-layout>
                <div class="mb-ui">
                    <p class="label">Condivisione</p>
                    <h3>Il principio fondante di Leila Bologna</h3>
                    <p>Far fronte alla crisi climatica, sociale ed economica sempre impossibile. Ma un rimedio c’è, la condivisione: una semplice soluzione per i problemi più complessi.</p>
                </div>

                <bxs-row>
                    <bxs-col cols="12" ds="4">
                        <bxs-figure src="https://leila-bologna.it/wp-content/uploads/2022/12/filosofia-di-leila-3.jpg"></bxs-figure>
                    </bxs-col>

                    <bxs-col
                    cols="12"
                    ds="8">
                        <p class="h5">Per prendere in prestito un oggetto dalla biblioteca è necessario <bxs-link href="#section-come-funziona" label="condividerne uno proprio" hide-arrow></bxs-link>.</p>

                        <h6 class="mb-2">Il mettere in prestito un oggetto sancisce l’ingresso in una pratica di condivisione e di fiducia.</h6>
                        <p class="text-400">Un circolo virtuoso basato sulla condivisione che cresce grazie alla fiducia nelle altre persone e alla soddisfazione personale.</p>

                        <p>Facciamo un esempio: Alessandra vuole andare a fare un trekking di qualche giorno, ma non ha la tenda. Un suo amico le dice che ne può prendere una da Leila, però deve prima fare la tessera dell’associazione e condividere almeno un suo oggetto.</p>
                        <p>Allora Alessandra decide di portare il suo flauto traverso. Non lo suona più da tanto tempo e non trova giusto che stia lì chiuso in una scatola a prendere polvere per anni. Contemporaneamente però, non vuole neanche liberarsene, è un oggetto molto importante per lei.</p>
                        <p>Per fortuna che gli oggetti della biblioteca di Leila rimangono di proprietà delle persone che li hanno messi in condivisione. In questo modo Alessandra può utilizzare la tenda che desiderava e continuare a possedere il suo flauto, anche se per un po’ verrà utilizzato da qualche altra persona che ne avrà più bisogno di lei.</p>

                        <p>Fai come Alessandra, metti un tuo oggetto in condivisione, prendi in prestito quello che vuoi e partecipa a rendere il mondo un luogo migliore.</p>

                        <bxs-btn to="/come-funziona">Scopri come iniziare</bxs-btn>
                    </bxs-col>
                </bxs-row>
            </bxs-layout>

            <bxs-layout max-width="1024">
                <bxs-row>
                    <bxs-col
                    cols="12"
                    ds="6">
                        <bxs-figure
                        src="https://leila-bologna.it/wp-content/uploads/2022/12/filosofia-di-leila-2-500x700.jpg"
                        style="margin-top: 10vh;"></bxs-figure>
                    </bxs-col>

                    <bxs-col
                    cols="12"
                    ds="6">
                        <bxs-figure
                        src="https://leila-bologna.it/wp-content/uploads/2022/12/filosofia-di-leila-5-500x700.jpg"
                        style="margin-top: 25vh;"></bxs-figure>
                    </bxs-col>
                </bxs-row>
            </bxs-layout>
        </section>

        <!-- ------------------------------------------------------------------------------------------------------------------
        ------------------------------------------------------------------------------------------------------------------ -->
        <section>
            <bxs-layout>
                <div class="mb-ui">
                    <p class="label">Gli obbiettivi</p>
                    <h3>Leila si pone tre obiettivi principali che ne caratterizzano il funzionamento e la filosofia</h3>
                </div>

                <ul>
                    <li>
                        <p class="text-primary h1">.1 Creare cultura e socialità</p>
                        <p class="h5">Attraverso la condivisione e l’esercizio della fiducia, oltre ad aumentare la qualità della vita delle persone, intende porre l’attenzione sull’idea di possesso, in favore di una cornice di pensiero improntata all’utilizzo.</p>
                    </li>

                    <li>
                        <p class="text-primary h1">.2 Risparmio economico</p>
                        <p class="h5">La condivisione fa sì che ogni oggetto venga utilizzato a pieno. Un trapano, ad esempio, è mediamente impiegato solo per 7 minuti rispetto alle sue possibilità. Grazie al servizio di biblioteca degli oggetti, evitiamo di acquistare un prodotto che per la maggior parte del tempo rimarrebbe inutilizzato. È uno strumento concreto che aiuta a fare acquisti mirati e al contempo offre la possibilità di provare un oggetto che magari si intende comprare.</p>
                    </li>

                    <li>
                        <p class="text-primary h1">.3 Tutela ambientale</p>
                        <p class="h5">Grazie alla condivisione, Leila fa sì che si acquisti solo ciò di cui si ha davvero necessità, contribuisce a ridurre la quantità di rifiuti da smaltire e gli oggetti da produrre,  e ad abbassare l’impronta ecologica di chi partecipa.</p>
                    </li>
                </ul>
            </bxs-layout>
        </section>

        <!-- ------------------------------------------------------------------------------------------------------------------
        ------------------------------------------------------------------------------------------------------------------ -->
        <section>
            <bxs-layout>
                <div class="mb-ui">
                    <p class="label">Sostenibilità</p>
                    <h3>Con Leila il consumismo è sostenibile</h3>
                </div>

                <div>
                    <p>Con Leila riduci le emissioni di Co2 e supporti la transizione ecologica verso un futuro sostenibile a emissioni zero. Leila crede che il nostro mondo sia fatto di relazioni e che solo grazie ad esse si possa far fronte alle difficoltà dei nostri tempi, che siano economiche, culturali, sociali, ambientali.</p>
                    <p>Da tutto questo è nato un movimento concreto, capace di realizzare una piccola rivoluzione quotidiana in ogni casa, condominio, scuola, biblioteca, tenda a partire dall’esigenza di utilizzare un avvitatore, un minipimer, un proiettore, un passeggino, una palla, un barbecue, un drone…</p>
                    <p>Il futuro è condivisione!</p>
                </div>
            </bxs-layout>
        </section>

        <!-- ------------------------------------------------------------------------------------------------------------------
        ------------------------------------------------------------------------------------------------------------------ -->
        <section>
            <bxs-layout>
                <div>
                    <div class="mb-ui">
                        <p class="label">Storia</p>
                        <h4>L’idea</h4>
                    </div>

                    <div>
                        <p>Leila Bologna nasce prendendo esempio ed ispirazione da Leila Berlino, un progetto nato con l’obiettivo di condividere gli oggetti all’interno di un condominio, costituendo di fatto la prima biblioteca degli oggetti in Italia.  Leila, si è presentata alla città di Bologna nel 2016 come una semplice idea con una potenzialità innovativa molto alta.</p>
                        <p>La città era pronta ad accogliere la filosofia della condivisione, così Leila,  intessendo reti con diverse realtà del territorio, ha iniziato a seminare, proponendosi come strumento di sviluppo urbano sostenibile. Da questi semi, sono nati alcuni corner sparsi per le vie della città, delle prototipiche biblioteche di oggetti in cui le persone potevano tesserarsi e ritirare ciò di cui avevano bisogno.  Le prime biblioteche sono nate  all’interno di spazi condivisi con realtà molto differenti tra loro come: …..Velostazione Dynamo, Balotta Bistrò,  Instabile Portazza, della Confraternita dell’Uva, della, del Caffè Belmeloro e di Biblioteca Salaborsa. </p>
                    </div>
                </div>

                <div>
                    <h4>La crescita</h4>
                    <p>La fiducia riposta in Leila e nei suoi valori, ha generato sinergie incredibili negli anni, molte delle quali si sono evolute in collaborazioni costanti, che hanno lasciato un segno importante aiutando la giovane Biblioteca degli Oggetti a crescere. Tra queste spiccano: Empirico (2017), un’operazione di rigenerazione urbana attraverso un container dedicato alla trasmissione del sapere artigianale e alla valorizzazione dell’identità artigianale di Bologna all’interno del Winter Village; la Cargo Bike (2018) che concede tuttora un modo di diffondere su ruota il valore della condivisione dei saperi artigiani nonché quella dei giochi per bambini attraverso la realizzazione di laboratori nelle piazze cittadine; il Baracca Festival (2018), il Festival della Condivisione dei Saperi nei Giardini del Baraccano con laboratori artigianali, attività sportive, musica e dirette radio.</p>
                    <p>Non solo, anche le istituzioni cittadine hanno creduto fin da subito in Leila attivando  negli anni  patti di collaborazione come quello con i quartieri San Vitale (2016) e Santo Stefano (2017-2020) e con Biblioteca Salaborsa  (2017-2020).</p>
                </div>

                <div>
                    <h4>La Biblioteca degli Oggetti</h4>
                    <p>Le idee, e Leila con loro, hanno viaggiato tra le strade e i vicoli della città e, nel 2019, era giunto il momento di istituire la prima vera e propria Biblioteca degli Oggetti: il 19 dicembre 2019, Leila vince un bando del Comune di Bologna per la concessione di un immobile in via Serra 2 g/h. Da allora Leila ha finalmente trovato la sede ufficiale per la prima Biblioteca degli Oggetti in Italia.</p>
                    <p>Grazie alla nuova sede, le collaborazioni di Leila aumentano: organizza un crowdfunding sulla piattaforma Idea Ginger col sostegno di Emilbanca e ottiene il 116% dell’obiettivo; vince il Bando Cultura del Comune di Bologna per il progetto “BOtteghe-storie artigiane” per la realizzazione di video e laboratori gratuiti sull'artigianato. Il 2021 regala a Leila una collaborazione con Fondazione Innovazione Urbana a DumBO, dove co-progetterà laboratori di falegnameria per bambini; inizia inoltre la convenzione con la fattoria sociale Il Biricoccolo, e la Biblioteca degli Oggetti diventa anche punto di distribuzione cassette ortaggi in Bolognia, perchè per Leila il benessere della comunità passa anche da cosa mangiamo e dallo stile con cui si produce. </p>
                </div>

                <div>
                    <h4>Dove stiamo andando</h4>
                    <p>La storia di Leila è un intreccio di storie da raccontare, di persone che attraversandola l’hanno resa unica. Grazie a tutte queste persone e grazie ad ogni socio/socia, Leila oggi ha attiva una collaborazione con il Settore Biblioteche del Comune di Bologna affinché si possa offrire il servizio di condivisione degli oggetti all’interno delle biblioteche di quartiere. L’idea di strutturare questo servizio innovativo in collaborazione con il Comune è stata selezionata dalla Fondazione Del Monte come meritevole di supporto e grazie al contributo concesso abbiamo potuto fare grandi passi in avanti. Il progetto è stato accolto molto bene anche dalla città e in collaborazione con Barca 59, in via sperimentale, è nata una Leila all’interno della Casa di Quartiere Rosa Marchi. </p>
                    <p>La grande ambizione di Leila è aiutare a far nascere e crescere altre Biblioteche degli Oggetti in Italia (e non solo). Per dar vita a una rete di scambio e collaborazione, un movimento di condivisione.</p>
                    <p>Oltre alla biblioteca degli oggetti, Leila ha sempre avuto l’obiettivo di fare comunità, e agire per arginare i danni ambientali attuati dalla nostra società, attraverso la condivisione dei saperi. Una delle azioni più recenti è LEILA SOCIAL CLUB, che racchiude in sé la massima espressione di questa esigenza. Uno spazio in cui le persone condividono le proprie competenze gratuitamente, con lo scopo di aiutare e insegnare a riparare e riutilizzare gli oggetti.</p>
                </div>
            </bxs-layout>
        </section>

        <!-- ------------------------------------------------------------------------------------------------------------------
            dicono di noi
        ------------------------------------------------------------------------------------------------------------------ -->
        <section-dicono-di-noi />

        <!-- ------------------------------------------------------------------------------------------------------------------
            come funziona
        ------------------------------------------------------------------------------------------------------------------ -->
        <section-come-funziona />

        <!-- ------------------------------------------------------------------------------------------------------------------
            sedi
        ------------------------------------------------------------------------------------------------------------------ -->
        <section-sedi />
    </div>
</template>

<script>
export default {
    name: 'about'
}
</script>