<template>
    <div class="voi-e-leila-tirocinio-view">
        <bxs-hero title="Tirocinio" />
        <section>
            <bxs-layout>
                <div class="mb-ui">
                    <p class="label">Tirocinio</p>
                    <h3>Se studi all’Unibo e vuoi fare il tirocinio curriculare, Leila è il posto che fa per te!</h3>
                    <p>
                      A seconda del tuo indirizzo di studi puoi scegliere di lavorare nell’area che ti interessa di più, dove avrai l’affiancamento del/della referente di riferimento. <br><strong>Co-costruiremo</strong> assieme le modalità del tuo coinvolgimento. In questo modo, oltre alle attività di routine, potrai contribuire con un tuo <strong>progetto personale</strong>.
                    </p>
                    <div class="text-center yellow-banner">LE AREE DI LAVORO SONO:</div>
                </div>
                <bxs-row>
                    <bxs-col cols="12" ds="6">
                        <bxs-figure src="https://leila-bologna.it/wp-content/uploads/2022/12/leila-bologna-tirocinio-1024x683.jpg"></bxs-figure>
                    </bxs-col>
                    <bxs-col cols="12" ds="6">
                        <p>
                          <strong>Comunicazione</strong> – che va dalla gestione quotidiana dei social all’analisi dell’utenza, alla pianificazione di campagne pubblicitarie ad hoc fino al community management.
                        </p>
                        <p>
                          <strong>Progettazione e fundraising</strong> – la stesura di bandi (cittadini, regionali, nazionali ed europei), analisi e profilazione delle aziende.
                        </p>
                        <p>
                          <strong>Gestione della biblioteca</strong> – la gestione del budget e dell’economia interna all’associazione, lo scambio di oggetti tramite l’utilizzo di un gestionale da impostare, la logistica degli spostamenti, l’ottimizzazione del magazzino.
                        </p>
                    </bxs-col>
                </bxs-row>
                <bxs-row>
                    <bxs-col cols="12" ds="12">
                        <p>
                          Scegli Leila per il tuo tirocinio curriculare! Il nostro team è ampio e variegato e abbiamo una gran voglia di <strong>collaborare</strong> con persone che hanno il desiderio di mettersi in gioco.
                        </p>
                        <p>
                          Con Leila puoi dare un piccolo grande contributo per rendere il <strong>mondo</strong> un luogo più <strong>ecologico</strong>, <strong>equo</strong> ed <strong>etico</strong>!
                        </p>
                        <p>
                          Vieni a trovarci in via Luigi Serra 2 G/H agli orari indicati nella pagina <bxs-link label="contatti" to="/contatti" hide-arrow actived /> o scrivici una mail a <bxs-link label="info@leila-bologna.it" href="mailto:info@leila-bologna.it" hide-arrow actived />
                        </p>
                    </bxs-col>
                </bxs-row>
            </bxs-layout>
        </section>
    </div>
</template>

<script>
export default {
    name: 'voi_e_leila_tirocinio'
}
</script>
