<template>
    <section id="section-dicono-di-noi">
        <bxs-layout>
            <div class="flex nowrap align-center justify-between mb-ui">
                <p class="label mb-0">Dicono di noi</p>

                <bxs-link
                label="Scopri di più"
                to="/dicono-di-noi"></bxs-link>
            </div>

            <bxs-marquee class="mb-ui">
                <div class="flex nowrap">
                    <bxs-figure
                    src="/img/rai-1-logo.png"
                    ratio="1-1"
                    contain
                    width="80"
                    class="mr-ui" />

                    <bxs-figure
                    src="/img/sky-tg-24-logo.png"
                    ratio="1-1"
                    contain
                    width="80"
                    class="mr-ui" />

                    <bxs-figure
                    src="/img/la-repubblica-logo.png"
                    ratio="1-1"
                    contain
                    width="80"
                    class="mr-ui" />

                    <bxs-figure
                    src="/img/ansa-logo.svg"
                    ratio="1-1"
                    contain
                    width="80"
                    class="mr-ui" />

                    <bxs-figure
                    src="/img/sky-video-logo.png"
                    ratio="1-1"
                    contain
                    width="80"
                    class="mr-ui" />
                </div>
            </bxs-marquee>
        </bxs-layout>
    </section>
</template>

<script>
export default {
    name: 'section_dicono_di_noi'
}
</script>