<template>
    <div class="fare-condivisione-cargo-bike-view">
        <bxs-hero
        title="Fare condivisione - Leila social club"
        src="https://leila-bologna.it/wp-content/uploads/2022/12/leila-bologna-cargobike.jpg" />

        <section>
            <bxs-layout>
                <div>
                    <h3>Per imparare a migliorare il mondo</h3>
                    <p>Pensi che l’autogestione, la fantasia, l’intraprendenza e l’imparare a usare le mani siano il sale della terra per la crescita della tua giovane prole? Ne hai le scatole piene dei giochi gonfiabili, tablet e giochi pieni di regole preconfezionate?</p>
                </div>
            </bxs-layout>
        </section>

        <section>
            <bxs-layout>
                <div>
                    <p class="label">Non temere, ci pensa leila!</p>
                </div>

                <bxs-row>
                    <bxs-col cols="12" ds="6">
                        <p>Con i Giochi di Pippo! Arriviamo con la nostra Cargo Bike nel parco o giardino da te scelto portando tantissimi giochi di strada per il gioco libero. La messa a disposizione di piccoli attrezzi come i cerchi, le corde da saltare, i birilli per il bowling, i frisbee, i trampoli, le bolle di sapone, i gessi colorati, permette ai bambini di poter usare la propria fantasia e organizzare in modo autonomo i momenti di gioco.</p>
                    </bxs-col>

                    <bxs-col cols="12" ds="6">
                        <bxs-figure src="https://leila-bologna.it/wp-content/uploads/2022/12/leila-bologna-cargobike.jpg"></bxs-figure>
                        <bxs-figure src="https://leila-bologna.it/wp-content/uploads/2022/10/cargo-bike.jpg"></bxs-figure>
                    </bxs-col>
                </bxs-row>

                <p>Se, invece, vuoi una festa davvero originale in cui le e i bambine/i imparano a giocare realizzando con le loro mani dei lavoretti in legno allora quello che stai cercando è la Cargo Bike di Leila in versione CiapLab. In questo caso la nostra super bici porterà alla tua festa il suo carico di legni, matite, colori e pennelli per un laboratorio unico! Dove verrà dato un pezzo di legno per disegnare un giocattolo, un mostro, un supereroe, un fiore, un cuore. Il disegno verrà poi ritagliato, cartato e infine dipinto. I e le bimbe/i impareranno a trasformare uno scarto, destinato ad essere cestinato, in un gioco fatto con le proprie mani.</p>
                <p>Se vuoi la cargo bike per la tua festa, evento o vuoi collaborare con noi per utilizzarla compila il form 👇</p>

                <form-cargo-bike />
            </bxs-layout>
        </section>

        <section>
            <bxs-layout>
                <p class="label">Come nasce la cargo bike</p>
                <p>L’idea di questo progetto prende vita dalla frequente richiesta da parte del territorio, dalla cittadinanza, le socie e i soci dell’associazione, l’amministrazione comunale, di far maturare Leila ampliando le aree in cui valorizzare la condivisione. Abbiamo quindi lavorato per aggiungere all’esperienza della condivisione degli oggetti, la condivisione dei saperi, attraverso il fare insieme.</p>
                <p>Con un’attenzione particolare per le nuove generazioni, perché sono loro le persone che un domani erediteranno il mondo che stiamo costruendo. Vogliamo imparare assieme a loro come migliorare questo mondo, dunque! E non c’è modo migliore se non trasmettendo la curiosità e le competenze necessarie per imparare a riparare e costruire gli oggetti, oltre a crescere attraverso il gioco libero e autonomo!</p>
            </bxs-layout>
        </section>
    </div>
</template>

<script>
export default {
    name: 'fare_condivisione_cargo_bike'
}
</script>