<template>
    <div class="oggetti-suggeriti-view">
        <bxs-hero title="Oggetti suggeriti" />
        <section>
            <bxs-layout>
                <div class="mb-ui">
                    <p class="label">Oggetti Suggeriti</p>
                    <h3>Quali oggetti condividere?</h3>
                    <h5>Un elenco di oggetti suggeriti per la condivisione</h5>
                </div>
                <div class="mb-ui">
                  <ul class="griglia-oggetti">
                    <li>Computer</li>
                    <li>Tablet</li>
                    <li>Smartphone</li>
                    <li>Kit compost</li>
                    <li>Cesoie lunghe per potatura</li>
                    <li>Slackline</li>
                    <li>Monociclo</li>
                    <li>Monopattino elettrico</li>
                    <li>Maschera saldatore automatica</li>
                    <li>Rulli per imbiancare</li>
                    <li>Cavalletto per dipingere</li>
                    <li>Macchina da cucire</li>
                    <li>Ferri per fare a maglia/uncinetto</li>
                    <li>Ramponi da montagna</li>
                    <li>Ombrellone da spiaggia</li>
                    <li>Scarpette arrampicata</li>
                    <li>Giochi in scatola</li>
                    <li>Pistola colla a caldo</li>
                    <li>Controller PlayStation</li>
                    <li>Aquilone</li>
                    <li>Console Wii/PlayStation/Xbox</li>
                    <li>Videogiochi</li>
                    <li>Sci</li>
                    <li>Racchette da tennis e padel</li>
                    <li>Drone</li>
                    <li>Roller</li>
                    <li>Ciaspole</li>
                    <li>Stampelle</li>
                    <li>Tutori</li>
                    <li>Racchette trekking</li>
                    <li>Giradischi</li>
                    <li>Casco bici</li>
                    <li>Seggiolino bici bimbo posteriore</li>
                    <li>Irrigatori per piante da balcone</li>
                    <li>Saldatrice a filo</li>
                    <li>Carica batterie solare</li>
                    <li>Tende da campeggio</li>
                    <li>Sacco a pelo</li>
                    <li>Macchina fotografica</li>
                    <li>Videocamera</li>
                    <li>Cassa bluetooth</li>
                    <li>Mazza baseball + pallina + guantone</li>
                    <li>Ghette</li>
                    <li>Canne da pesca</li>
                    <li>Telescopio</li>
                    <li>Pinne</li>
                    <li>Palla pallavolo</li>
                    <li>Rete da pallavolo</li>
                    <li>Treppiede</li>
                    <li>Videoproiettore</li>
                    <li>Macchina ghiaccio</li>
                    <li>Pedana per passeggino</li>
                    <li>Tagliasiepi</li>
                    <li>Friggitrice ad aria</li>
                    <li>Spina per birra</li>
                    <li>Cassetta attrezzi completa</li>
                    <li>Pesi fitness</li>
                    <li>Set elastici fitness</li>
                    <li>Abiti da cerimonia</li>
                    <li>Imbottigliatrice vino</li>
                    <li>Piscinetta gonfiabile</li>
                    <li>Flessibile</li>
                    <li>Gelatiera</li>
                    <li>Scalpelli per il legno</li>
                    <li>Albero di natale</li>
                    <li>Vela per Katesurf</li>
                    <li>Trapano avvitatore (modelli nuovi)</li>
                    <li>Imbracatura arrampicata</li>
                    <li>Carrello traslochi</li>
                    <li>Decespugliatore</li>
                    <li>Skate</li>
                    <li>Punte trapano</li>
                  </ul>
                </div>
            </bxs-layout>
        </section>
    </div>
</template>

<script>
export default {
    name: 'oggetti_suggeriti'
}
</script>
