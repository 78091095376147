<template>
    <section id="section-collaborazioni">
        <bxs-layout>
            <div class="mb-ui">
                <p class="label">Collaborazioni</p>
                <h3>Realtà con cui condividiamo progetti e obiettivi</h3>
            </div>

            <bxs-marquee class="my-ui">
                <div class="flex nowrap">
                    <bxs-figure
                    src="/img/logo-salaborsa-comune-bologna-colore.jpg"
                    ratio="1-1"
                    contain
                    width="120"
                    class="mr-ui" />

                    <bxs-figure
                    src="/img/backbo-logo-orizzontale.png"
                    ratio="1-1"
                    contain
                    width="120"
                    class="mr-ui" />

                    <bxs-figure
                    src="https://leila-bologna.it/wp-content/uploads/2023/04/logo-opera-marella.png"
                    ratio="1-1"
                    contain
                    width="120"
                    class="mr-ui" />

                    <bxs-figure
                    src="https://leila-bologna.it/wp-content/uploads/2022/12/logo-barca-59.png"
                    ratio="1-1"
                    contain
                    width="120"
                    class="mr-ui" />

                    <bxs-figure
                    src="https://leila-bologna.it/wp-content/uploads/2022/11/LOGO_Ar.coWoodWorkshop_jpeg-1024x724.jpg"
                    ratio="1-1"
                    contain
                    width="120"
                    class="mr-ui" />

                    <bxs-figure
                    src="https://leila-bologna.it/wp-content/uploads/2022/12/logo.jpg"
                    ratio="1-1"
                    contain
                    width="120"
                    class="mr-ui" />

                    <bxs-figure
                    src="https://leila-bologna.it/wp-content/uploads/2022/11/Quattrocchi_Logo-1024x755.png"
                    ratio="1-1"
                    contain
                    width="120"
                    class="mr-ui" />

                    <bxs-figure
                    src="https://leila-bologna.it/wp-content/uploads/2023/02/Le-Gazze.jpg"
                    ratio="1-1"
                    contain
                    width="120"
                    class="mr-ui" />
                </div>
            </bxs-marquee>

            <div class="mt-ui">
                <bxs-link
                label="Scopri di più"
                to="/collaborazioni" />
            </div>
        </bxs-layout>
    </section>
</template>

<script>
export default {
    name: 'section_collaborazioni'
}
</script>